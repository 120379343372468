import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-multiple-choice',
  templateUrl: './modal-multiple-choice.page.html',
  styleUrls: ['./modal-multiple-choice.page.scss'],
})
export class ModalMultipleChoicePage implements OnInit {

  @Input() options: any;
  @Input() multiple: boolean = false;
  @Input() title: any;
  @Input() selectedValue: string;
  isSelected: Boolean = false;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if (this.selectedValue) {
      if (this.multiple) {
        const values = this.selectedValue.split(', ')
        values.map(v => {
          this.options.forEach((opt, ix) => {
            if (opt.value === v) {
              this.options[ix].active = true
            }
          });
        });
      } else {
        this.options.forEach((opt, ix) => {
          if (opt.value === this.selectedValue) {
            this.options[ix].active = true
          }
        });
      }
    }
  }

  setOptions(index) {
    if (!this.multiple || this.options[index].isOnly) {
      this.options.forEach((opt, ix) => {
        if (opt.value !== this.options[index].value) {
          this.options[ix].active = false;      
        }
      })
    }
    if (this.multiple) {
      this.options.forEach((opt, ix) => {
        if (opt.value !== this.options[index].value && opt.isOnly) {
          this.options[ix].active = false;      
        }
      })
    }
    this.isSelected = true;
    this.options[index].active = !this.options[index].active;
  }

  clearOptions(index = null) {
    
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async confirm() {
    const optionsSelected = this.options.filter(o => o.active);
    await this.modalController.dismiss(optionsSelected);
  }

}

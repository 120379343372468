import { DataPropriedades } from "./Propriedades";

export class Relatorio {
    id: string;
    name: string;
    phone: string;
    mail: string;
    cityOperation: string;
    ufOperation: string;
    activityFirst: string;
    isSpecialist: boolean;
    kindPerson: string;
    cpf: string;
    cnpj: string;
    companyName: string;
    cityCompany: string;
    ufCompany: string;
    fundationYear: string;
    numberEmployee: string;
    financingAmount: string;
    goal: string;
    agropecuaryActivity: string;
    timeActivity: string;
    area: string;
    isLowSeason: Boolean;
    lastAmount: string;
    financingProduction: any[];
    isHasRegistration: Boolean;
    data: DataPropriedades;
    polygon: any;
    farmImage: string;
    garantees: any[];
    acceptedEstimated: Boolean;
    valueEstimated: string;
    agriculture: string;
    pasture: string;
    degradedPasture: string;
    reforestation: string
    nativeVegetation: string;
    predominantSoils: string[];
    isMoetgage: Boolean;
    isOwner: Boolean;
    otherGarantees: any[];
    potencialCredit: string;
    isAcceptedTerms: Boolean;
    vintagesPerYear: string;
}
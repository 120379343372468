import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

import { InfoPessoalPageRoutingModule } from './info-pessoal-routing.module'

import { InfoPessoalPage } from './info-pessoal.page'
import { NgxMaskModule } from 'ngx-mask'
import { NgxTrimDirectiveModule } from 'ngx-trim-directive'
import {BrMaskerModule} from 'br-mask';

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        CommonModule,
        FormsModule,
        BrMaskerModule,
        ReactiveFormsModule,
        IonicModule,
        NgxMaskModule.forRoot(),
        InfoPessoalPageRoutingModule,
        NgxTrimDirectiveModule,
    ],
    declarations: [InfoPessoalPage],
})
export class InfoPessoalPageModule {}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuscarPropriedadePage } from './buscar-propriedade.page';

const routes: Routes = [
  {
    path: '',
    component: BuscarPropriedadePage
  },
  {
    path: 'modal-buscar-propriedade',
    loadChildren: () => import('./modal-buscar-propriedade/modal-buscar-propriedade.module').then( m => m.ModalBuscarPropriedadePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuscarPropriedadePageRoutingModule {}

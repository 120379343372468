import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DominiosService } from '@services/dominios.service';
import { Uf } from 'app/models/Uf';
import { DesenharPropriedadePage } from '../../desenhar-propriedade/desenhar-propriedade.page';

@Component({
  selector: 'app-modal-buscar-propriedade',
  templateUrl: './modal-buscar-propriedade.page.html',
  styleUrls: ['./modal-buscar-propriedade.page.scss'],
})
export class ModalBuscarPropriedadePage implements OnInit {

  @Input() cidade: string;
  @Input() uf: Uf;
  @Input() listModals: string[] = [];

  constructor(
    private dominiosService: DominiosService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async modalDesenharPropriedade() {
    if (this.cidade) {
        const splitCidade = this.cidade.split(' - ')

        const ufs = await this.dominiosService.getUf()
        this.uf = ufs.find((u) => u.uf === splitCidade[1].toUpperCase())
    }

    let idModal = Math.floor(Math.random() * 9999) + 1000;
    this.listModals.push('desenhar-propriedade' + idModal)
    const modal = await this.modalController.create({
        component: DesenharPropriedadePage,
        cssClass: 'modal-full',
        backdropDismiss: true,
        componentProps: {
            uf: this.uf,
            listModals: this.listModals,
            cidade: this.cidade,
            id: 'desenhar-propriedade' + idModal
        },
        id: 'desenhar-propriedade' + idModal,
    })
    await modal.present()
    const { data } = await modal.onWillDismiss()

    if (data) {
    }
}

}

import { Injectable } from "@angular/core";
import { Relatorio } from "app/models/Relatorio";
import { 
    ACCEPTED_TERMS,
    ANO_FUNDACAO_PJ,
    AREA_TOTAL_MATRICULA,
    ATIVIDADE_PRINCIPAL, 
    ATIVIDADE_PRINCIPAL_2, 
    ATIVIDADE_PRINCIPAL_PJ, 
    COMO_SE_FINANCIA_PJ, 
    CONCORDAR_ESTIMATIVA, 
    CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO, 
    ESTIMATIVA_VALOR_MATRICULA, 
    E_PROPRIETARIO, 
    FATURAMENTO_PJ, 
    FAZENDA_GARANTIA, 
    FINACIAR_PRODUCAO, 
    FINALIDADE_FINANCIAMENTO, 
    FINALIDADE_FINANCIAMENTO_PJ, 
    GO, 
    HIPOTECA_ALIENACAO, 
    INFO_PORCENTAGEM, 
    MATRICULA_PJ, 
    MUNICIPIO_PJ, 
    NUMERO_FUNCIONARIOS_PJ, 
    OUTRAS_GARANTIAS, 
    PF, 
    PJ, 
    QTD_HECTARES, 
    RAZAO_SOCIAL, 
    SAFRAS_POR_ANO, 
    SAFRINHA, 
    SOLO_PREDOMINANTE, 
    TEMPO_ATIVIDADE, 
    ULTIMO_FATURAMENTO, 
    VALOR_FINANCIAMENTO,
    VALOR_FINANCIAMENTO_PJ,
    VALOR_FINANCIAMENTO_POTENCIAL_CREDITO,
    VALOR_FINANCIAMENTO_POTENCIAL_CREDITO_PJ
} from "./list-intents";

@Injectable({
    providedIn: 'root'
})
export class ValidateIntent {

    validate(relatorio: Relatorio, currentMessage: any, value: string) {
        if (currentMessage.intent == GO) {
            relatorio.kindPerson = value;
        }
        else if (currentMessage.intent == PF) {
            relatorio.cpf = value;
        }
        else if (currentMessage.intent == PJ) {
            relatorio.cnpj = value;
        }
        else if (currentMessage.intent == VALOR_FINANCIAMENTO) {
            relatorio.financingAmount = value;
        }
        else if (currentMessage.intent == FINALIDADE_FINANCIAMENTO) {
            relatorio.goal = value;
        }
        else if (currentMessage.intent == ATIVIDADE_PRINCIPAL_2) {
            relatorio.activityFirst = value;
        }
        else if (currentMessage.intent == TEMPO_ATIVIDADE) {
            relatorio.timeActivity = value;
        }
        else if (currentMessage.intent == QTD_HECTARES) {
            relatorio.area = value;
        }
        else if (currentMessage.intent == AREA_TOTAL_MATRICULA) {
            relatorio.area = value;
        }
        else if (currentMessage.intent == SAFRINHA) {
            relatorio.isLowSeason = value == 'Sim';
        }
        else if (currentMessage.intent == ULTIMO_FATURAMENTO) {
            relatorio.lastAmount = value;
        }
        else if (currentMessage.intent == FINACIAR_PRODUCAO) {
            relatorio.financingProduction = value.split(', ');
        }
        else if (currentMessage.intent == CONCORDAR_ESTIMATIVA) {
            relatorio.acceptedEstimated = value == 'Sim';
        }
        else if (currentMessage.intent == INFO_PORCENTAGEM) {
            const values = value.split(', ');
            values.map(v => {
                if (v.startsWith('Agricultura ')) {
                    relatorio.agriculture = v.replace('Agricultura ', '');
                }
                else if (v.startsWith('Pastagem ')) {
                    relatorio.pasture = v.replace('Pastagem ', '');
                }
                /*else if (v.startsWith('Pasto degradado ')) {
                    relatorio.degradedPasture = v.replace('Pasto degradado ', '');
                }*/
                else if (v.startsWith('Reflorestamento ')) {
                    relatorio.reforestation = v.replace('Reflorestamento ', '');
                }
                else if (v.startsWith('Vegetação nativa ')) {
                    relatorio.nativeVegetation = v.replace('Vegetação nativa ', '');
                }
            });
        }
        else if (currentMessage.intent == ATIVIDADE_PRINCIPAL) {
            relatorio.agropecuaryActivity = value;
        }
        else if (currentMessage.intent == SOLO_PREDOMINANTE) {
            relatorio.predominantSoils = value.split(', ');
        }
        else if (currentMessage.intent == HIPOTECA_ALIENACAO) {
            relatorio.isMoetgage = value == 'Sim';
        }
        else if (currentMessage.intent == E_PROPRIETARIO) {
            relatorio.isOwner = value == 'Sim';
        }
        else if (currentMessage.intent == RAZAO_SOCIAL) {
            relatorio.companyName = value;
        }
        else if (currentMessage.intent == VALOR_FINANCIAMENTO_PJ) {
            relatorio.financingAmount = value;
        }
        else if (currentMessage.intent == FINALIDADE_FINANCIAMENTO_PJ) {
            relatorio.goal = value;
        }
        else if (currentMessage.intent == MUNICIPIO_PJ) {
            const cidade = value.split(' - ');
            relatorio.cityCompany = cidade[0];
            relatorio.ufCompany = cidade[1];
        }
        else if (currentMessage.intent == ATIVIDADE_PRINCIPAL_PJ) {
            relatorio.agropecuaryActivity = value;
        }
        else if (currentMessage.intent == ANO_FUNDACAO_PJ) {
            relatorio.fundationYear = value;
        }
        else if (currentMessage.intent == NUMERO_FUNCIONARIOS_PJ) {
            relatorio.numberEmployee = value;
        }
        else if (currentMessage.intent == FATURAMENTO_PJ) {
            relatorio.lastAmount = value;
        }
        else if (currentMessage.intent == COMO_SE_FINANCIA_PJ) {
            relatorio.financingProduction = value.split(', ');
        }
        else if (currentMessage.intent == ESTIMATIVA_VALOR_MATRICULA) {
            relatorio.valueEstimated = value;
        }
        else if (currentMessage.intent == OUTRAS_GARANTIAS) {
            relatorio.otherGarantees = value.split(', ');
        }
        else if (currentMessage.intent == FAZENDA_GARANTIA && value != '') {
            relatorio.isHasRegistration = value == 'Sim';
        }
        else if (currentMessage.intent == MATRICULA_PJ && value != '') {
            relatorio.isHasRegistration = value == 'Sim';
        }
        else if (currentMessage.intent == CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO) {
            relatorio.acceptedEstimated = value == 'Sim';
        }
        else if (currentMessage.intent == VALOR_FINANCIAMENTO_POTENCIAL_CREDITO) {
            relatorio.potencialCredit = value;
        }
        else if (currentMessage.intent == VALOR_FINANCIAMENTO_POTENCIAL_CREDITO_PJ) {
            relatorio.potencialCredit = value;
        }
        else if (currentMessage.intent == ACCEPTED_TERMS) {
            relatorio.isAcceptedTerms = true;
        }
        else if (currentMessage.intent == SAFRAS_POR_ANO) {
            relatorio.vintagesPerYear = value;
        }
    }
    
}
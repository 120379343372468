import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalLegendasMobileRoutingModule } from './modal-legendas-mobile-routing.module';
import { ModalLegendasMobilePage } from './modal-legendas-mobile.page';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxMaskModule.forRoot(),
    ModalLegendasMobileRoutingModule
  ],
  declarations: [ModalLegendasMobilePage]
})
export class ModalLegendasMobileModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ModalLoginPageRoutingModule} from './modal-login-routing.module';
import {ModalLoginPage} from './modal-login.page';
import {NgxMaskModule} from 'ngx-mask';
import {PipesModule} from 'app/pipes/pipes.module';
import {BrMaskerModule} from 'br-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        BrMaskerModule,
        ModalLoginPageRoutingModule,
        PipesModule
    ],
    declarations: [ModalLoginPage]
})
export class ModalLoginPageModule {
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TerralogsService} from '@services/terralogs.service';
import {ModalLoginPage} from '../modal-login/modal-login.page';
import {ModalBemVindoPage} from '../modal-bem-vindo/modal-bem-vindo.page';
import {isCpfValid, validarEmail} from '../../../utils/utils';

@Component({
    selector: 'app-modal-cadastro.',
    templateUrl: './modal-cadastro.page.html',
    styleUrls: ['./modal-cadastro.page.scss'],
})
export class ModalCadastroPage implements OnInit {

    form: FormGroup;
    emailValido = false;
    cpfValido = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private platform: Platform,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController,
    ) {
        this.form = this.formBuilder.group({
            cpf: ['', Validators.required],
            nome: ['', Validators.required],
            email: ['', Validators.required, Validators.email],
            telefone: ['', Validators.required],
            senha: ['', Validators.required],
            confirmarSenha: ['', Validators.required],
            perfil: [7] // Solicitante Site
        });
    }

    ngOnInit() {
    }

    async modalLogin() {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: ModalLoginPage,
            cssClass: ['modal-small', 'modal-login'],
            backdropDismiss: true,
            id: 'modal-login'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            console.log('data', data);
        }
    }


    async modalBemVindo() {
        const modal = await this.modalController.create({
            component: ModalBemVindoPage,
            cssClass: ['modal-small', 'modal-bem-vindo'],
            backdropDismiss: true,
            id: 'modal-bem-vindo'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            console.log('data', data);
        }
    }

    async salvar() {

        if (this.form.invalid) {
            return this.toast('Campos obrigatórios do formulário não foram preenchidos.', 'danger');
        }

        if (this.form.get('senha').value !==
            this.form.get('confirmarSenha').value) {
            return this.toast('As senhas devem ser compatíveis');
        }

        if (!this.emailValido) {
            return this.toast('E-mail inválido!', 'danger');
        }
        if (!this.cpfValido) {
            return this.toast('CPF inválido!', 'danger');
        }
        const usuario = this.form.getRawValue();
        if (!validarEmail(usuario.email)) {
            return this.toast('O e-mail informado é inválido.', 'danger');
        }
        if (!isCpfValid(usuario.cpf)) {
            return this.toast('O CPF informado é inválido.', 'danger');
        }

        await (await this.loadingController.create()).present();

        this.terralogsService.salvarUsuario(usuario)
            .subscribe(x => {
                this.modalController.dismiss();
                this.modalBemVindo();
            }, er => {
                return this.toast(er?.error?.result.message  || 'Não foi possível salvar os dados. Tente novamente!', 'danger');
            });

        await this.loadingController.dismiss();

    }

    validarEmail(evento: any) {
        const email = this.form.get('email').value;

        const emailValido = validarEmail(email);

        if (!emailValido) {
            return this.toast('E-mail inválido!', 'danger');
        }
        this.emailValido = true;
    }

    validarCPF(evento: any) {
        const cpf = this.form.get('cpf').value;

        const cpfValido = isCpfValid(cpf);

        if (!cpfValido) {
            return this.toast('CPF inválido!', 'danger');
        }
        this.cpfValido = true;
    }

    close() {
        this.modalController.dismiss();
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }
}

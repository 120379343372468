import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PropriedadeSelecionadaPageRoutingModule } from './propriedade-selecionada-routing.module';

import { PropriedadeSelecionadaPage } from './propriedade-selecionada.page';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxMaskModule.forRoot(),
    PropriedadeSelecionadaPageRoutingModule
  ],
  declarations: [PropriedadeSelecionadaPage]
})
export class PropriedadeSelecionadaPageModule {}

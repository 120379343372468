import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import * as L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.js';
import { Uf } from '../../../models/Uf';
import * as Constants from '@services/contants.service'
import { ModalFormPropriedadePage } from '../modal-form-propriedade/modal-form-propriedade.page';
import domtoimage from 'dom-to-image-google-font-issue';
import { ModalAjudaPage } from '../modal-ajuda/modal-ajuda.page';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalMobileOptionsPage } from '../modal-mobile-options/modal-mobile-options.page';

@Component({
  selector: 'app-desenhar-propriedade',
  templateUrl: './desenhar-propriedade.page.html',
  styleUrls: ['./desenhar-propriedade.page.scss'],
})
export class DesenharPropriedadePage implements OnInit {

  map: any;
  shape: any;
  btnRemovePolygon: boolean = false;
  btnIniciarDesenho: boolean = true;
  btnReiniciar: boolean = false;

  polygon: any = null;
  draw: any = null;
  worldPolygon: any = null;
  layerDraw: any = null;
  isBusy: Boolean = false;
  countVertex: number = 0;
  isFirstDraw: boolean = true;

  @Input() isFinanciar: Boolean;
  @Input() data: boolean = false;
  @Input() uf: Uf;
  @Input() listModals: string[] = [];
  @Input() cidade: string;
  @Input() id: string;

  constructor(
    private store: Store<any>,
    private modalController: ModalController,
    private router: Router,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.loadMap();
  }

  loadMap() {
    if (this.uf) {
      this.map = new L.Map("map4").setView([this.uf.latitude, this.uf.longitude], 13);
    } else {
      this.map = new L.Map("map4").setView([-15.8433962,-47.871458], 13);
    }
    L.tileLayer(Constants.TILE_LAYER, {
      attribution: '',
      maxZoom: 18,
      id: Constants.TILE_ID,
      tileSize: 512,
      zoomOffset: -1,
      accessToken: Constants.TILE_ACCESS_TOKEN
    }).addTo(this.map);
  }

  onIniciarDesenho() {
    this.btnIniciarDesenho = false;
    if (this.isFirstDraw) {
      this.eventDraw();
      this.isFirstDraw = false;
    }
    this.enableDrawPolygon();
  }

  removePolygon() {
    this.map.removeLayer(this.draw);
    this.map.removeLayer(this.worldPolygon);
    this.btnReiniciar = false;
    this.btnRemovePolygon = false;
    this.btnIniciarDesenho = true;
    this.countVertex = 0;
  }

  async confirmPolygon() {

    this.isBusy = true;
    const loading = await this.loadingController.create();
    await loading.present();
    setTimeout(async () => {
      var zoomLeaflet: any = document.getElementsByClassName('leaflet-control-zoom');
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'none';
      }
      const bannerMap = await this.printMap();
      localStorage.setItem('bannerMap', bannerMap);
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'initial';
      }
      this.isBusy = false;
      await loading.dismiss();
      
      const modal = await this.modalController.create({
        component: ModalFormPropriedadePage,
        cssClass: 'modal-footer',
        id: "modal-form-propriedade",
        backdropDismiss: true,
      });
      await modal.present();
      const {data} = await modal.onWillDismiss();

      if (data) {
        this.layerDraw.nome_propriede = data;
        this.layerDraw.cidade = this.cidade ? this.cidade.split(' - ')[0] : null;
        this.layerDraw.uf = this.cidade ? this.cidade.split(' - ')[1] : null;

        await (await this.loadingController.create()).present()

        await this.modalController.dismiss({type: 'new', value: this.layerDraw}, '', 'buscar-propriedade').catch(this.printError);
        await this.listModals.map(async m => {
          if (m != 'buscar-propriedade') {
            await this.modalController.dismiss(null, '', m).catch(error => console.log(error));
          }
        });
        await this.loadingController.dismiss()
        /*if (this.isFinanciar) {
          localStorage.setItem('is_financiar', 'true');
          this.store.dispatch(addPolygon({ payload: this.layerDraw }));
          this.router.navigate(['info-pessoal']);
        }*/
      }
    }, 2000);

  }

  eventDraw() {
    this.map.on('draw:drawvertex', (e) => {
      this.btnReiniciar = true;
      this.countVertex++;
    });

    this.map.on('draw:created', e => {
      if (e.layerType == 'polygon') {
        this.layerDraw = e.layer;
        let params: any = {
          type: "FeatureCollection",
        }
        params.features = [];

        params.features.push(e.layer.toGeoJSON());
        let areaMap: any = L.geoJSON(params);
        
        this.polygon = e.layer.getLatLngs();
        this.draw = L.polygon(this.polygon, {
          fillColor: 'rgba(0, 155, 69, 0.22)',
          color: 'rgba(255, 255, 255, 0.5)',
          fillOpacity: 0.8,
          weight: 3, 
        }).addTo(this.map);
        areaMap.addData([[90, -180], [90, 180], [-90, 180], [-90, -180], [90, -180]]);
        areaMap.eachLayer(l => {
          const worldPolygon: any = L.polygon([[[90, -180], [90, 180], [-90, 180], [-90, -180], [90, -180]], l.getLatLngs()], {
            fillOpacity: 0.2,
            weight: 2,
            color: '#000000',
            fillColor: '#000000',
            stroke: false,
          });

          this.worldPolygon = worldPolygon;
          this.map.addLayer(worldPolygon);
          this.map.fitBounds(areaMap.getBounds());
        })
      }
      this.shape = null;
      this.btnRemovePolygon = true;
    });
  }

  enableDrawPolygon() {
    const leaflet: any = L;

    this.shape = new leaflet.Draw.Polygon(this.map);
    this.shape.setOptions({
      shapeOptions: {
        fillColor: 'rgba(0, 155, 69, 0.22)',
        color: 'rgba(255, 255, 255, 0.5)',
        fillOpacity: 0.8,
        weight: 1, 
        id: 'drawpolygon'
      }
    });
    this.shape.enable();

    /*this.map.on('dragend', () => {
      this.shape.deleteLastVertex();
    })*/
  }

  async openModalAjuda() {
    const modal = await this.modalController.create({
        component: ModalAjudaPage,
        cssClass: 'modal-footer',
        backdropDismiss: true,
        id: 'modal-ajuda',
    })
    await modal.present()
  }

  async printMap() {
    let node = document.getElementById('map4');

    // Hack para diminuir o tamanho da imagem
    const scale = 0.8;
    const options = {
      height: node.offsetHeight * scale,
      width: node.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px"
      }
    };

    const base64 = await domtoimage.toPng(node, options);
    return base64;
  }

  async onClose() {
    await (await this.loadingController.create()).present()
    await this.listModals.map(async m => {
        await this.modalController.dismiss(null, '', m).catch(error => console.log(error));
    })
    await this.loadingController.dismiss()
    await this.router.navigate(['home']);
  }

  async onBack() {
    await this.modalController.dismiss(null, '', this.id);
  }

  onReiniciar() {
    if (this.countVertex == 1) {
      this.shape.addVertex([this.uf.latitude, this.uf.longitude]);
      this.shape.addVertex([this.uf.latitude, this.uf.longitude]);
    } else if (this.countVertex == 2) {
      this.shape.addVertex([this.uf.latitude, this.uf.longitude]);
    }
    this.shape.completeShape();
    this.removePolygon();
  }

  async openModalMobileOptions() {
    const modal = await this.modalController.create({
        component: ModalMobileOptionsPage,
        cssClass: 'modal-footer',
        backdropDismiss: true,
        id: 'modal-mobile-options',
        componentProps: {
            options: [
                {
                    icon: 'assets/custom-icons/icon-reiniciar-selecao.svg',
                    label: 'Reiniciar',
                    action: async () => this.onReiniciar(),
                    isDisabled: !this.btnReiniciar
                }, {
                    icon: 'assets/custom-icons/icone-pesquisar-modal-opcoes.svg',
                    label: 'Filtrar',
                    action: async () => this.onBack(),
                    isDisabled: false
                }, {
                    icon: 'assets/custom-icons/icon-ajuda-modal-opcoes.svg',
                    label: 'Ajuda',
                    action: async () => this.openModalAjuda(),
                    isDisabled: false
                }
            ]
        },
    })
    await modal.present()
  }

  printError(error) {
    console.log(error);
  }

}

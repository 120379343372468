import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-selecionar-mapas',
  templateUrl: './modal-selecionar-mapas.page.html',
  styleUrls: ['./modal-selecionar-mapas.page.scss'],
})
export class ModalSelecionarMapasPage {
  mapaSelecionado;

  constructor(
    private modalController: ModalController
  ) { }

  selecionar(mapa) {
    this.mapaSelecionado = mapa;
    this.close();
  }

  close() {
    this.modalController.dismiss({
      mapa: this.mapaSelecionado
    });
  }
}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CamadasService {

    constructor() {
    }

    getTemperaturaLegenda() {
        return [
            {'value': -17, 'nome': '-17º', 'cor': '#fae9fb'},
            {'value': -16, 'nome': '-16º', 'cor': '#fcddfd'},
            {'value': -15, 'nome': '-15º', 'cor': '#f3d2fd'},
            {'value': -14, 'nome': '-14º', 'cor': '#efc7fb'},
            {'value': -13, 'nome': '-13º', 'cor': '#eebbfd'},
            {'value': -12, 'nome': '-12º', 'cor': '#e4b2f9'},
            {'value': -11, 'nome': '-11º', 'cor': '#df92fc'},
            {'value': -10, 'nome': '-10º', 'cor': '#e19cf7'},
            {'value': -9, 'nome': '-9º', 'cor': '#de91ff'},
            {'value': -8, 'nome': '-8º', 'cor': '#da85fe'},
            {'value': -7, 'nome': '-7º', 'cor': '#d57eff'},
            {'value': -6, 'nome': '-6º', 'cor': '#d573fc'},
            {'value': -5, 'nome': '-5º', 'cor': '#cf69fd'},
            {'value': -4, 'nome': '-4º', 'cor': '#ce5efe'},
            {'value': -3, 'nome': '-3º', 'cor': '#c652ff'},
            {'value': -2, 'nome': '-2º', 'cor': '#b84dff'},
            {'value': -1, 'nome': '-1º', 'cor': '#984ffe'},
            {'value': 0, 'nome': '0º', 'cor': '#7457fd'},
            {'value': 1, 'nome': '1º', 'cor': '#6159fe'},
            {'value': 2, 'nome': '2º', 'cor': '#4b5ffe'},
            {'value': 3, 'nome': '3º', 'cor': '#2a71ff'},
            {'value': 4, 'nome': '4º', 'cor': '#1882fc'},
            {'value': 5, 'nome': '5º', 'cor': '#0b95ed'},
            {'value': 6, 'nome': '6º', 'cor': '#0ac1e9'},
            {'value': 7, 'nome': '7º', 'cor': '#00e4c3'},
            {'value': 8, 'nome': '8º', 'cor': '#08ed6e'},
            {'value': 9, 'nome': '9º', 'cor': '#17e656'},
            {'value': 10, 'nome': '10º', 'cor': '#46de11'},
            {'value': 11, 'nome': '11º', 'cor': '#7be803'},
            {'value': 12, 'nome': '12º', 'cor': '#8ae800'},
            {'value': 13, 'nome': '13º', 'cor': '#b8f400'},
            {'value': 14, 'nome': '14º', 'cor': '#d7f700'},
            {'value': 15, 'nome': '15º', 'cor': '#fcf803'},
            {'value': 16, 'nome': '16º', 'cor': '#f2e5011'},
            {'value': 17, 'nome': '17º', 'cor': '#f4c908'},
            {'value': 18, 'nome': '18º', 'cor': '#f2a905'},
            {'value': 19, 'nome': '19º', 'cor': '#f48d04'},
            {'value': 20, 'nome': '20º', 'cor': '#f67307'},
            {'value': 21, 'nome': '21º', 'cor': '#f35d06'},
            {'value': 22, 'nome': '22º', 'cor': '#f13903'},
            {'value': 23, 'nome': '23º', 'cor': '#e81700'},
            {'value': 24, 'nome': '24º', 'cor': '#e10c04'},
            {'value': 25, 'nome': '25º', 'cor': '#d0080a'},
            {'value': 26, 'nome': '26º', 'cor': '#bf040d'},
            {'value': 27, 'nome': '27º', 'cor': '#af0311'},
            {'value': 28, 'nome': '28º', 'cor': '#9c0018'},
            {'value': 29, 'nome': '29º', 'cor': '#88001b'},
            {'value': 30, 'nome': '30º', 'cor': '#730125'},
            {'value': 31, 'nome': '31º', 'cor': '#640026'},
            {'value': 32, 'nome': '32º', 'cor': '#530031'},
            {'value': 33, 'nome': '33º', 'cor': '#410237'}
        ];
    }

    getSolosLegenda() {
        return [
            {
                nome: 'Alissolo',
                cor: '#72F4AA'
            },
            {
                nome: 'Argissolo',
                cor: '#B95A49'
            },
            {
                nome: 'Cambissolo',
                cor: '#F3F4CA'
            },
            {
                nome: 'Chernossolo',
                cor: '#F9F072'
            },
            {
                nome: 'Dunas',
                cor: '#EEEEC7'
            },
            {
                nome: 'Espodossolo',
                cor: '#E8E830'
            },
            {
                nome: 'Gleissolo',
                cor: '#858080'
            },
            {
                nome: 'Latossolo',
                cor: '#C7633C'
            },
            {
                nome: 'Luvissolo',
                cor: '#F4AFBD'
            },
            {
                nome: 'Massa de água',
                cor: '#97DBF4'
            },
            {
                nome: 'Neossolo',
                cor: '#ED4A6C'
            },
            {
                nome: 'Nitossolo',
                cor: '#D28FAC'
            },
            {
                nome: 'Organossolo',
                cor: '#C150CD'
            },
            {
                nome: 'Planossolo',
                cor: '#4466AB'
            },
            {
                nome: 'Plintossolo',
                cor: '#D3DD75'
            },
            {
                nome: 'Vertissolo',
                cor: '#EE9F5A'
            }
        ];
    }

    getBiomasLegenda() {
        return [
            {
                nome: 'Amazônia',
                cor: '#21ed1a'
            },
            {
                nome: 'Cerrado',
                cor: '#936700'
            },
            {
                nome: 'Mata Atlântica',
                cor: '#2432f4'
            },
            {
                nome: 'Caatinga',
                cor: '#6c7054'
            },
            {
                nome: 'Pampa',
                cor: '#c60f25'
            },
            {
                nome: 'Pantanal',
                cor: '#f9e807'
            }
        ];
    }

    getPrecipitacaoLegenda() {
        return [
            {
                nome: '>3.300 mm',
                cor: '#00319c',
                value: 3300
            },
            {
                nome: '>3.000 mm',
                cor: '#184a9c',
                value: 3000
            },
            {
                nome: '2.700',
                cor: '#315aa5',
                value: 2700
            },
            {
                nome: '2.400',
                cor: '#4a73ad',
                value: 2400
            },
            {
                nome: '2.100',
                cor: '#6384ad',
                value: 2100
            },
            {
                nome: '1.800',
                cor: '#849cb5',
                value: 1800
            },
            {
                nome: '1.500',
                cor: '#9cadbd',
                value: 1500
            },
            {
                nome: '1.200',
                cor: '#b5c6bd',
                value: 1200
            },
            {
                nome: '900',
                cor: '#ced6c6',
                value: 900
            },
            {
                nome: '600',
                cor: '#e7efc6',
                value: 600
            }
        ];
    }

    getClimasLegenda() {
        return [
            {
                nome: 'Clima tropical úmido ou superúmido',
                cor: '#0000FE',
                value: 'AF'
            },
            {
                nome: 'Clima tropical úmido ou subúmido',
                cor: '#0077FF',
                value: 'AM'
            },
            {
                nome: 'Clima tropical quente e úmido',
                cor: '#46A9FA',
                value: 'AS'
            },
            {
                nome: 'Clima tropical com inverno seco',
                cor: '#5eb2f7',
                value: 'AW'
            },
            {
                nome: 'Clima semi-árido quente',
                cor: '#F5A301',
                value: 'BSH'
            },
            {
                nome: 'Clima subtropical com verão quente',
                cor: '#C6FF4E',
                value: 'CFA'
            },
            {
                nome: 'Clima temperado com verão ameno',
                cor: '#66FF33',
                value: 'CFB'
            },
            {
                nome: 'Clima subtropical de inverno seco',
                cor: '#96FF96',
                value: 'CWA'
            },
            {
                nome: 'Clima subtropical de altitude',
                cor: '#63C764',
                value: 'CWB'
            }
        ];
    }

}

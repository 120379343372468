import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {ModalMultipleChoicePage} from '../modal-multiple-choice/modal-multiple-choice.page';
import {ModalSmsPage} from '../modal-sms/modal-sms.page';
import {map, startWith} from 'rxjs/operators';
import {Municipio} from '../../../models/Municipio';
import {DominiosService} from '../../../services/dominios.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalAjudaPage} from '../modal-ajuda/modal-ajuda.page';
import {StringUtils} from 'app/utils/string-utils';
import {SmsService} from '@services/sms.service';
import {TerralogsService} from '@services/terralogs.service';

@Component({
    selector: 'app-info-pessoal',
    templateUrl: './info-pessoal.page.html',
    styleUrls: ['./info-pessoal.page.scss'],
})
export class InfoPessoalPage implements OnInit {

    formGroupForm: FormGroup;

    municipios: Municipio[] = [];
    options: any[] = [];
    filteredOptions: Observable<string[]>;
    isSelectState = false;
    selectState: string;
    idPropriedade;
    tipo = 'fisica';

    constructor(
        private modalController: ModalController,
        private dominiosService: DominiosService,
        private terralogsService: TerralogsService,
        private route: ActivatedRoute,
        private router: Router,
        private stringUtils: StringUtils,
        private smsService: SmsService,
        private loadingController: LoadingController
    ) {
    }

    async ngOnInit() {

        this.route.queryParams.subscribe(params => {
            if (params && params.idPropriedade) {
                this.idPropriedade = params.idPropriedade;
            }
        });
        this.formGroupForm = this.createFormGroup();

        this.municipios = await this.dominiosService.getMunicipio('');
        const ufs = await this.dominiosService.getUf();

        this.options = this.municipios.map(municipio => {
            const sigla = ufs.find(uf => municipio.codigo_uf === uf.codigo_uf).uf;
            return  {
                nome: `${municipio.nome} - ${sigla}`,
                codigo_ibge: municipio.codigo_ibge,
                codigo_uf: municipio.codigo_uf
            };
        });

        this.filteredOptions = this.formGroupForm.get('cidade').valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );

    }

    private createFormGroup() {
        return new FormGroup({
            nome: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            razaoSocial: new FormControl(''),
            cpf: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            cnpj: new FormControl(''),
            email: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            cidade: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            telefone: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            atividadePrincipal: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            municipioSite: new FormControl(''),
            ufSite: new FormControl(''),
        });
    }

    alterarTipo(evento) {
        this.tipo = evento.detail.value;
        if (this.tipo === 'juridica') {
            this.limparValidadores('nome');
            this.limparValidadores('cpf');
            this.adicionarValidador('cnpj');
            this.adicionarValidador('razaoSocial');
        } else {
            this.limparValidadores('razaoSocial');
            this.limparValidadores('cnpj');
            this.adicionarValidador('cpf');
            this.adicionarValidador('nome');
        }
    }

    limparValidadores(campo: string) {
        this.formGroupForm.get(campo).clearValidators();
        this.formGroupForm.get(campo).updateValueAndValidity();
    }

    adicionarValidador(campo: string) {
        this.formGroupForm.get(campo).setValidators(Validators.required);
        this.formGroupForm.get(campo).updateValueAndValidity();
    }

    private _filter(value: any): string[] {
        const filterValue = value ? value.toLowerCase() : '';

        return this.options
            .filter((option) => option.nome.toLowerCase().includes(filterValue)
                || this.stringUtils.replaceAcentos(option.nome.toLowerCase()).includes(filterValue))
            .slice(0, 5);
    }

    async comecar() {
        await (await this.loadingController.create()).present();
        const formData = this.formGroupForm.value;
        await this.terralogsService.dadosUsuarioChatbotSolicitacao(formData);

        formData.cidade = this.selectState;
        let pin = Math.floor(Math.random() * 9999) + 1000;
        pin = pin > 9999 ? Math.floor(pin / 10) : pin;
        const message = `${pin} - Código de verificação Terralogs.`;

        await this.smsService.send(message, '+55' + formData.telefone).catch(error => console.log(error));

        await this.loadingController.dismiss();
        const modal = await this.modalController.create({
            component: ModalSmsPage,
            cssClass: 'modal-footer',
            componentProps: {
                formData,
                pin
            }
        });
        const result = await modal.present();
        await modal.onWillDismiss();
        return result;
    }

    proximoCampo(eventoTarget: any) {
        const target = document.getElementById(eventoTarget);
        this.scrollToElement(target);
    }

    scrollToElement($element): void {
        $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    async modalChoice() {
        const modal = await this.modalController.create({
            component: ModalMultipleChoicePage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            componentProps: {
                title: 'Qual sua atividade principal?',
                options: [
                    {
                        id: 1,
                        name: 'Produtor rural',
                        value: 'produtor_rural',
                    },
                    {
                        id: 2,
                        name: 'Proprietário de agroindústria',
                        value: 'proprietario_agroindustrial',
                    },
                    {
                        id: 3,
                        name: 'Gerente',
                        value: 'gerente',
                    },
                    {
                        id: 4,
                        name: 'Consultor agropecuário',
                        value: 'consultor_agropecuario',
                    },
                    {
                        id: 5,
                        name: 'Outro',
                        value: 'outro',
                    },
                ],
                multiple: false
            },
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            this.formGroupForm.get('atividadePrincipal').setValue(data[0].name);
        }
    }

    onSelectState(value: any) {
        this.formGroupForm.get('municipioSite').setValue(value.codigo_ibge);
        this.formGroupForm.get('ufSite').setValue(value.codigo_uf);
        this.selectState = value.nome;
        this.isSelectState = true;
    }

    async openModalAjuda() {
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda',
        });
        await modal.present();
    }

    goBack() {
        if (this.idPropriedade) {
        this.router.navigate(['mapa-propriedades/share/' + this.idPropriedade]);
        } else {
            this.router.navigate(['/mapa-propriedades']);
        }
    }

}

import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'menu-horizontal-landing-page',
    templateUrl: 'menu-horizontal-landing-page.component.html',
    styleUrls: ['menu-horizontal-landing-page.component.scss'],
})
export class MenuHorizontalLandingPageComponent {
    @Input() menu = 'inicio';
    menuMobileOpen = false;

    constructor(
        private router: Router,
        private modalController: ModalController
    ) {
    }

    goChatbot() {
        this.router.navigate(['info-pessoal']);
    }

    goTo(rota) {
        this.router.navigateByUrl(rota);
    }

    toggleMenuMobile() {
        this.menuMobileOpen = !this.menuMobileOpen;
    }

}

import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { LoadingController, ModalController } from '@ionic/angular'
import { Relatorio } from 'app/models/Relatorio'
import { Usuario } from 'app/models/Usuario'
import { PdfChatbot } from 'app/utils/pdf-chatbot'
import { ModalAjudaPage } from '../modal-ajuda/modal-ajuda.page'

@Component({
    selector: 'app-finish-chatbot',
    templateUrl: './finish-chatbot.page.html',
    styleUrls: ['./finish-chatbot.page.scss'],
})
export class FinishChatbotPage implements OnInit {
    load: Boolean = false;
    
    @Input() dataUser: Usuario
    @Input() message: string
    @Input() title: string = 'Recebemos sua solicitação';
    @Input() relatorio: Relatorio;

    constructor(
        private modalController: ModalController, 
        public router: Router,
        private pdfChatbot: PdfChatbot,
        private loadingController: LoadingController
    ) {}

    ngOnInit() {
    }

    goBack() {
		this.modalController.dismiss(true)
        this.router.navigate(['home'])
    }

    formatarMaiusculo(text) {
        if (text) {
            var loweredText = text.toLowerCase()
            var words = loweredText.split(' ')
            for (var a = 0; a < words.length; a++) {
                var w = words[a]

                var firstLetter = w[0]
                w = firstLetter.toUpperCase() + w.slice(1)

                words[a] = w
            }
            return words.join(' ')
        }
        return ''
    }

    async openModalAjuda() {
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda',
        })
        await modal.present()
    }

    async onDownloadRelatorio() {
        const loading = await this.loadingController.create();
        this.load = true;
        await loading.present();
        await this.pdfChatbot.generatePdf(this.relatorio);
        this.load = false;
        await loading.dismiss();
    }

}

import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// CHAT
import {BuscarPropriedadePageModule} from './pages/fluxos-chatbot/buscar-propriedade/buscar-propriedade.module';
import {ChatbotPageModule} from './pages/fluxos-chatbot/chatbot/chatbot.module';
import {DesenharPropriedadePageModule} from './pages/fluxos-chatbot/desenhar-propriedade/desenhar-propriedade.module';
import {FinishChatbotPageModule} from './pages/fluxos-chatbot/finish-chatbot/finish-chatbot.module';
import {InfoPessoalPageModule} from './pages/fluxos-chatbot/info-pessoal/info-pessoal.module';
import {MapaPropriedadesPageModule} from './pages/fluxos-chatbot/mapa-propriedades/mapa-propriedades.module';
import {ModalDetailPropriedadePageModule} from './pages/fluxos-chatbot/modal-detail-propriedade/modal-detail-propriedade.module';
import {ModalFormPropriedadePageModule} from './pages/fluxos-chatbot/modal-form-propriedade/modal-form-propriedade.module';
import {ModalMultipleChoicePageModule} from './pages/fluxos-chatbot/modal-multiple-choice/modal-multiple-choice.module';
import {ModalSelecionarMapasPageModule} from './pages/fluxos-chatbot/modal-selecionar-mapas/modal-selecionar-mapas.module';
import {ModalSelecionarCamadasPageModule} from './pages/fluxos-chatbot/modal-selecionar-camadas/modal-selecionar-camadas.module';
import {ModalSelecionarFiltrosPageModule} from './pages/fluxos-chatbot/modal-selecionar-filtros/modal-selecionar-filtros.module';
import {ModalLegendasMobileModule} from './pages/fluxos-chatbot/modal-legendas-mobile/modal-legendas-mobile.module';
import {ModalSmsPageModule} from './pages/fluxos-chatbot/modal-sms/modal-sms.module';
import {PropriedadeSelecionadaPageModule} from './pages/fluxos-chatbot/propriedade-selecionada/propriedade-selecionada.module';
import {BrMaskerModule} from 'br-mask';
import {StoreModule} from '@ngrx/store';
import {MapaReducer} from './store/reducers/mapa.reducers';
import ptBr from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import {ModalCadastroPageModule} from './pages/fluxos-chatbot/modal-cadastro/modal-cadastro.module';
import {ModalLoginPageModule} from './pages/fluxos-chatbot/modal-login/modal-login.module';
import { ModalEsqueciSenhaPageModule } from './pages/fluxos-chatbot/modal-esqueci-senha/modal-esqueci-senha.module';

registerLocaleData(ptBr);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrMaskerModule,
        HttpClientModule,
        BuscarPropriedadePageModule,
        ChatbotPageModule,
        DesenharPropriedadePageModule,
        FinishChatbotPageModule,
        InfoPessoalPageModule,
        MapaPropriedadesPageModule,
        ModalDetailPropriedadePageModule,
        ModalFormPropriedadePageModule,
        ModalSelecionarCamadasPageModule,
        ModalSelecionarMapasPageModule,
        ModalLegendasMobileModule,
        ModalSelecionarFiltrosPageModule,
        ModalMultipleChoicePageModule,
        ModalCadastroPageModule,
        ModalLoginPageModule,
        ModalEsqueciSenhaPageModule,
        ModalSmsPageModule,
        PropriedadeSelecionadaPageModule,
        StoreModule.forRoot({
            mapa: MapaReducer
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'pt'},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

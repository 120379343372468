import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "logo-superior-landing-page",
  templateUrl: "logo-superior-landing-page.component.html",
  styleUrls: ["logo-superior-landing-page.component.scss"],
})
export class LogoSuperiorLandingPageComponent {
  @Input() isOutline: boolean = false;
  @Output() onToggle: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onToggleMenu(): void {
    this.onToggle.emit(null);
  }
}

import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'mapa-propriedades',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/mapa-propriedades/mapa-propriedades.module').then(
                (m) => m.MapaPropriedadesPageModule
            ),
    },
    {
        path: 'mapa-propriedades/share/:id',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/mapa-propriedades/mapa-propriedades.module').then(
                (m) => m.MapaPropriedadesPageModule
            ),
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'parceiros',
        loadChildren: () =>
            import('./pages/parceiros/parceiros.module').then((m) => m.ParceirosPageModule),
    },
    {
        path: 'recuperacao-senha/:hash',
        loadChildren: () =>
            import('./pages/recuperacao-senha/recuperacao-senha.module').then((m) => m.RecuperacaoSenhaPageModule),
    },
    {
        path: 'info-pessoal',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/info-pessoal/info-pessoal.module').then(
                (m) => m.InfoPessoalPageModule
            ),
    },
    {
        path: 'chatbot/:id',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/chatbot/chatbot.module').then(
                (m) => m.ChatbotPageModule
            ),
    },
    {
        path: 'info-parceiro',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/info-parceiro/info-parceiro.module').then(
                (m) => m.InfoParceiroPageModule
            ),
    },
    {
        path: 'modal-sms',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/modal-sms/modal-sms.module').then(
                (m) => m.ModalSmsPageModule
            ),
    },
    {
        path: 'modal-multiple-choice',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/modal-multiple-choice/modal-multiple-choice.module'
            ).then((m) => m.ModalMultipleChoicePageModule),
    },
    {
        path: 'buscar-propriedade',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/buscar-propriedade/buscar-propriedade.module').then(
                (m) => m.BuscarPropriedadePageModule
            ),
    },
    {
        path: 'modal-detail-propriedade',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/modal-detail-propriedade/modal-detail-propriedade.module'
            ).then((m) => m.ModalDetailPropriedadePageModule),
    },
    {
        path: 'propriedade-selecionada',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/propriedade-selecionada/propriedade-selecionada.module'
            ).then((m) => m.PropriedadeSelecionadaPageModule),
    },
    {
        path: 'finish-chatbot',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/finish-chatbot/finish-chatbot.module').then(
                (m) => m.FinishChatbotPageModule
            ),
    },
    {
        path: 'desenhar-propriedade',
        loadChildren: () =>
            import('./pages/fluxos-chatbot/desenhar-propriedade/desenhar-propriedade.module').then(
                (m) => m.DesenharPropriedadePageModule
            ),
    },
    {
        path: 'modal-form-propriedade',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/modal-form-propriedade/modal-form-propriedade.module'
            ).then((m) => m.ModalFormPropriedadePageModule),
    },
    {
        path: 'encontrar-propriedade',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/encontrar-propriedade/encontrar-propriedade.module'
            ).then((m) => m.EncontrarPropriedadePageModule),
    },
    {
        path: 'mapa-encontrar-propriedade',
        loadChildren: () =>
            import(
                './pages/fluxos-chatbot/mapa-encontrar-propriedade/mapa-encontrar-propriedade.module'
            ).then((m) => m.MapaEncontrarPropriedadePageModule),
    },
  {
    path: 'modal-sum-percetage',
    loadChildren: () => import('./pages/fluxos-chatbot/modal-sum-percetage/modal-sum-percetage.module').then( m => m.ModalSumPercetagePageModule)
  },
  {
    path: 'modal-ajuda',
    loadChildren: () => import('./pages/fluxos-chatbot/modal-ajuda/modal-ajuda.module').then( m => m.ModalAjudaPageModule)
  },
  {
    path: 'modal-mobile-options',
    loadChildren: () => import('./pages/fluxos-chatbot/modal-mobile-options/modal-mobile-options.module').then( m => m.ModalMobileOptionsPageModule)
  },
  {
    path: 'modal-buscar-municipio',
    loadChildren: () => import('./pages/fluxos-chatbot/modal-buscar-municipio/modal-buscar-municipio.module').then( m => m.ModalBuscarMunicipioPageModule)
  },
  {
    path: 'info-especialista',
    loadChildren: () => import('./pages/fluxos-chatbot/info-especialista/info-especialista.module').then( m => m.InfoEspecialistaPageModule)
  },

]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

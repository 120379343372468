import { ToastController } from '@ionic/angular';
import { AbstractControl } from '@angular/forms';

const SameDigitsCNPJ = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
];

export function formatarQuantidade(quantidade) {
  let val;
  let decimal;

  if (!quantidade) {
    return;
  }

  if (typeof quantidade != 'string') {
    quantidade = quantidade.toString().replace('.', ',');
  }

  if (quantidade.search(/[,]/g) >= 0) {
    decimal = ',' + quantidade.split(',')[1];
    val = quantidade.split(',')[0];
  } else {
    val = quantidade.replace(/\D/gi, '');
  }

  if (!val) {
    return '';
  }

  const reverse = val.toString().split('').reverse().join('');
  const thousands = reverse.match(/\d{1,3}/g);

  if (decimal) {
    val = thousands.join('.').split('').reverse().join('') + decimal;
  } else {
    val = thousands.join('.').split('').reverse().join('');
  }

  return val.replace(/(,\d{2})\d+/, '$1');
}

export function formatarPercentual(numero) {
  if (numero && numero.toString().length > 5) {
    return numero.toString().split('').slice(0, 5).join('');
  }
}

export function ensureOutroOnFinal(options: any[]) {
  let outro = false;
  const patternOutro = /(outr(o|a)s?)/i;
  const lista = (options || []).filter((a) => {
    if (!outro && patternOutro.test(a)) {
      outro = (a.match(patternOutro) || [])[1];
    }
    return !patternOutro.test(a);
  });
  if (outro) {
    lista.push(outro);
  }
  return lista;
}

export function calcularTotal(param_1?, param_2?, param_3?) {
  let precoAtual;
  let quantidade;
  let valor;

  precoAtual = param_1.replace(/\D/g, '');
  precoAtual = (Number(precoAtual) / 100).toFixed(2) + '';
  quantidade = Number(param_2.toString().replaceAll(/\D/g, ''));

  if (precoAtual > 0 && quantidade > 0) {
    valor = (precoAtual * quantidade).toFixed(2).replace('.', ',');
    valor = valor.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    return valor.replace(/(\d)(\d{3}),/g, '$1.$2,');
  } else {
    return '0,00';
  }
}

export function replaceDinheiro(num) {
  return num.replace(',', '');
}

export function dicalogin(nomecompleto) {
  nomecompleto = nomecompleto.replace(/\s(de|da|dos|das)\s/g, ' '); // Remove os de,da, dos,das.
  return nomecompleto.split(' ')[0].substring(0, 1) + nomecompleto.split(' ')[1].substring(0, 1);
}

export function validarEmail(email) {
  // const regex = /\S+@\S+\.\S+/;
  const regex = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
  return regex.test(email);
}

export function removerAcentos(newStringComAcento) {
  if (!newStringComAcento) {
    return;
  }
  let str = (newStringComAcento).toString();
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g
  };

  // tslint:disable-next-line:forin
  for (const letra in mapaAcentosHex) {
    const expressaoRegular = mapaAcentosHex[letra];
    str = str.replace(expressaoRegular, letra);
  }

  return str;
}

export function parseUrl(url: string): any {
  url = url.replace(/^((?:.*)\?)/, '');
  const result = {};
  url.split('&').forEach(part => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function getAppUrl() {
  const baseUrl = getCookie('baseUrl');
  return baseUrl;
}

export function parseResult(obj) {
  return JSON.parse(obj);
}

export function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // tslint:disable-next-line:triple-equals
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // tslint:disable-next-line:triple-equals
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export async function showToastMessage(msg, color?: any) {
  const toastCtrl = new ToastController();
  const toast = await toastCtrl.create({
    color,
    position: 'top',
    message: msg,
    duration: 3000,
    cssClass: 'custom-toast-message'
  });
  await toast.present();
}

export function validarCNPJ(cnpj) {
  if (!cnpj || !cnpj.trim()) {
    return false;
  }

  cnpj = cnpj.replace(/\D/gi, '');

  if (cnpj.length < 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (SameDigitsCNPJ.indexOf(cnpj) >= 0) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let soma = 0;
  let pos = tamanho - 7;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado.toString() !== digitos.charAt(0)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

  return resultado.toString() === digitos.charAt(1);


}

// http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
export function isCpfValid(cpf: string) {
  const invalidCpf = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  if (!cpf) {
    return false;
  }

  let sum = 0;
  let rest = 0;

  cpf = cpf.replace(/[^0-9]/g, '');

  if (cpf.length > 11) {
    return false;
  }

  if (invalidCpf.includes(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  return rest === parseInt(cpf.substring(10, 11), 10);

}

export function cpfValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value || control.value.length < 11) {
    return { continueTypingCpf: true };
  }

  const valid = isCpfValid(control.value);

  if (!valid) {
    return { invalidCpf: true };
  }

  return null;
}

export function joinParams(params: object) {
  const result = [];
  Object.keys(params).forEach(key => {
    if (params[key] || params[key] === 0) {
      result.push(`${ key }=${ params[key] }`);
    }
  });

  return result.join('&');
}

export function freezeDeep<T>(obj: T) {
  if (obj && typeof obj === 'object') {
    Object.freeze(obj);
    Object.values(obj).forEach(freezeDeep);
  }
  return obj;
}

export function parseDecimals(valor: number) {
  if (!valor) {
    return valor;
  }

  return (Math.round(valor * 100) / 100)
    .toFixed(2)
    .replace('.', ',');
}

export function parseTextToDouble(valor: string) {
  if (typeof valor !== 'string') {
    return valor;
  }

  return +valor.replace(/[^\d,]+/gi, '').replace(',', '.');
}

export function parseDoubleToText(number: number) {
  if (typeof number !== 'number') {
    return number;
  }

  const valor = number.toString();
  const array = valor.split('.');

  if (array.length === 1) {
    return valor.concat(',00');
  } else {
    return valor.replace('.', ',');
  }
}

export function getExtension(file) {
  const basename = file.split(/[\\/]/).pop();
  const posPonto = basename.lastIndexOf('.');
  return basename.slice(posPonto + 1);
}

import {Component, Input, OnInit} from '@angular/core';
import {
    AnimationController,
    AnimationBuilder,
    LoadingController,
    ModalController,
    Platform,
    PopoverController,
    ToastController
} from '@ionic/angular';
import * as L from 'leaflet';
import * as Constants from '@services/contants.service';
import {DataPropriedades} from '../../../models/Propriedades';
import {ModalAjudaPage} from '../modal-ajuda/modal-ajuda.page';
import {ModalDetailPropriedadePage} from '../modal-detail-propriedade/modal-detail-propriedade.page';
import {ModalSelecionarMapasPage} from '../modal-selecionar-mapas/modal-selecionar-mapas.page';
import {ModalSelecionarCamadasPage} from '../modal-selecionar-camadas/modal-selecionar-camadas.page';
import {ModalSelecionarFiltrosPage} from '../modal-selecionar-filtros/modal-selecionar-filtros.page';
import {Uf} from 'app/models/Uf';
import {EncontrarPropriedadePage} from '../encontrar-propriedade/encontrar-propriedade.page';
import {ModalMobileOptionsPage} from '../modal-mobile-options/modal-mobile-options.page';
import {DetalheRegiaoPinPageComponent} from 'app/components/detalhe-regiao-pin-page/detalhe-regiao-pin-page.component';
import {City} from 'app/models/City';
import {TerralogsService} from '@services/terralogs.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DetalheRegiaoLimitrofeComponent} from 'app/components/detalhe-regiao-limitrofe/detalhe-regiao-limitrofe.component';
import {CamadasService} from '@services/camadas.service';
import {ModalLegendasMobilePage} from '../modal-legendas-mobile/modal-legendas-mobile.page';
import {ModalLimitePage} from '../modal-limite/modal-limite.page';
import {Municipio} from '../../../models/Municipio';
import {DominiosService} from '@services/dominios.service';

const mapIconSigef = L.icon({
    iconUrl: Constants.MAP_ICON_SIGEF,
    iconSize: [32, 32],
});
const mapIconAssentamentos = L.icon({
    iconUrl: Constants.MAP_ICON_ASSENTAMENTOS,
    iconSize: [32, 32],
});
const mapIconCAR = L.icon({
    iconUrl: Constants.MAP_ICON_CAR,
    iconSize: [32, 32],
});

const mapIconLimitrofe = L.icon({
    iconUrl: Constants.MAP_ICON_LIMITROFE,
    iconSize: [32, 32],
});

const mapIconPivor = L.icon({
    iconUrl: Constants.MAP_ICON_PIVOS,
    iconSize: [32, 32],
});

const mapIconTerrasIndigenas = L.icon({
    iconUrl: Constants.MAP_ICON_INDIGENAS,
    iconSize: [32, 32],
});

const mapIconQuilombolas = L.icon({
    iconUrl: Constants.MAP_ICON_QUILOMBOLAS,
    iconSize: [32, 32],
});

const mapIconUnidadeConservacao = L.icon({
    iconUrl: Constants.MAP_ICON_CONSERVACAO,
    iconSize: [32, 32],
});

const mapIconPortos = L.icon({
    iconUrl: Constants.MAP_ICON_PORTOS,
    iconSize: [32, 32],
});

const mapIconFlorestais = L.icon({
    iconUrl: Constants.MAP_ICON_FLORESTAIS,
    iconSize: [32, 32],
});

const mapIconSilos = L.icon({
    iconUrl: Constants.MAP_ICON_SILOS,
    iconSize: [32, 32],
});

const mapIconFrigorificos = L.icon({
    iconUrl: Constants.MAP_ICON_FRIGORIFICO,
    iconSize: [32, 32],
});

const mapIconUsinas = L.icon({
    iconUrl: Constants.MAP_ICON_USINAS,
    iconSize: [32, 32],
});

const mapIconLaticionios = L.icon({
    iconUrl: Constants.MAP_ICON_LATICINIOS,
    iconSize: [32, 32],
});

const mapIconIndustriasSuco = L.icon({
    iconUrl: Constants.MAP_ICON_SUCO,
    iconSize: [32, 32],
});

const mapIconConcessionariasTrator = L.icon({
    iconUrl: Constants.MAP_ICON_CONCESSIONARIAS_TRATOR,
    iconSize: [32, 32],
});

const mapIconWaterResource = L.icon({
    iconUrl: Constants.MAP_ICON_WATER_RESOURCE,
    iconSize: [32, 32],
});

@Component({
    selector: 'app-mapa-propriedades',
    templateUrl: './mapa-propriedades.page.html',
    styleUrls: ['./mapa-propriedades.page.scss'],
})
export class MapaPropriedadesPage implements OnInit {
    @Input() data: DataPropriedades[];
    @Input() tamanho: string;
    @Input() city: string;
    @Input() cityInfo: City;
    @Input() state: string;
    @Input() isFinanciar: boolean;
    @Input() uf: Uf;
    @Input() listModals: string[] = [];
    @Input() zoomInicial = 9;
    @Input() modalEscolherPropriedade = false;
    legendaAberta = false;
    modalMapaSelecionadaAberta = false;
    modalCamadaSelecionadaAberta = false;
    modalFiltroSelecionadoAberto = false;
    modalLegendaMobileAberta = false;
    filteredOptions: any[] = [];
    tileSelecionada = 'padrao';
    showLegenda = false;
    primeiraVisualizacao = true;
    mobile = false;
    cidade = {
        name: '',
        id: ''
    };
    initialPosition = [-15.793889, -47.882778];
    idShare;
    mostrarLista = false;
    labelLegenda;
    solosLegenda = [];
    biomasLegenda = [];
    climasLegenda = [];
    precipitacoesLegenda = [];
    temperaturaLegenda = [];
    polyContorno;

    legendas = [];

    filtros = [];
    municipios: Municipio[] = [];
    ufs = [];

    public map: L.map;
    public markersSigef = new L.markerClusterGroup({maxClusterRadius: 1});
    public markersTerrasIndigenas = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersPivos = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersAssentamentos = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersCAR = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersQuilombolas = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersUnidadesConservacao = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersPortos = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersFlorestais = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersSilos = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersFrigorificos = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersUsinas = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersLaticinios = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersIndustriasSuco = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersConcessionariasTrator = new L.markerClusterGroup({maxClusterRadius: 0});
    public markersRegiao = new L.markerClusterGroup();
    public markersRegiaoSigefCar = new L.markerClusterGroup();
    public markersRegiaoLimitrofes = new L.markerClusterGroup({maxClusterRadius: 1});
    public markersRegiaoFerrovias = new L.markerClusterGroup();
    public markersWaterResources = new L.markerClusterGroup();

    constructor(
        private modalController: ModalController,
        private loadingController: LoadingController,
        private popoverController: PopoverController,
        private terralogsService: TerralogsService,
        private camadasService: CamadasService,
        private activetedRouter: ActivatedRoute,
        private animationCtrl: AnimationController,
        private dominiosService: DominiosService,
        private route: ActivatedRoute,
        private platform: Platform,
        private router: Router,
        private toastController: ToastController
    ) {

        this.mobile = this.platform.width() < 1114;

        this.route.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation()?.extras?.state) {
                this.zoomInicial = this.router.getCurrentNavigation().extras.state.zoomInicial;
                this.city = this.router.getCurrentNavigation().extras.state.city;
                this.cityInfo = this.router.getCurrentNavigation().extras.state.cityInfo;
                this.data = this.router.getCurrentNavigation().extras.state.data;
                this.isFinanciar = this.router.getCurrentNavigation().extras.state.isFinanciar;
                this.listModals = this.router.getCurrentNavigation().extras.state.listModals;
                this.state = this.router.getCurrentNavigation().extras.state.state;
                this.uf = this.router.getCurrentNavigation().extras.state.uf;
            }
        });
    }

    async ngOnInit(): Promise<void> {
        const {data} = await this.terralogsService.login();
        localStorage.setItem('token', data.token);
        this.municipios = await this.dominiosService.getMunicipio('');
        this.ufs = await this.dominiosService.getUf();
        this.solosLegenda = this.camadasService.getSolosLegenda();
        this.biomasLegenda = this.camadasService.getBiomasLegenda();
        this.climasLegenda = this.camadasService.getClimasLegenda();
        this.precipitacoesLegenda = this.camadasService.getPrecipitacaoLegenda();
        this.temperaturaLegenda = this.camadasService.getTemperaturaLegenda();
        this.idShare = this.activetedRouter.snapshot.params.id;
        if (this.idShare) {
            await (await this.loadingController.create()).present();
            const responseMatricula = await this.terralogsService.getMatricula(this.idShare);
            const nameCity = responseMatricula.data.city.name;
            const paramsCity = {
                type: 'city',
                query: nameCity && nameCity.length > 0 ? nameCity : '',
                _limit: 25
            };
            const responseCity = await this.terralogsService.search(paramsCity);
            this.getPropriedade(responseCity.data[0]);
            this.detailPoint(null, responseMatricula.data, mapIconSigef, responseMatricula.data.origin);
            await this.loadingController.dismiss();
        }
    }

    async ionViewDidEnter() {
        this.initialPosition = [0, 0];
        if (this.data) {
            const inicialPosicaoJson = JSON.stringify(this.cityInfo.geometry_centroid);
            const inicialPosicaoParse = JSON.parse(inicialPosicaoJson);
            this.initialPosition = inicialPosicaoParse.reverse();
            if (this.map) {
                await this.map.flyTo(this.initialPosition, 9);
            }
        } else {
            this.initialPosition = await [-15.793889, -47.882778];
        }

        if (this.map) {
            this.map.invalidateSize(false);
        }
        this.resetFiltros();
        if (this.city) {
            this.cidade.name = this.city + ' (' + this.state + ')';
        }
    }

    ionViewDidLeave() {
        this.reset();
    }


    reset() {
        this.limparMapa();
        this.map = undefined;
        this.data = null;
        this.tamanho = null;
        this.city = null;
        this.cityInfo = null;
        this.state = null;
        this.uf = null;
        this.legendaAberta = false;
        this.isFinanciar = false;
        this.listModals = [];
        this.modalMapaSelecionadaAberta = false;
        this.modalCamadaSelecionadaAberta = false;
        this.modalFiltroSelecionadoAberto = false;
        this.modalLegendaMobileAberta = false;
        this.showLegenda = false;
        this.idShare = null;
        this.cidade.name = '';
        if (this.map) {
            this.map.remove();
        }
    }


    toggleLegenda() {
        this.legendaAberta = !this.legendaAberta;
    }

    resetFiltros() {
        this.filtros = [
            {
                nome: 'sigef',
                ativo: true
            },
            {
                nome: 'car',
                ativo: false
            },
            {
                nome: 'silos',
                ativo: false
            },
            {
                nome: 'frigorificos',
                ativo: false
            },
            {
                nome: 'usinas',
                ativo: false
            },
            {
                nome: 'laticinios',
                ativo: false
            },
            {
                nome: 'florestais',
                ativo: false
            },
            {
                nome: 'industriasSuco',
                ativo: false
            },
            {
                nome: 'concessionariasTrator',
                ativo: false
            },
            {
                nome: 'recursosHidricos',
                ativo: false
            },
            {
                nome: 'pivos',
                ativo: false
            },
            {
                nome: 'portos',
                ativo: false
            },
            {
                nome: 'assentamentos',
                ativo: false
            },
            {
                nome: 'terrasIndigenas',
                ativo: false
            },
            {
                nome: 'quilombolas',
                ativo: false
            },
            {
                nome: 'unidadesConservacao',
                ativo: false
            },
            {
                nome: 'solos',
                ativo: false
            },
            {
                nome: 'biomas',
                ativo: false
            },
            {
                nome: 'clima',
                ativo: false
            },
            {
                nome: 'amazonia',
                ativo: false
            },
            {
                nome: 'precipitacoes',
                ativo: false
            },
            {
                nome: 'temperatura',
                ativo: false
            },
            {
                nome: 'ferrovias',
                ativo: false
            },
            {
                nome: 'nenhuma',
                ativo: false
            }
        ];
    }

    async prosseguirSemPropriedade() {
        await this.modalController.dismiss();
        await this.modalController.dismiss({propriedade: {}});
    }

    loadMap(mapa) {
        this.map = mapa;
        if (this.map) {
            L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map);
        }
        this.inicializar();

    }

    async inicializar() {
        if (this.data) {
            await this.markersRegiao.clearLayers();
            await this.markersRegiaoLimitrofes.clearLayers();
            const coorCity = [];
            this.cityInfo.geometry_smoothed.coordinates[0].forEach((coor) => {
                const coorCityJson = JSON.stringify(coor);
                coorCity.push(JSON.parse(coorCityJson).reverse());
            });
            this.polyContorno = await L.polygon(coorCity, {fillColor: 'transparent', color: '#00AE4E', fillOpacity: 0.4}).addTo(this.map);
            this.markersRegiao.addLayer(this.polyContorno);
            this.map.addLayer(this.markersRegiao);
            this.populateMapPins(this.data, mapIconSigef, this.markersSigef, 'sigef');
            // limitrofes
            const limitrofes = this.cityInfo.neighbors;
            limitrofes.forEach(async (limitrofe) => {
                // TODO: Caso queira deixar os municípios limítrofes com área demarcada visível num "hover" do mouse
                // const coords = [];
                // limitrofe.geometry_smoothed.coordinates[0].forEach((coor) => {
                //     const coordsjson = JSON.stringify(coor);
                //     coords.push(JSON.parse(coordsjson).reverse());
                // });
                // this.polyContorno = await L.polygon(coords, {fillColor: 'transparent', color: 'transparent', opacity: 0, fillOpacity: 0})
                //     .on('mouseover', el => el.target.setStyle({fillColor: 'white', color: '#00AE4E', opacity: 0.3, fillOpacity: 0.8}))
                //     .on('mouseout', el => el.target.setStyle({fillColor: 'transparent', color: 'transparent', opacity: 0, fillOpacity: 0}))
                //     .addTo(this.map);

                const limotrofeCoordJson = JSON.stringify(limitrofe);
                const limotrofeCoord = JSON.parse(limotrofeCoordJson).geometry_centroid.reverse();
                const newPin = await new L.marker(limotrofeCoord, {icon: mapIconLimitrofe, title: `${limitrofe.name} - ${limitrofe.state.toUpperCase()}`}).on(
                    'click',
                    (layerClicked) => {
                        this.detalheRegiaoLimitrofe(layerClicked, limitrofe);
                    }
                );
                this.markersRegiaoLimitrofes.addLayer(newPin);
            });
            this.map.addLayer(this.markersRegiaoLimitrofes);
            this.primeiraVisualizacao = false;
        }
    }

    naoEncontreiPropriedade() {
        window.open('https://api.whatsapp.com/send?phone=5511996511968&text=N%C3%A3o%20encontrei%20minha%20propriedade%2C%20como%20devo%20prossegui%3F', '_blank');
    }

    limparMapa() {
        this.markersSigef.clearLayers();
        this.markersTerrasIndigenas.clearLayers();
        this.markersPivos.clearLayers();
        this.markersAssentamentos.clearLayers();
        this.markersCAR.clearLayers();
        this.markersQuilombolas.clearLayers();
        this.markersUnidadesConservacao.clearLayers();
        this.markersPortos.clearLayers();
        this.markersFlorestais.clearLayers();
        this.markersSilos.clearLayers();
        this.markersFrigorificos.clearLayers();
        this.markersUsinas.clearLayers();
        this.markersLaticinios.clearLayers();
        this.markersIndustriasSuco.clearLayers();
        this.markersConcessionariasTrator.clearLayers();
        this.markersRegiaoFerrovias.clearLayers();
        this.markersWaterResources.clearLayers();
        this.markersRegiao.clearLayers();
        this.markersRegiaoSigefCar.clearLayers();
        this.markersRegiaoLimitrofes.clearLayers();
        this.resetFiltros();
        this.primeiraVisualizacao = true;
        this.showLegenda = false;
    }

    populateFerrovias(data) {

        if (data) {
            const coorFerrovias = [];
            data.forEach((properties) => {
                properties.geometry.coordinates.forEach((coor) => {
                    const coorCityJson = JSON.stringify(coor);
                    coorFerrovias.push(JSON.parse(coorCityJson).reverse());
                });
                const polyline = L.polyline(coorFerrovias, {color: 'red'});
                this.markersRegiaoFerrovias.addLayer(polyline);
            });
            this.map.addLayer(this.markersRegiaoFerrovias);
        }
    }

    populateWaterResources(data) {
        if (data) {
            data.forEach(async (properties) => {
                let centroid = [];
                const openModal = (layerClicked) => {
                    this.detalheRegiao(layerClicked, properties, mapIconWaterResource, 'water-resource');
                };
                if (properties.geometry.type === 'LineString') {
                    const coorWaterResources = properties.geometry.coordinates.map((coor) => {
                        return [coor[1], coor[0]];
                    });
                    const polyline = L.polyline(coorWaterResources, {color: '#85d9ff', weight: 3, opacity: 0.8})
                        .on('mouseover', el => el.target.setStyle({color: '#4eb3e1', weight: 5, opacity: 1}))
                        .on('mouseout', el => el.target.setStyle({color: '#85d9ff', weight: 3, opacity: 0.8}))
                        .on('click', openModal);
                    this.markersWaterResources.addLayer(polyline);
                    centroid = coorWaterResources[Math.round(coorWaterResources.length / 2)];
                }
                if (properties.geometry.type === 'Polygon') {
                    const poly = [];
                    properties.geometry_smoothed.coordinates[0].forEach((coor) => {
                        poly.push(JSON.parse(JSON.stringify(coor)).reverse());
                    });
                    const polyg = L.polygon(poly, {color: '#85d9ff', weight: 2, opacity: 0.7}).addTo(this.map);
                    if (properties.geometry_centroid && properties.geometry_centroid.length > 0) {
                        centroid = JSON.parse(JSON.stringify(properties.geometry_centroid)).reverse();
                    } else {
                        centroid = poly[Math.round(poly.length / 2)];
                    }
                    this.markersWaterResources.addLayer(polyg);
                }

                const newPin = await new L.marker(centroid, {icon: mapIconWaterResource, title: `${properties.name}`}).on('click', openModal);
                this.markersWaterResources.addLayer(newPin);
            });
            this.map.addLayer(this.markersWaterResources);
        }
    }

    async populateMapPins(data, mapIcon = mapIconSigef, markers, tipo) {

        if (data) {
            await data.forEach(async (properties) => {
                const [lng, lat] = properties.geometry_centroid;

                if (tipo !== 'sigef') {
                    const newPin = await new L.marker([lat, lng], {icon: mapIcon}).on(
                        'click',
                        (layerClicked) => {
                            if (tipo !== 'pivos' && tipo !== 'car') {
                                this.detalheRegiao(layerClicked, properties, mapIcon, tipo);
                            } else if (tipo === 'car') {
                                this.detailPoint(layerClicked, properties, mapIcon, tipo);
                            }
                        }
                    );
                    markers.addLayer(newPin);
                }

                if (tipo === 'sigef') {

                    const coorCity = [];
                    properties.geometry_smoothed.coordinates[0].forEach((coor) => {
                        const coorCityJson = JSON.stringify(coor);
                        coorCity.push(JSON.parse(coorCityJson).reverse());
                    });
                    const poly = await L.polygon(coorCity, {
                        fillColor: 'green',
                        color: this.tileSelecionada !== 'padrao' ? '#ffffff' : '#696969',
                        fillOpacity: 0.4
                    })
                        .on(
                            'click',
                            (layerClicked) => {
                                this.detailPoint(layerClicked, properties, mapIcon, tipo);
                            }
                        );
                    this.markersRegiao.addLayer(poly);
                }
            });
            this.map.addLayer(markers);
        }
    }


    goTo(rota) {
        this.router.navigateByUrl(rota);
    }

    async populateMapCamadas(data) {

        await this.markersRegiao.clearLayers();
        await this.markersRegiao.addLayer(this.polyContorno);
        await data.forEach((properties) => {

            if (properties.geometry_smoothed) {
                const poly = [];

                properties.geometry_smoothed.coordinates[0].forEach((coor) => {
                    const coorCityJson = JSON.stringify(coor);
                    poly.push(JSON.parse(coorCityJson).reverse());
                });

                if (properties.color) {
                    if (properties.color.indexOf('#') === -1) {
                        properties.color = '#' + properties.color;
                    }
                }

                const polyg = L.polygon(poly, {color: properties.color}).addTo(this.map);

                // markers.addLayer(polyg);
                this.markersRegiao.addLayer(polyg);
            }
        });
        await this.populateMapPins(this.data, mapIconSigef, this.markersSigef, 'sigef');
        // this.map.addLayer(markers);
    }

    retornarMunicipio(valor) {
        return this.municipios.find( municipio => municipio.nome === valor);
    }


    async detailPoint(selectedPinLayer: L.marker, dataPropriedade: DataPropriedades, mapaIcon = mapIconSigef, tipo) {

        const codigoMunicipo = this.retornarMunicipio(dataPropriedade.city.name).codigo_ibge;
        const codigoUf = this.retornarMunicipio(dataPropriedade.city.name).codigo_uf;

        const usuarioLogado = this.terralogsService.retonarUsuarioLogado();

        if (!usuarioLogado && !this.modalEscolherPropriedade) {
            const quantidadeAcesso = +this.terralogsService.quantidadeAcesso() || 0;
            if (quantidadeAcesso >= 3) {
                return await this.modalLimite();
            }

            await this.terralogsService.alterarQuantidadeAcesso(quantidadeAcesso + 1);
        }


        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-detail-propriedade' + idModal);
        const modal = await this.modalController.create({
            component: ModalDetailPropriedadePage,
            cssClass: this.mobile ? 'modal-detalhe-marcacao-mobile' : 'modal-detalhe-marcacao',
            backdropDismiss: true,
            componentProps: {
                dataPropriedade,
                codigoMunicipo,
                codigoUf,
                map: this.map,
                tamanho: this.tamanho,
                isFinanciar: this.isFinanciar,
                uf: this.uf,
                listModals: this.listModals,
                tipo,
                idModalSelecionada: 'modal-detail-propriedade' + idModal,
                modalEscolherPropriedade: this.modalEscolherPropriedade
            },
            id: 'modal-detail-propriedade' + idModal,
        });

        await modal.present();
        await modal.onWillDismiss();
    }

    async modalLimite() {
        const modal = await this.modalController.create({
            component: ModalLimitePage,
            cssClass: ['modal-small', 'modal-limite'],
            backdropDismiss: true,
            id: 'modal-login'
        });
        await modal.present();
        await modal.onWillDismiss();
    }

    async detalheRegiaoLimitrofe(ev: any, properties) {
        const popover = await this.popoverController.create({
            component: DetalheRegiaoLimitrofeComponent,
            cssClass: ['popover-detalhe', this.mobile ? 'popover-mobile' : ''],
            translucent: true,
            mode: 'ios',
            componentProps: {
                properties
            }
        });
        await popover.present();

        const role = await popover.onDidDismiss();
        if (role.data && role.data.info) {
            const city = role.data.info.name;
            const state = role.data.info.state;
            const paramsCity = {
                type: 'city',
                query: city && city.length > 0 ? `${city} ${state}` : '',
                _limit: 25
            };
            const responseCity = await this.terralogsService.search(paramsCity);
            this.getPropriedade(responseCity.data[0]);
        }
    }

    async detalheRegiao(ev: any, properties, icon, tipo) {
        const popover = await this.popoverController.create({
            component: DetalheRegiaoPinPageComponent,
            cssClass: ['popover-detalhe', this.mobile ? 'popover-mobile' : ''],
            translucent: true,
            mode: 'ios',
            componentProps: {
                properties,
                icon,
                tipo
            }
        });
        await popover.present();
        await popover.onDidDismiss();
    }

    async recentralizar() {
        if (this.map && this.data) {
            const inicialPosicaoJson = JSON.stringify(this.cityInfo.geometry_centroid);
            const inicialPosicaoParse = JSON.parse(inicialPosicaoJson);
            this.map.flyTo(inicialPosicaoParse.reverse(), 9);
        }
    }

    private fromRightModal(): { enterAnimation: AnimationBuilder, leaveAnimation: AnimationBuilder } {

        const enterAnimation = (baseEl: any) => {
            const backdropAnimation = this.animationCtrl.create()
                // tslint:disable-next-line:no-non-null-assertion
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

            const wrapperAnimation = this.animationCtrl.create()
                // tslint:disable-next-line:no-non-null-assertion
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .beforeStyles({
                    ['opacity']: 1,
                    ['transform']: 'translate3d(100vw, 0, 0)',
                })
                .keyframes([
                    {offset: 0, transform: 'translate3d(100vw, 0, 0)'},
                    {offset: 1, transform: 'translate3d(0, 0, 0)'}
                ]);

            return this.animationCtrl.create()
                .addElement(baseEl)
                .easing('ease-in-out')
                .duration(300)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        };

        const leaveAnimation = (baseEl: any) => {
            return enterAnimation(baseEl).direction('reverse');
        };

        return {enterAnimation, leaveAnimation};
    }


    async selecionarFiltro() {
        const animation = this.fromRightModal();
        this.modalFiltroSelecionadoAberto = true;
        const modal = await this.modalController.create({
            component: ModalSelecionarFiltrosPage,
            cssClass: this.mobile ? 'modal-escolher-filtro-mobile' : 'modal-escolher-filtro',
            backdropDismiss: true,
            enterAnimation: animation?.enterAnimation,
            leaveAnimation: animation?.leaveAnimation,
            componentProps: {
                city: this.city,
                state: this.state,
                cityInfo: this.cityInfo,
                filtros: this.filtros
            }
        });
        await modal.present();
        await modal.onWillDismiss().then(async (valor) => {
            if (valor.data && valor.data.ativo && valor.data.retorno) {
                this.filtros = valor.data.filtros;
                switch (valor.data.tipo) {
                    case 'pivos': {
                        this.populateMapPins(valor.data.retorno, mapIconPivor, this.markersPivos, 'pivos');
                        break;
                    }
                    case 'assentamentos': {
                        this.populateMapPins(valor.data.retorno, mapIconAssentamentos, this.markersAssentamentos, 'assentamentos');
                        break;
                    }
                    case 'car': {
                        this.populateMapPins(valor.data.retorno, mapIconCAR, this.markersCAR, 'car');
                        break;
                    }
                    case 'sigef': {
                        this.populateMapPins(valor.data.retorno, mapIconSigef, this.markersSigef, 'sigef');
                        break;
                    }
                    case 'terrasIndigenas': {
                        this.populateMapPins(valor.data.retorno, mapIconTerrasIndigenas, this.markersTerrasIndigenas, 'terrasIndigenas');
                        break;
                    }
                    case 'quilombolas': {
                        this.populateMapPins(valor.data.retorno, mapIconQuilombolas, this.markersQuilombolas, 'quilombolas');
                        break;
                    }
                    case 'unidadesConservacao': {
                        this.populateMapPins(valor.data.retorno, mapIconUnidadeConservacao, this.markersUnidadesConservacao, 'unidadesConservacao');
                        break;
                    }
                    case 'portos': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconPortos, this.markersPortos, 'portos');
                        break;
                    }
                    case 'florestais': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconFlorestais, this.markersFlorestais, 'florestais');
                        break;
                    }
                    case 'silos': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconSilos, this.markersSilos, 'silos');
                        break;
                    }
                    case 'frigorificos': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconFrigorificos, this.markersFrigorificos, 'frigorificos');
                        break;
                    }
                    case 'usinas': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconUsinas, this.markersUsinas, 'usinas');
                        break;
                    }
                    case 'laticinios': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconLaticionios, this.markersLaticinios, 'laticinios');
                        break;
                    }
                    case 'industriasSuco': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconIndustriasSuco, this.markersIndustriasSuco, 'industriasSuco');
                        break;
                    }
                    case 'concessionariasTrator': {
                        valor.data.retorno.forEach(val => {
                            val.geometry_centroid = val.geometry.coordinates;
                        });
                        this.populateMapPins(valor.data.retorno, mapIconConcessionariasTrator, this.markersConcessionariasTrator, 'concessionariasTrator');
                        break;
                    }
                    case 'ferrovias': {
                        this.populateFerrovias(valor.data.retorno);
                        break;
                    }
                    case 'recursosHidricos': {
                        this.populateWaterResources(valor.data.retorno);
                        break;
                    }
                }
            } else if (valor.data && !valor.data.ativo) {
                switch (valor.data.tipo) {
                    case 'sigef': {
                        this.markersRegiao.clearLayers();
                        this.markersRegiao.addLayer(this.polyContorno);
                        break;
                    }
                    case 'pivos': {
                        this.markersPivos.clearLayers();
                        break;
                    }
                    case 'assentamentos': {
                        this.markersAssentamentos.clearLayers();
                        break;
                    }
                    case 'car': {
                        this.markersCAR.clearLayers();
                        break;
                    }
                    case 'terrasIndigenas': {
                        this.markersTerrasIndigenas.clearLayers();
                        break;
                    }
                    case 'quilombolas': {
                        this.markersQuilombolas.clearLayers();
                        break;
                    }
                    case 'unidadesConservacao': {
                        this.markersUnidadesConservacao.clearLayers();
                        break;
                    }
                    case 'portos': {
                        this.markersPortos.clearLayers();
                        break;
                    }
                    case 'florestais': {
                        this.markersFlorestais.clearLayers();
                        break;
                    }
                    case 'silos': {
                        this.markersSilos.clearLayers();
                        break;
                    }
                    case 'frigorificos': {
                        this.markersFrigorificos.clearLayers();
                        break;
                    }
                    case 'usinas': {
                        this.markersUsinas.clearLayers();
                        break;
                    }
                    case 'laticinios': {
                        this.markersLaticinios.clearLayers();
                        break;
                    }
                    case 'industriasSuco': {
                        this.markersIndustriasSuco.clearLayers();
                        break;
                    }
                    case 'concessionariasTrator': {
                        this.markersConcessionariasTrator.clearLayers();
                        break;
                    }
                    case 'ferrovias': {
                        this.markersRegiaoFerrovias.clearLayers();
                        break;
                    }
                    case 'recursosHidricos': {
                        this.markersWaterResources.clearLayers();
                        break;
                    }
                }
            } else if (valor.data && !valor.data.retorno) {
                const toast = await this.toastController.create({
                    message: 'Não existe ' + valor.data.tipo + ' para ' + this.city,
                    position: 'top',
                    color: 'warning',
                    animated: true,
                    duration: 4000
                });
                await toast.present();
            }

            this.modalFiltroSelecionadoAberto = false;
        });
    }

    async selecionarMapa() {
        const animation = this.fromRightModal();
        this.modalMapaSelecionadaAberta = true;
        const modal = await this.modalController.create({
            component: ModalSelecionarMapasPage,
            cssClass: this.mobile ? 'modal-escolher-mapa-mobile' : 'modal-escolher-mapa',
            backdropDismiss: true,
            enterAnimation: animation?.enterAnimation,
            leaveAnimation: animation?.leaveAnimation,
            componentProps: {
                mapaSelecionado: this.tileSelecionada
            }
        });
        await modal.present();
        await modal.onWillDismiss().then(async (retorno) => {
            if (retorno.data) {
                this.tileSelecionada = retorno.data.mapa;
                if (retorno.data.mapa === 'padrao') {
                    await L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
                        maxZoom: 19,
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    }).addTo(this.map);
                } else {
                    await L.tileLayer(Constants.TILE_LAYER, {
                        attribution: '',
                        maxZoom: 18,
                        id: Constants.TILE_ID,
                        tileSize: 512,
                        zoomOffset: -1,
                        accessToken: Constants.TILE_ACCESS_TOKEN
                    }).addTo(this.map); // Add the Tile Layer to the map
                }
                await this.limparCamadas();
                await this.inicializar();
            }
            this.modalMapaSelecionadaAberta = false;
        });
    }

    async selecionarLegenda() {
        this.modalLegendaMobileAberta = true;
        const animation = this.fromRightModal();
        const modal = await this.modalController.create({
            component: ModalLegendasMobilePage,
            cssClass: 'modal-legenda-mobile',
            backdropDismiss: true,
            enterAnimation: animation?.enterAnimation,
            leaveAnimation: animation?.leaveAnimation,
            componentProps: {
                legendas: this.legendas
            }
        });
        await modal.present();
        await modal.onWillDismiss().then(async (retorno) => {
            if (retorno.data && retorno.data.retorno) {
            }
            this.modalLegendaMobileAberta = false;
        });
    }

    async selecionarCamada() {
        const animation = this.fromRightModal();
        this.modalCamadaSelecionadaAberta = true;
        const modal = await this.modalController.create({
            component: ModalSelecionarCamadasPage,
            cssClass: this.mobile ? 'modal-escolher-camada-mobile' : 'modal-escolher-camada',
            backdropDismiss: true,
            enterAnimation: animation?.enterAnimation,
            leaveAnimation: animation?.leaveAnimation,
            componentProps: {
                city: this.city,
                state: this.state,
                cityInfo: this.cityInfo,
                filtros: this.filtros
            }
        });
        await modal.present();
        await modal.onWillDismiss().then(async (retorno) => {
            if (retorno.data && retorno.data.ativo && retorno.data.retorno) {
                this.filtros = retorno.data.filtros;
                this.limparCamadas();
                this.showLegenda = true;
                switch (retorno.data.tipo) {
                    case 'nenhuma': {
                        this.limparCamadas();
                        this.modalCamadaSelecionadaAberta = false;
                        this.showLegenda = false;
                        this.resetFiltros();
                        break;
                    }
                    case 'solos': {
                        this.legendas = this.solosLegenda;
                        this.labelLegenda = 'Solos';
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                    case 'biomas': {
                        this.legendas = this.biomasLegenda;
                        retorno.data.retorno.forEach(val => {
                            val.color = this.biomasLegenda.find(v => v.nome === val.name).cor;
                        });
                        this.labelLegenda = 'Biomas';
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                    case 'clima': {
                        this.legendas = this.climasLegenda;
                        retorno.data.retorno.forEach(val => {
                            val.color = this.climasLegenda.find(v => v.value === val.code.toUpperCase()).cor;
                        });
                        this.labelLegenda = 'Climas';
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                    case 'amazonia': {
                        retorno.data.retorno.forEach(val => {
                            val.color = '83DB91';
                        });
                        this.showLegenda = false;
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                    case 'precipitacoes': {
                        retorno.data.retorno.forEach(val => {
                            val.color = this.precipitacoesLegenda.find(v => v.value === val.average_rainfall).cor;
                        });
                        this.legendas = this.precipitacoesLegenda;
                        this.labelLegenda = 'Precipitações';
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                    case 'temperatura': {
                        retorno.data.retorno.forEach(val => {
                            val.color = this.temperaturaLegenda.find(v => v.value === val.temperature).cor;
                        });
                        this.legendas = this.temperaturaLegenda;
                        this.labelLegenda = 'Temperaturas';
                        this.populateMapCamadas(retorno.data.retorno);
                        break;
                    }
                }
            } else if (retorno.data && !retorno.data.retorno) {
                this.showLegenda = false;
                const toast = await this.toastController.create({
                    message: 'Não existe ' + retorno.data.tipo + ' para ' + this.city,
                    position: 'top',
                    color: 'warning',
                    animated: true,
                    duration: 4000
                });
                await toast.present();
            }
            this.modalCamadaSelecionadaAberta = false;
        });
    }

    async limparCamadas() {
        await this.markersRegiao.clearLayers();
        await this.markersRegiao.addLayer(this.polyContorno);
        await this.populateMapPins(this.data, mapIconSigef, this.markersSigef, 'sigef');
    }

    async modalEncontrarPropriedade() {
        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('encontrar-propriedade' + idModal);
        const modal = await this.modalController.create({
            component: EncontrarPropriedadePage,
            cssClass: 'modal-full',
            backdropDismiss: true,
            id: 'encontrar-propriedade' + idModal,
            componentProps: {
                data: true,
                dataPropriedade: this.data[0],
                tamanho: this.tamanho,
                isFinanciar: this.isFinanciar,
                uf: this.uf,
                listModals: this.listModals,
                idModalSelecionada: 'encontrar-propriedade' + idModal
            },
        });
        await modal.present();
        await modal.onWillDismiss();
    }

    async openModalAjuda() {
        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-ajuda' + idModal);
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda' + idModal,
        });
        await modal.present();
    }

    async onFilterNew() {
        if (this.cidade.name && this.cidade.name !== '') {
            const splitCidade = this.cidade.name.split(' - ');
            const paramsCity = {
                type: 'city',
                query: splitCidade && splitCidade.length > 0 ? splitCidade[0] : '',
                _limit: 25
            };
            const responseCity = await this.terralogsService.search(paramsCity);
            this.filteredOptions = responseCity.data;
        } else {
            this.filteredOptions = [];
        }
    }


    async irMapa(cidade) {
        const splitCidade = cidade.name.split('(');

        await (await this.loadingController.create()).present();

        try {
            const limit = 200;
            const offset = 0;
            const params = {
                _limit: limit,
                _offset: offset,
                state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1).toLowerCase().trim() : '',
                city: splitCidade && splitCidade.length > 0 ? splitCidade[0].trim() : '',
                origin: 'sigef',
                _field: 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices',
                area_minimum: 30
            };
            let response: any = {
                data: []
            };
            response.data = await this.buscarPaginadoPropriedade(params, limit, offset, []);

            const city = await this.terralogsService.getCity(cidade.id);

            response = {
                ...response,
                state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1) : '',
                city: splitCidade && splitCidade.length > 0 ? splitCidade[0] : ''
            };

            this.data = response.data;
            this.city = response.city;
            this.state = response.state;
            this.cityInfo = city.data;
        } catch (error) {
            const toast = await this.toastController.create({
                message: 'Erro no serviço, tente novamente',
                position: 'top',
                color: 'danger',
                animated: true,
                duration: 4000
            });
            await toast.present();
        }

        await this.loadingController.dismiss();
    }

    async buscarPaginadoPropriedade(params, limit, offset, arrayValores) {

        const response: any = await this.terralogsService.getInfoPorTipo(params, 'enrollment');
        if (response) {
            arrayValores = arrayValores.concat(response.data);
            if (response.data.length === limit) {
                offset = offset + limit;
                params._offset = offset;
                return await this.buscarPaginadoPropriedade(params, limit, offset, arrayValores);
            }
            return await arrayValores;
        } else {
            return [];
        }

    }

    fecharBusca() {
        this.mostrarLista = false;
    }

    async getPropriedade(cidade) {
        this.cidade = cidade;
        this.filteredOptions = [];
        this.mostrarLista = false;
        this.limparMapa();
        await this.irMapa(this.cidade);
        await this.inicializar();
        if (this.data) {
            const inicialPosicaoJson = JSON.stringify(this.cityInfo.geometry_centroid);
            const inicialPosicaoParse = JSON.parse(inicialPosicaoJson);
            this.map.flyTo(inicialPosicaoParse.reverse(), 9);
        }
    }

    onClickMapa() {
        this.mostrarLista = true;
    }

    async openModalMobileOptions() {
        const modal = await this.modalController.create({
            component: ModalMobileOptionsPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-mobile-options',
            componentProps: {
                options: [
                    {
                        icon: 'assets/custom-icons/icone-pesquisar-modal-opcoes.svg',
                        label: 'Filtrar',
                        action: async () => this.modalEncontrarPropriedade(),
                        isDisabled: false
                    }, {
                        icon: 'assets/custom-icons/icon-ajuda-modal-opcoes.svg',
                        label: 'Ajuda',
                        action: async () => this.openModalAjuda(),
                        isDisabled: false
                    }
                ]
            },
        });
        await modal.present();
    }

    async onClose() {
        await (await this.loadingController.create()).present();
        await this.loadingController.dismiss();
    }
}

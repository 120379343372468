import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ModalCadastroPageRoutingModule} from './modal-cadastro-routing.module';
import {ModalCadastroPage} from './modal-cadastro.page';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMaskModule} from 'ngx-mask';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {PipesModule} from 'app/pipes/pipes.module';
import {BrMaskerModule} from 'br-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        NgxTrimDirectiveModule,
        BrMaskerModule,
        ModalCadastroPageRoutingModule,
        PipesModule
    ],
    declarations: [ModalCadastroPage]
})
export class ModalCadastroPageModule {
}

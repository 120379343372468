import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {City} from 'app/models/City';
import {Search} from 'app/models/Search';
import {Observable, Subject, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {DataPropriedades} from '../models/Propriedades';
import {catchError} from 'rxjs/operators';

interface ParamsRequestPropriedade {
    city?: string;
    query?: string;
    _field: string;
    _offset?: number;
    _limit?: number;
    area_minimum?: number;
    area_maximum?: number;
}

interface ParamsRequestFiltro {
    within?: {
        latitute?: number,
        longitude?: number,
        radius?: number
    };
    _field?: string;
}

interface ParamsRequestSearch {
    type?: string;
    city?: string;
    _limit?: number;
}

interface ResultRequestLogin {
    data: {
        token: string;
    };
}

interface ResponsePropriedades {
    data?: DataPropriedades[];
    city?: string;
    state?: string;
}

interface ResponseSearch {
    data?: Search[];
    message?: string;
}

interface ResponseCity {
    data?: City;
    message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class TerralogsService {

    QUANTIDADE_ACESSO = 'QUANTIDADE_ACESSO';
    TERRALOGS_SITE =  'TERRALOGS-TOKEN-SITE';
    DADOS_CHATBOT_SOLICITACAO =  'DADOS-CHATBOT-SOLICITACAO';
    urlV1: string = `${environment.api_terralogs}/${environment.api_version_terralogs}`;
    public atualizarMapa$: Subject<boolean> = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    atualizarMapa() {
        return this.atualizarMapa$.asObservable();
    }

    getInfoPorTipo(params: any, tipo): Promise<ResponsePropriedades> {
        const token = localStorage.getItem('token');
        return this.http.get<ResponsePropriedades>(`${this.urlV1}/${tipo}`, {
            params: {...params},
            headers: {'x-access-token': token}
        }).toPromise();
    }

    getAmazoniaLegal(): Promise<ResponsePropriedades> {
        const token = localStorage.getItem('token');
        return this.http.get<ResponsePropriedades>(`${this.urlV1}/legal-amazon`, {headers: {'x-access-token': token}}).toPromise();
    }

    getCity(idCity): Promise<ResponseCity> {
        const token = localStorage.getItem('token');
        return this.http.get<ResponseCity>(`${this.urlV1}/city/${idCity}`, {headers: {'x-access-token': token}}).toPromise();
    }

    getMatricula(idMatricula) {
        const token = localStorage.getItem('token');
        return this.http.get<any>(`${this.urlV1}/enrollment/${idMatricula}`, {headers: {'x-access-token': token}}).toPromise();
    }

    search(params: any): Promise<ResponseSearch> {
        const token = localStorage.getItem('token');
        return this.http.get<ResponseSearch>(`${this.urlV1}/search`, {params: {...params}, headers: {'x-access-token': token}}).toPromise();
    }

    async saveFormParceiro(usuario: any): Promise<any> {
        return await this.http.post(`${environment.url_terralogs_api}/requisicoes/parceiro`, usuario).toPromise();
    }

    sendEstimativa(idPropriedade, data) {
        const token = localStorage.getItem('token');
        return this.http.post<any>(`${this.urlV1}/enrollment/${idPropriedade}/user-estimate`, {
            data,
            headers: {'x-access-token': token}
        }).toPromise();
    }

    login(): Promise<ResultRequestLogin> {
        return this.http.post<ResultRequestLogin>(`${this.urlV1}/user/token`,
            {
                data:
                    {email: environment.user_terralogs, pass: environment.password_terralogs}
            }
        ).toPromise();
    }

    loginGestao(username: string, password: string): Observable<any> {
        return this
            .http
            .post<any>(`${environment.url_terralogs_api}/auth/login`, {username, password})
            .pipe(
                catchError(err => {
                    if (err.error) {
                        if (err.error.result.code === 401) {
                            return throwError('Endereço de e-mail ou senha inválidos.' || err.error.result.message);
                        } else if (err.error.result.code === 400) {
                            return throwError(err.error.result.message);
                        } else if (err.error.result.code === 404) {
                            return throwError(err.error.result.message);
                        } else {
                            return throwError('Não foi possível autenticar-se. Verifique sua conexão e tente novamente mais tarde.');
                        }
                    }

                })
            );
    }

    salvarUsuario(usuario: any): Observable<any> {
        return this.http.post<any>(`${environment.url_terralogs_api}/usuarios/site`, usuario);
    }

    atualizarSenhaUsuario(email: string, password: string, recoveryPasswordHash: string): Observable<any> {
        return this.http.post<any>(
            `${environment.url_terralogs_api}/usuarios/atualizar-senha`,
            { email, password, recoveryPasswordHash }
        );
    }

    recuperarSenhaUsuario(email: string): Observable<any> {
        return this.http.post<any>(`${environment.url_terralogs_api}/usuarios/enviar-recuperacao-senha`, { email });
    }

    salvarSolicitacao(solicitacao: any): Observable<any> {
        return this.http.post<any>(`${environment.url_terralogs_api}/solicitacoes/site`, solicitacao);
    }

    dadosUsuarioChatbotSolicitacao(formData) {
        return localStorage.setItem(this.DADOS_CHATBOT_SOLICITACAO, JSON.stringify(formData));
    }

    retonarDadosUsuarioChatbotSolicitacao() {
        return localStorage.getItem(this.DADOS_CHATBOT_SOLICITACAO);
    }

    alterarUsuarioLogado(auth: string) {
        return sessionStorage.setItem(this.TERRALOGS_SITE, auth.toString());
    }

    jwtDecode(t: string) {
        let token = {} as any;
        token.raw = t;
        token.header = JSON.parse(window.atob(t.split('.')[0]));
        token.payload = JSON.parse(window.atob(t.split('.')[1]));
        return (token)
      }

    retonarUsuarioLogado() {
        const token = sessionStorage.getItem(this.TERRALOGS_SITE);
        if (token) {
            const decoded = this.jwtDecode(token);
            const date    = new Date(decoded.payload.exp * 1000);
            const curDate = new Date();
            if (date >= curDate) {
                localStorage.removeItem(this.QUANTIDADE_ACESSO);
                return true;
            }
        }
        return false;
    }

    quantidadeAcesso() {
        return localStorage.getItem(this.QUANTIDADE_ACESSO);
    }

    alterarQuantidadeAcesso(quantidade = 0) {
        return localStorage.setItem(this.QUANTIDADE_ACESSO, quantidade.toString());
    }

}

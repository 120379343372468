import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigationExtras, Router} from '@angular/router';
import {LoadingController, ModalController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {ModalMultipleChoicePage} from '../modal-multiple-choice/modal-multiple-choice.page';
import {map, startWith} from 'rxjs/operators';
import {Municipio} from '../../../models/Municipio';
import {MapaPropriedadesPage} from '../mapa-propriedades/mapa-propriedades.page';
import {DominiosService} from '../../../services/dominios.service';
import {TerralogsService} from '../../../services/terralogs.service';
import {Uf} from '../../../models/Uf';
import {ModalAjudaPage} from '../modal-ajuda/modal-ajuda.page';
import {ModalBuscarPropriedadePage} from './modal-buscar-propriedade/modal-buscar-propriedade.page';
import {StringUtils} from 'app/utils/string-utils';

@Component({
    selector: 'app-buscar-propriedade',
    templateUrl: './buscar-propriedade.page.html',
    styleUrls: ['./buscar-propriedade.page.scss'],
})
export class BuscarPropriedadePage implements OnInit {
    formGroupForm = new FormGroup({
        cidade: new FormControl('', {
            validators: [Validators.required],
        }),
        tamanhoPropriedade: new FormControl('', {
            validators: [Validators.required],
        }),
        nome: new FormControl(''),
        matricula: new FormControl(''),
        numeroCAR: new FormControl('')
    });

    valueTamanho: string = '';

    notExistResponse: boolean = false;

    municipios: Municipio[] = [];
    uf: Uf;
    options: string[] = [];
    filteredOptions: Observable<string[]>;

    municipioSelected: string = '';
    @Input() listModals: string[] = [];

    constructor(
        private router: Router,
        private modalController: ModalController,
        private terralogsService: TerralogsService,
        private dominiosService: DominiosService,
        private stringUtils: StringUtils,
        private loadingController: LoadingController
    ) {
    }

    async ngOnInit() {
        await (await this.loadingController.create()).present();

        const {data} = await this.terralogsService.login();
        localStorage.setItem('token', data.token);

        this.municipios = await this.dominiosService.getMunicipio('');
        const ufs = await this.dominiosService.getUf();

        this.options = this.municipios.map((municipio) => {
            const sigla = ufs.find((uf) => municipio.codigo_uf === uf.codigo_uf).uf;
            this.municipioSelected = municipio.nome;
            return `${municipio.nome} - ${sigla}`;
        });

        this.filteredOptions = this.formGroupForm.get('cidade').valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
        );
        await this.loadingController.dismiss();
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options
            .filter((option) => option.toLowerCase().includes(filterValue)
                || this.stringUtils.replaceAcentos(option.toLowerCase()).includes(filterValue))
            .slice(0, 5);
    }

    goMapa() {
        this.router.navigate(['/mapa-propriedades']);
    }

    async dismiss() {
        await this.modalController.dismiss();
        // await this.router.navigate(['home'])
    }

    async getPropriedade() {
        await (await this.loadingController.create()).present();
        const cidade: string = this.formGroupForm.get('cidade').value;
        const splitCidade = cidade.split(' - ');
        let filteredOptions = [];
        if (cidade && cidade !== '') {
            const paramsCity = {
                type: 'city',
                query: splitCidade && splitCidade.length > 0 ? splitCidade[0] : '',
                _limit: 25
            };
            const responseCity = await this.terralogsService.search(paramsCity);
            filteredOptions = responseCity.data;
        }


        const [minimum, maximum] = this.valueTamanho.split('-');
        const nome = this.formGroupForm.get('nome').value;
        const matricula = this.formGroupForm.get('matricula').value;
        const numeroCAR = this.formGroupForm.get('numeroCAR').value;

        let query = null;
        if (nome && nome !== '') {
            query = nome.trim();
        } else if (matricula && matricula !== '') {
            query = matricula.trim();
        } else if (numeroCAR && numeroCAR !== '') {
            query = numeroCAR.trim();
        }

        const limit = 200;
        const offset = 0;
        const params = {
            _limit: limit,
            _offset: offset,
            query: '',
            // state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1).toLowerCase().trim() : '',
            city: splitCidade && splitCidade.length > 0 ? splitCidade[0].trim() : '',
            origin: 'sigef',
            _field: 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices',
            area_minimum: 30,
            area_maximum: maximum
        };


        if (query) {
            params.query = query;
        } else {
            delete params.query;
        }

        let response: any = {
            data: []
        };

        response.data = await this.buscarPaginadoPropriedade(params, limit, offset, []);

        const city = await this.terralogsService.getCity(filteredOptions[0].id);

        response = {
            ...response,
            state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1) : '',
            city: splitCidade && splitCidade.length > 0 ? splitCidade[0] : ''
        };

        await this.loadingController.dismiss();
        if (!response) {
            this.openModalBuscarPropriedade();
            this.notExistResponse = true;
        } else {
            const data = await this.openModalMapaPropriedades(response.data, response.city, city.data.state.toUpperCase(), city.data);
            if (data) {
                setTimeout(async () => {
                    await this.modalController.dismiss(data, '', 'buscar-propriedade');
                });
            }
        }

    }

    async openMapaPropriedades(response, city, state, cityInfo) {
        this.listModals.push('mapa-propriedade');
        let navigationExtras: NavigationExtras =
            {
                state:
                    {
                        data: response,
                        isFinanciar: true,
                        uf: this.uf,
                        listModals: this.listModals,
                        city,
                        state,
                        cityInfo,
                        zoomInicial: 9
                    }
            };
        this.router.navigate(['/mapa-propriedades'], navigationExtras);
        this.modalController.dismiss();
        this.terralogsService.atualizarMapa$.next(true);
    }

    async buscarPaginadoPropriedade(params, limit, offset, arrayValores) {

        let response: any = await this.terralogsService.getInfoPorTipo(params, 'enrollment');
        if (response) {
            arrayValores = arrayValores.concat(response.data);
            if (response.data.length === limit) {
                offset = offset + limit;
                params._offset = offset;
                return await this.buscarPaginadoPropriedade(params, limit, offset, arrayValores);
            }
            return await arrayValores;
        } else {
            return [];
        }

    }

    async openModalMapaPropriedades(response, city, state, cityInfo) {
        this.listModals.push('mapa-propriedade-modal');
        const modal = await this.modalController.create({
            component: MapaPropriedadesPage,
            cssClass: 'modal-full',
            backdropDismiss: true,
            componentProps: {
                data: response,
                isFinanciar: true,
                uf: this.uf,
                listModals: this.listModals,
                city,
                state,
                cityInfo,
                zoomInicial: 12,
                modalEscolherPropriedade: true
            },
            id: 'mapa-propriedade',
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            return {...data, type: 'new'};
        }

        return null;
    }

    async modalChoice() {
        const modal = await this.modalController.create({
            component: ModalMultipleChoicePage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            componentProps: {
                title: 'Tamanho da propriedade',
                options: [
                    {
                        name: 'Até 500 hectares',
                        value: '0-500',
                    },
                    {
                        name: 'De 500 a 1.500 hectares',
                        value: '500-1500',
                    },
                    {
                        name: 'De 1.500 a 3.000 hectares',
                        value: '1500-3000',
                    },
                    {
                        name: 'De 3.000 a 5.000 hectares',
                        value: '3000-5000',
                    },
                    {
                        name: 'De 5.000 a 10.000 hectares',
                        value: '5000-10000',
                    },
                    {
                        name: 'Acima de 10.000 hectares',
                        value: '10000-9999999999',
                    },
                ],
                multiple: false,
            },
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            this.formGroupForm.get('tamanhoPropriedade').setValue(data[0].name);
            this.valueTamanho = data[0].value;
        }
    }

    async openModalBuscarPropriedade() {
        let idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-buscar-propriedade' + idModal);
        const modal = await this.modalController.create({
            component: ModalBuscarPropriedadePage,
            cssClass: 'modal-info',
            componentProps: {
                listModals: this.listModals,
                uf: this.uf,
                cidade: this.formGroupForm.get('cidade').value
            },
            backdropDismiss: true,
            id: 'modal-buscar-propriedade' + idModal,
        });
        await modal.present();
    }

    async openModalMapaPropriedade() {
        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-mapa-propriedade' + idModal);
        const modal = await this.modalController.create({
            component: ModalBuscarPropriedadePage,
            cssClass: 'modal-info',
            componentProps: {
                listModals: this.listModals,
                uf: this.uf,
                cidade: this.formGroupForm.get('cidade').value
            },
            backdropDismiss: true,
            id: 'modal-buscar-propriedade' + idModal,
        });
        await modal.present();
    }

    async openModalAjuda() {
        let idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-ajuda' + idModal);
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda' + idModal,
        });
        await modal.present();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { DominiosService } from '@services/dominios.service';
import { Uf } from 'app/models/Uf';
import { DesenharPropriedadePage } from '../../desenhar-propriedade/desenhar-propriedade.page';

@Component({
  selector: 'app-modal-encontrar-propriedade',
  templateUrl: './modal-encontrar-propriedade.page.html',
  styleUrls: ['./modal-encontrar-propriedade.page.scss'],
})
export class ModalEncontrarPropriedadePage implements OnInit {

  @Input() isFinanciar: Boolean;
  @Input() cidade: string;
  @Input() uf: Uf;
  @Input() listModals: string[] = [];
  
  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private dominiosService: DominiosService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  async modalDesenharPropriedade() {
    if (this.cidade) {
      const splitCidade = this.cidade.split(' - ')

      const ufs = await this.dominiosService.getUf()
      this.uf = ufs.find((u) => u.uf === splitCidade[1].toUpperCase())
    }

    let idModal = Math.floor(Math.random() * 9999) + 1000;
    this.listModals.push('desenhar-propriedade' + idModal)
    const modal = await this.modalController.create({
      component: DesenharPropriedadePage,
      cssClass: 'modal-full',
      backdropDismiss: true,
      componentProps: {
        uf: this.uf,
        isFinanciar: this.isFinanciar,
        listModals: this.listModals,
        cidade: this.cidade,
        id: 'desenhar-propriedade' + idModal
      },
      id: 'desenhar-propriedade' + idModal,
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();

    if (data) {
        
    }
  }

  async onFalarEspecialista() {
    await (await this.loadingController.create()).present()
    await this.listModals.map(async m => {
        await this.modalController.dismiss(null, '', m).catch(error => console.log(error));
    })
    await this.loadingController.dismiss()
    this.router.navigate(['info-especialista']);
  }

}

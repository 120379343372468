import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'detalhe-regiao-pin-page',
  templateUrl: 'detalhe-regiao-pin-page.component.html',
  styleUrls: ['detalhe-regiao-pin-page.component.scss'],
})
export class DetalheRegiaoPinPageComponent implements OnInit {

  @Input() properties;
  @Input() icon;
  @Input() tipo;
  img;
  titulo;

  constructor(
    private popoverController: PopoverController
  ) {}

  ngOnInit() {    
      switch(this.tipo) {
        case 'terrasIndigenas':
        {
          this.titulo = 'Terras Indígenas';
          break;
        }
        case 'unidadesConservacao': 
        {
          this.titulo = 'Unidades de Conservação';
          break;
        }
        case 'industriasSuco':
        {
          this.titulo = 'Indústria de Suco';
          break;
        }
        case 'concessionariasTrator':
        {
          this.titulo = 'Concessionárias de Trator';
          break;
        }
        case 'laticinios':
        {
          this.titulo = 'Laticínios';
          break;
        }
        case 'water-resource':
        {
          this.titulo = 'Recursos Hídricos';
          break;
        }
        default:
        {
          this.titulo = this.tipo;
          break;
        }
      }
      this.img = 'assets/images/marcacoes/' + this.tipo + '.svg';
  }

  close() {
    this.popoverController.dismiss();
  }
}

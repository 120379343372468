import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  constructor(private http: HttpClient) {}

  async questionByIntent(intent: string): Promise<any> {
    return await this.http.get(`${environment.url_chatbot}/chatbot/v1/questions/${intent}`).toPromise();
  }

}



export class Parceiro {
    name: string;
    phone: string;
    mail: string;
    cityOperation: string;
    ufOperation: string;
    activityFirst: string;
    profile: string;

    constructor(formData: any) {
        this.name = formData.nome;
        this.phone = formData.telefone;
        this.mail = formData.email;
        this.cityOperation = formData.cidade.split(' - ')[0];
        this.ufOperation = formData.cidade.split(' - ')[1];
        this.activityFirst = formData.atividadePrincipal;
        this.profile = formData.perfil;
    }
}
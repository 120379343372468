
export const WELCOME: string = 'WELCOME';

export const GO: string = 'GO';

export const PF: string = 'PF';

export const VALOR_FINANCIAMENTO: string = 'VALOR_FINANCIAMENTO';

export const FINALIDADE_FINANCIAMENTO: string = 'FINALIDADE_FINANCIAMENTO';

export const ATIVIDADE_PRINCIPAL: string = 'ATIVIDADE_PRINCIPAL';

export const TEMPO_ATIVIDADE: string = 'TEMPO_ATIVIDADE';

export const QTD_HECTARES: string = 'QTD_HECTARES';

export const SAFRINHA: string = 'SAFRINHA';

export const ULTIMO_FATURAMENTO: string = 'ULTIMO_FATURAMENTO';

export const FINACIAR_PRODUCAO: string = 'FINACIAR_PRODUCAO';

export const FAZENDA_GARANTIA: string = 'FAZENDA_GARANTIA';

export const NO_FARM_EQUITY: string = 'NO_FARM_EQUITY';

export const CONCORDAR_ESTIMATIVA: string = 'CONCORDAR_ESTIMATIVA';

export const NAO_INICIAR: string = 'NAO_INICIAR';

export const ATIVIDADE_PRINCIPAL_2: string = 'ATIVIDADE_PRINCIPAL_2';

export const SOLO_PREDOMINANTE: string = 'SOLO_PREDOMINANTE';

export const HIPOTECA_ALIENACAO: string = 'HIPOTECA_ALIENACAO';

export const E_PROPRIETARIO: string = 'E_PROPRIETARIO';

export const OUTRAS_GARANTIAS: string = 'OUTRAS_GARANTIAS';

export const AREA_TOTAL_MATRICULA: string = 'AREA_TOTAL_MATRICULA';

export const ESTIMATIVA_VALOR_MATRICULA: string = 'ESTIMATIVA_VALOR_MATRICULA';

export const PJ: string = 'PJ';

export const RAZAO_SOCIAL: string = 'RAZAO_SOCIAL';

export const ESTIMATIVA_MATRICULA: string = 'ESTIMATIVA_MATRICULA';

export const VALOR_FINANCIAMENTO_PJ: string = 'VALOR_FINANCIAMENTO_PJ';

export const FINALIDADE_FINANCIAMENTO_PJ: string = 'FINALIDADE_FINANCIAMENTO_PJ';

export const MUNICIPIO_PJ: string = 'MUNICIPIO_PJ';

export const ATIVIDADE_PRINCIPAL_PJ: string = 'ATIVIDADE_PRINCIPAL_PJ';

export const ANO_FUNDACAO_PJ: string = 'ANO_FUNDACAO_PJ';

export const NUMERO_FUNCIONARIOS_PJ: string = 'NUMERO_FUNCIONARIOS_PJ';

export const FATURAMENTO_PJ: string = 'FATURAMENTO_PJ';

export const COMO_SE_FINANCIA_PJ: string = 'COMO_SE_FINANCIA_PJ';

export const MATRICULA_PJ: string = 'MATRICULA_PJ';

export const INFO_PORCENTAGEM: string = 'INFO_PORCENTAGEM';

export const FARM_EQUITY: string = 'FARM_EQUITY';

export const VALOR_FINANCIAMENTO_POTENCIAL_CREDITO: string = 'VALOR_FINANCIAMENTO_POTENCIAL_CREDITO';

export const CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO: string = 'CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO';

export const VALOR_FINANCIAMENTO_POTENCIAL_CREDITO_PJ: string = 'VALOR_FINANCIAMENTO_POTENCIAL_CREDITO_PJ';

export const ACCEPTED_TERMS: string = 'ACCEPTED_TERMS';

export const FINISH: string = 'FINISH';

export const SAFRAS_POR_ANO: string = 'SAFRAS_POR_ANO';

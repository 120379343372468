import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { LoadingController, ModalController } from '@ionic/angular';
import { DataPropriedades } from '../../../models/Propriedades';
import { Router } from '@angular/router';
import domtoimage from 'dom-to-image-google-font-issue';
import { ModalAjudaPage } from '../modal-ajuda/modal-ajuda.page';
import * as Constants from '@services/contants.service'

@Component({
  selector: 'app-propriedade-selecionada',
  templateUrl: './propriedade-selecionada.page.html',
  styleUrls: ['./propriedade-selecionada.page.scss'],
})
export class PropriedadeSelecionadaPage implements OnInit {

  @Input() dataPropriedade: DataPropriedades;
  @Input() isFinanciar: Boolean;
  @Input() listModals: string[] = [];

  map:any;
  isBusy: Boolean = false;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.loadMap();
  }

  async printMap() {
    let node = document.getElementById('map2');

    // Hack para diminuir o tamanho da imagem
    const scale = 0.8;
    const options = {
      height: node.offsetHeight * scale,
      width: node.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px"
      }
    };

    const base64 = await domtoimage.toPng(node, options);
    return base64;
  }

  loadMap() {
    const [lng, lat] = this.dataPropriedade.geometry_centroid;
    this.map = new L.Map("map2").setView([lat, lng], 13);
    L.tileLayer(Constants.TILE_LAYER, {
      attribution: '',
      maxZoom: 18,
      id: Constants.TILE_ID,
      tileSize: 512,
      zoomOffset: -1,
      accessToken: Constants.TILE_ACCESS_TOKEN
    }).addTo(this.map);
    const polygon = this.dataPropriedade.geometry_smoothed.coordinates[0].map(item => {
      const [lng, lat] = item;
      return [lat, lng];
    })
    
    let params: any = {
      type: "FeatureCollection",
    }
    params.features = [];

    params.features.push({
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            ...this.dataPropriedade.geometry_smoothed.coordinates[0]
          ]
        ]
      }
    });


    L.polygon(polygon, {
      fillColor: 'rgba(0, 155, 69, 0.22)',
      color: 'rgba(255, 255, 255, 0.5)',
      fillOpacity: 0.8,
      weight: 3, 
      
    }).addTo(this.map);

    let areaMap: any = L.geoJSON(params);
    areaMap.addData([[90, -180], [90, 180], [-90, 180], [-90, -180], [90, -180]]);
    areaMap.eachLayer(l => {
      const worldPolygon: any = L.polygon([[[90, -180], [90, 180], [-90, 180], [-90, -180], [90, -180]], l.getLatLngs()], {
        fillOpacity: 0.2,
        weight: 2,
        color: '#000000',
        fillColor: '#000000',
        stroke: false,
      });

      // this.worldPolygon = worldPolygon;
      this.map.addLayer(worldPolygon);
      this.map.fitBounds(areaMap.getBounds());
    })

  } 

  async confirm() {
    this.isBusy = true;
    const loading = await this.loadingController.create();
    await loading.present();
    await setTimeout(async () => {
      var zoomLeaflet: any = document.getElementsByClassName('leaflet-control-zoom');
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'none';
      }

      const bannerMap = await this.printMap();
      localStorage.setItem('bannerMap', bannerMap);
      
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'initial';
      }
      this.isBusy = false;
      
      
      await loading.dismiss();
      await this.modalController.dismiss({type: 'old', value: this.dataPropriedade}, '', 'buscar-propriedade');
      this.listModals.map(async m => {
        if (m != 'buscar-propriedade') {
          await this.modalController.dismiss(null, '', m).catch(this.printError);
        }
      })
    }, 2000);
  }

  async onQueroFinanciar() {
    this.isBusy = true;
    const loading = await this.loadingController.create();
    await loading.present();
    setTimeout(async () => {
      var zoomLeaflet: any = document.getElementsByClassName('leaflet-control-zoom');
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'none';
      }

      const bannerMap = await this.printMap();
      localStorage.setItem('bannerMap', bannerMap);
      
      for(let x = 0; x < zoomLeaflet.length; x++){
        zoomLeaflet[x].style.display = 'initial';
      }
      this.isBusy = false;
      
      localStorage.setItem('is_financiar', 'true');
      localStorage.setItem('data_propriedade', JSON.stringify(this.dataPropriedade));
      
      await loading.dismiss();
      this.listModals.map(async m => {
          await this.modalController.dismiss(null, '', m).catch(this.printError);
      })
      this.router.navigate(['info-pessoal']);
    }, 2000);
    
  }

  printError(error) {
    console.log(error);
  }

  formatMaiusculo(data: string) {
    return data.toUpperCase();
  }

  async openModalAjuda() {
    const modal = await this.modalController.create({
        component: ModalAjudaPage,
        cssClass: 'modal-footer',
        backdropDismiss: true,
        id: 'modal-ajuda',
    })
    await modal.present()
  }

  goBack() {
    this.modalController.dismiss();
  }

  async onClose() {
    this.listModals.map(async m => {
      if (m == 'buscar-propriedade') {
        await this.modalController.dismiss({type: 'close', value: null}, '', 'buscar-propriedade').catch(this.printError);
      } else {
        await this.modalController.dismiss(null, '', m).catch(this.printError);
      }
    })
    this.router.navigate(['home'])
  }
}

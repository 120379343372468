import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TerralogsService} from '@services/terralogs.service';
import {ModalCadastroPage} from '../modal-cadastro/modal-cadastro.page';
import {validarEmail} from '../../../utils/utils';
import { ModalEsqueciSenhaPage } from '../modal-esqueci-senha/modal-esqueci-senha.page';

@Component({
    selector: 'app-modal-login',
    templateUrl: './modal-login.page.html',
    styleUrls: ['./modal-login.page.scss'],
})
export class ModalLoginPage implements OnInit {

    form: FormGroup;
    emailValido = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private platform: Platform,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController,
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            senha: ['', Validators.required]
        });
    }

    ngOnInit() {
    }


    async entrar() {

        if (this.form.invalid) {
            return this.toast('Preencha corretamente os campos!');
        }

        if (!this.emailValido) {
            return this.toast('E-mail inválido!', 'danger');
        }

        await (await this.loadingController.create()).present();

        this.terralogsService.loginGestao(
            this.form.get('email').value,
            this.form.get('senha').value,
        ).subscribe((result: any) => {
            const token: any = result.data.token;
            this.terralogsService.alterarUsuarioLogado(token);
            return this.modalController.dismiss(true);
        }, error => {
            this.toast(error || 'Erro no serviço', 'danger');
        });

        await this.loadingController.dismiss();

    }

    async recover() {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: ModalEsqueciSenhaPage,
            cssClass: ['modal-small', 'modal-recover'],
            backdropDismiss: true,
            id: 'modal-recover'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();
    }

    async cadastrar() {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: ModalCadastroPage,
            cssClass: ['modal-small', 'modal-cadastrar'],
            backdropDismiss: true,
            id: 'modal-cadastrar'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            console.log('data', data);
        }
    }

    validarEmail(evento: any) {
        const email = this.form.get('email').value;

        const emailValido = validarEmail(email);

        if (!emailValido) {
            return this.toast('E-mail inválido!', 'danger');
        }
        this.emailValido = true;
    }

    close() {
        this.modalController.dismiss();
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }
}

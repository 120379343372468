import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalSelecionarCamadasPageRoutingModule } from './modal-selecionar-camadas-routing.module';
import { ModalSelecionarCamadasPage } from './modal-selecionar-camadas.page';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxMaskModule.forRoot(),
    ModalSelecionarCamadasPageRoutingModule
  ],
  declarations: [ModalSelecionarCamadasPage]
})
export class ModalSelecionarCamadasPageModule {}

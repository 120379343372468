import { Action, createReducer, on } from '@ngrx/store';
import * as MapaAction from '../actions/mapa.action';

export const initialState = {
    polygon: null,
    data: null
};

const reducer = createReducer(
    initialState, 
    on(MapaAction.addPolygon, (state: any, payload) => {
        return { ...state, polygon: payload };
    }),
    on(MapaAction.addMapa, (state: any, payload) => {
        return { ...state, data: payload }
    }),
    on(MapaAction.clear, (state) => initialState)
);

export function MapaReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
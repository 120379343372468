import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FinishChatbotPage } from './finish-chatbot.page';

const routes: Routes = [
  {
    path: '',
    component: FinishChatbotPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinishChatbotPageRoutingModule {}

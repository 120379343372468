import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';
import {BuscarPropriedadePage} from '../buscar-propriedade/buscar-propriedade.page';
import * as L from 'leaflet';
import {DataPropriedades} from '../../../models/Propriedades';
import {ChatbotService} from '../../../services/chatbot.service';
import {ModalMultipleChoicePage} from '../modal-multiple-choice/modal-multiple-choice.page';
import {FinishChatbotPage} from '../finish-chatbot/finish-chatbot.page';
import {ModalSumPercetagePage} from '../modal-sum-percetage/modal-sum-percetage.page';
import {Municipio} from '../../../models/Municipio';
import {DominiosService} from '../../../services/dominios.service';
import {Uf} from '../../../models/Uf';
import {ModalAjudaPage} from '../modal-ajuda/modal-ajuda.page';
import {ModalMobileOptionsPage} from '../modal-mobile-options/modal-mobile-options.page';
import {ModalBuscarMunicipioPage} from '../modal-buscar-municipio/modal-buscar-municipio.page';
import {ValidateIntent} from 'app/utils/validate-intent';
import {Relatorio} from 'app/models/Relatorio';
import {format} from 'date-fns';
import {Store} from '@ngrx/store';
import {Usuario} from 'app/models/Usuario';
import {TerralogsService} from '@services/terralogs.service';

@Component({
    selector: 'app-chatbot',
    templateUrl: './chatbot.page.html',
    styleUrls: ['./chatbot.page.scss'],
})
export class ChatbotPage implements OnInit, OnDestroy {
    isComecar: boolean = true;
    footerButton: boolean = true;
    footerText: boolean = false;
    footerSelect: boolean = false;
    isPf: boolean = false;
    isPj: boolean = false;
    cpf: string = null;
    isCpf: boolean = false;
    financiamento: string = null;
    isFinanciamento: boolean = false;
    isTipoFinanciamento: boolean = false;
    tipoFinanciamento: string = null;
    text: string = '';
    messages: any[] = [];
    currentMessage: any = {};
    map: any;
    loading: boolean = false;

    relatorio: Relatorio = null;
    dataPropriedade: DataPropriedades = null;
    newPolygon: any = null;
    isFinanciar: Boolean;

    dataUser: Usuario;

    municipios: Municipio[] = [];
    municipioSelected: string = '';
    uf: Uf;
    options: string[] = [];
    filteredOptions: string[] = [];
    showMunicipiosList = true;
    listModals: string[] = [];
    isBackAsk: boolean = false;
    chatInfo;

    municipioFazenda;
    ufFazenda;
    municipioEmpresa;
    ufEmpresa;
    precoSelecionado;

    @ViewChild('content') private content: any;

    constructor(
        private router: Router,
        private modalController: ModalController,
        private chatbotService: ChatbotService,
        private dominiosService: DominiosService,
        private activatedRoute: ActivatedRoute,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private validateIntent: ValidateIntent,
        private loadingController: LoadingController,
        private store: Store<any>
    ) {
    }

    async ngOnInit() {
        this.text = '';
        this.messages = [];
        this.currentMessage = {};

        await this.findDataUser();
        const dataChatbot = await this.terralogsService.retonarDadosUsuarioChatbotSolicitacao();
        this.chatInfo = JSON.parse(dataChatbot);

        await this.nextIntent('WELCOME');

        try {
            const isFinanciarStorage = localStorage.getItem('is_financiar');
            if (isFinanciarStorage) {
                this.isFinanciar = JSON.parse(isFinanciarStorage);
            }
        } catch (err) {
            this.isFinanciar = null;
        } finally {
            localStorage.removeItem('is_financiar');
        }

        try {
            const dataPropriedadeStorage = localStorage.getItem('data_propriedade');
            if (dataPropriedadeStorage) {
                this.dataPropriedade = JSON.parse(dataPropriedadeStorage);
                this.precoSelecionado = this.dataPropriedade.prices[0].total_price;
            }
        } catch (err) {
            this.dataPropriedade = null;
        } finally {
            localStorage.removeItem('data_propriedade');
        }

    }

    async findDataUser() {
        const idUser = this.activatedRoute.snapshot.paramMap.get('id');

        if (idUser) {
            const response: any = JSON.parse(localStorage.getItem(idUser));

            if (response) {
                this.dataUser = response;

                this.relatorio = new Relatorio();
                this.relatorio.id = idUser;
                this.relatorio.name = this.dataUser.name;
                this.relatorio.mail = this.dataUser.mail;
                this.relatorio.cityOperation = this.dataUser.cityOperation;
                this.relatorio.ufOperation = this.dataUser.ufOperation;
                this.relatorio.phone = this.dataUser.phone;
                this.relatorio.activityFirst = this.dataUser.activityFirst;
                if (this.isFinanciar) {
                    this.relatorio.isHasRegistration = true;
                    this.relatorio.data = this.dataPropriedade;
                }
            } else {
                this.goBack();
            }
        } else {
            this.goBack();
        }
    }

    loadMap() {
        let polygon = null;
        if (this.dataPropriedade) {
            const [lng, lat] = this.dataPropriedade.geometry_centroid;
            this.map = new L.Map('map3', {zoomControl: false}).setView([lat, lng], 13);
            polygon = this.dataPropriedade.geometry_smoothed.coordinates[0].map((item) => {
                const [lng, lat] = item;
                return [lat, lng];
            });
            L.tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3VzdGF2b3ZpZWlyYTk5MiIsImEiOiJja2piZmN3MGcwazFwMnVxcWh0YWVubjdoIn0.rny57jknqMG45YR2nlrojA'
            ).addTo(this.map);

            L.polygon(polygon, {
                fillColor: 'rgba(0, 155, 69, 0.22)',
                color: 'rgba(255, 255, 255, 0.5)',
                fillOpacity: 0.8,
                weight: 3,
            }).addTo(this.map);
        }

        if (this.newPolygon) {
            this.map = new L.Map('map3', {zoomControl: false}).setView([-15.8433962, -47.871458], 13);
            L.tileLayer(
                'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3VzdGF2b3ZpZWlyYTk5MiIsImEiOiJja2piZmN3MGcwazFwMnVxcWh0YWVubjdoIn0.rny57jknqMG45YR2nlrojA'
            ).addTo(this.map);
            let params: any = {
                type: 'FeatureCollection',
            };
            params.features = [];
            params.features.push(this.newPolygon.toGeoJSON());
            let areaMap: any = L.geoJSON(params);
            polygon = this.newPolygon.getLatLngs();
            L.polygon(polygon, {
                fillColor: 'rgba(0, 155, 69, 0.22)',
                color: 'rgba(255, 255, 255, 0.5)',
                fillOpacity: 0.8,
                weight: 3,
            }).addTo(this.map);
            areaMap.addData([
                [90, -180],
                [90, 180],
                [-90, 180],
                [-90, -180],
                [90, -180],
            ]);
            areaMap.eachLayer((l) => {
                const worldPolygon: any = L.polygon(
                    [
                        [
                            [90, -180],
                            [90, 180],
                            [-90, 180],
                            [-90, -180],
                            [90, -180],
                        ],
                        l.getLatLngs(),
                    ],
                    {
                        fillOpacity: 0.2,
                        weight: 2,
                        color: '#000000',
                        fillColor: '#000000',
                        stroke: false,
                    }
                );
                this.map.addLayer(worldPolygon);
                this.map.fitBounds(areaMap.getBounds());
            });
        }
    }

    formatResult(data: string) {
        const textoUsado = this.chatInfo.cnpj !== '' ? this.chatInfo.razaoSocial : this.dataUser.name;
        if (this.dataPropriedade && this.dataPropriedade.prices) {
            return data
                .replace('%NOME_USUARIO%', this.formatarMaiusculo(textoUsado))
                .replace(
                    '%NOME_FAZENDA%',
                    this.dataPropriedade
                        ? this.formatarMaiusculo(this.dataPropriedade.farm_name)
                        : 'Nome Fazenda'
                )
                .replace(
                    '%VALOR_FAZENDA%',
                    this.dataPropriedade
                        ? String(
                            Math.round(this.dataPropriedade.prices[0].total_price).toLocaleString(
                                'pt-br'
                            )
                        )
                        : 'Valor da fazenda'
                )
                .replace(
                    '%POTENCIAL_CREDITO%',
                    this.dataPropriedade
                        ? String(
                            Math.round(
                                (this.dataPropriedade.prices[0].credit ? this.dataPropriedade.prices[0].credit : (this.dataPropriedade.prices[0].total_price * 0.6))
                            ).toLocaleString('pt-br')
                        )
                        : ' Potencial de crédito'
                );
        } else if (!this.dataPropriedade && textoUsado) {
            return data
                .replace('%NOME_USUARIO%', this.formatarMaiusculo(textoUsado));
        } else {
            return data;
        }
    }

    async nextIntent(intent: string, value: string = null, isMap: boolean = false) {
        this.loading = true;

        setTimeout(() => (this.loading = false), 1000);

        if (value != 'Voltando para pergunta anterior') {
            this.isBackAsk = false;
            this.validateIntent.validate(this.relatorio, this.currentMessage, value ? value : this.text);
        } else {
            this.isBackAsk = true;
        }


        if (intent === 'GO') {
            this.messages.push({
                description: [value],
                sender: 'user',
            });
            if (this.chatInfo && this.chatInfo.cnpj !== '') {
                return await this.nextIntent('VALOR_FINANCIAMENTO_PJ', null);
            } else if (this.chatInfo && this.chatInfo.cpf !== '') {
                return await this.nextIntent('VALOR_FINANCIAMENTO', null);
            }
            return;
        }

        if (intent === 'FARM_EQUITY') {
            await this.openModalBuscarPropriedade();
            return;
        }

        if (intent === 'FINISH') {
            await (await this.loadingController.create()).present();

            this.relatorio.farmImage = localStorage.getItem('bannerMap');
            let propriedadeData = this.dataPropriedade;
            if (propriedadeData?.id?.length > 0) {
                propriedadeData = (await this.terralogsService.getMatricula(propriedadeData.id)).data || this.dataPropriedade;
            }
            this.relatorio.data = propriedadeData;
            this.relatorio.polygon = this.newPolygon;

            if (this.chatInfo && this.chatInfo.cnpj !== '') {
                this.relatorio.companyName = this.chatInfo.razaoSocial;
                this.relatorio.cnpj = this.chatInfo.cnpj;
                this.relatorio.name = null;
                this.relatorio.cpf = null;
            } else if (this.chatInfo && this.chatInfo.cpf !== '') {
                this.relatorio.name = this.chatInfo.nome;
                this.relatorio.cpf = this.chatInfo.cpf;
                this.relatorio.companyName = null;
                this.relatorio.cnpj = null;
            }

            const user: Usuario = new Usuario(this.relatorio);
            localStorage.setItem(user.id, JSON.stringify(user));

            await this.enviarSolicitacaoSite();

            await this.loadingController.dismiss();
            const modal = await this.modalController.create({
                component: FinishChatbotPage,
                cssClass: 'modal-full',
                backdropDismiss: true,
                componentProps: {
                    relatorio: this.relatorio,
                    dataUser: this.dataUser
                },
                id: 'finish-chatbot',
            });
            await modal.present();
            await modal.onWillDismiss();
            this.ngOnDestroy();
            return;
        }

        if (intent === 'NAO_INICIAR') {
            await (await this.loadingController.create()).present();

            const user: Usuario = new Usuario(this.relatorio);
            user.isSpecialist = true;
            localStorage.setItem(user.id, JSON.stringify(user));
            await this.enviarSolicitacaoSite();
            await this.loadingController.dismiss();

            const modal = await this.modalController.create({
                component: FinishChatbotPage,
                cssClass: 'modal-full',
                backdropDismiss: true,
                componentProps: {
                    message: `<b>Obrigado, ${this.dataUser.name}!</b> <br/> Em breve, um dos especialistas em crédito do TerraLogs entrará em contato com você.`,
                },
            });
            await modal.present();
            await modal.onWillDismiss();
            this.ngOnDestroy();
            return;
        }

        if (isMap) {
            this.messages.push({
                sender: 'user-map',
            });
            setTimeout(() => {
                this.loadMap();
            }, 1000);
        }

        if (
            (this.currentMessage.type === 'button' ||
                this.currentMessage.type === 'select' ||
                this.currentMessage.type === 'select_percentage') &&
            !isMap && (intent !== 'VALOR_FINANCIAMENTO' && intent !== 'VALOR_FINANCIAMENTO_PJ')
        ) {
            this.messages.push({
                description: [value],
                sender: 'user',
            });
        }

        if (
            (this.currentMessage.type === 'text' ||
                this.currentMessage.type === 'text_municipio') &&
            !isMap
        ) {
            this.messages.push({
                description: [value ? value : this.text],
                sender: 'user',
            });
            this.text = '';
        }

        const response = await this.chatbotService.questionByIntent(intent);
        this.currentMessage = response.data;
        this.currentMessage.sender = 'bot';

        this.validarFluxoEncontrarPropriedade();

        setTimeout(() => this.content.scrollToBottom());
        setTimeout(() => {
            setTimeout(() => this.content.scrollToBottom());
            this.messages.push(this.currentMessage);
        }, 1000);

        setTimeout(() => this.content.scrollToBottom());

    }

    goBack() {
        this.ngOnDestroy();
        this.router.navigate(['/home']);
    }

    validarFluxoEncontrarPropriedade() {
        if (this.isFinanciar) {
            if (this.currentMessage.intent === 'PF') {
                this.currentMessage.next_intent = 'VALOR_FINANCIAMENTO_POTENCIAL_CREDITO';
            } else if (this.currentMessage.intent === 'RAZAO_SOCIAL') {
                this.currentMessage.next_intent = 'VALOR_FINANCIAMENTO_POTENCIAL_CREDITO_PJ';
            } else if (this.currentMessage.intent === 'FINACIAR_PRODUCAO') {
                this.currentMessage.next_intent = 'CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO';
            } else if (this.currentMessage.intent === 'COMO_SE_FINANCIA_PJ') {
                this.currentMessage.next_intent = 'CONCORDAR_ESTIMATIVA_POTENCIAL_CREDITO';
            }
        }
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }

    async onFilter() {
        const modal = await this.modalController.create({
            component: ModalBuscarMunicipioPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'buscar-municipio',
            componentProps: {
                title: this.currentMessage.description[this.currentMessage.description.length - 1],
            },
        });
        modal.present();
        const {data} = await modal.onWillDismiss();
        if (data.municipio) {
            this.municipioEmpresa = data.municipio;
        }
        if (data.uf) {
            this.ufEmpresa = data.uf;
        }
        this.text = data.nome;
    }

    async openModalMultipleChoice() {
        const modal = await this.modalController.create({
            component: ModalMultipleChoicePage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            componentProps: {
                options: this.currentMessage.options,
                multiple: this.currentMessage.multiple,
                title: this.currentMessage.description[this.currentMessage.description.length - 1],
            },
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            if (data && data.active && data.intent) {
                this.nextIntent(data.intent, data.value);
            } else if (data && data.length > 0) {
                const values = data.map((d) => d.name).join(', ');
                this.nextIntent(this.currentMessage.next_intent, values);
            } else {
                this.nextIntent(this.currentMessage.next_intent, data.value);
            }
        }
    }

    async enviarSolicitacaoSite() {
        await (await this.loadingController.create()).present();

        let pessoaFisica = false;
        if (this.chatInfo && this.chatInfo.cnpj !== '') {
            this.relatorio.companyName = this.chatInfo.razaoSocial;
            this.relatorio.cnpj = this.chatInfo.cnpj;
            this.relatorio.name = null;
            this.relatorio.cpf = null;
        } else if (this.chatInfo && this.chatInfo.cpf !== '') {
            pessoaFisica = true;
            this.relatorio.name = this.chatInfo.nome;
            this.relatorio.cpf = this.chatInfo.cpf;
            this.relatorio.companyName = null;
            this.relatorio.cnpj = null;
        }

        const usuarioSolicitacao: Usuario = new Usuario(this.relatorio);

        const solos = [];
        if (usuarioSolicitacao.predominantSoils && usuarioSolicitacao.predominantSoils.length > 0) {
            usuarioSolicitacao.predominantSoils.map(solo => {
                solos.push(this.retornarTipoSoloId(solo));
            });
        }

        const garantias = [];
        if (usuarioSolicitacao.otherGarantees && usuarioSolicitacao.otherGarantees.length > 0) {
            usuarioSolicitacao.otherGarantees.map(garantia => {
                garantias.push(this.retornarGarantiaPorId(garantia));
            });
        }

        const financiamentos = [];
        if (usuarioSolicitacao.financingProduction && usuarioSolicitacao.financingProduction.length > 0) {
            usuarioSolicitacao.financingProduction.map(financiamento => {
                financiamentos.push(this.retornarComoEmpresaSeFinancia(financiamento));
            });
        }

        const safra = this.retornarTipoSafraId(usuarioSolicitacao.vintagesPerYear) || null;

        const ramoAtividade = this.retornarAtividadePrincipalId(usuarioSolicitacao.agropecuaryActivity) || null;

        const numeroEmpregado = this.retornarNumerosFuncionarioEmpresaId(usuarioSolicitacao.numberEmployee) || null;

        const finalidade = this.retornarFinalidadeId(usuarioSolicitacao.goal) || null;

        const atividadePrincipal = this.retornarAtividadePrincipalMatriculaId(usuarioSolicitacao.activityFirst) || null;

        const atividadePrincipalSite = this.retornarAtividadeSite(this.chatInfo.atividadePrincipal) || null;

        const coordenadaCentral = this.relatorio?.data?.geometry_centroid;
        const coordenadasFazenda = [];
        if (usuarioSolicitacao.data && usuarioSolicitacao.data.geometry_smoothed.coordinates[0].length > 0) {
            for (const coo of usuarioSolicitacao.data.geometry_smoothed.coordinates[0]) {
                coordenadasFazenda.push({
                    latitude: coo[0],
                    longitude: coo[1],
                    pontoCentral: coo[0] === coordenadaCentral[0] && coo[1] === coordenadaCentral[1]
                });
            }
        }

        const ojbSolicitacao = this.modeloSolicitacao(
            pessoaFisica ? this.relatorio.cpf : this.relatorio.cnpj,
            pessoaFisica ? usuarioSolicitacao.name : usuarioSolicitacao.companyName,
            usuarioSolicitacao.mail,
            usuarioSolicitacao.phone,
            usuarioSolicitacao.financingAmount,
            usuarioSolicitacao.fundationYear,
            usuarioSolicitacao.lastAmount,
            usuarioSolicitacao.valueEstimated,
            usuarioSolicitacao.isMoetgage,
            usuarioSolicitacao.isOwner,
            pessoaFisica ? 1 : 2,
            this.chatInfo.ufSite,
            this.chatInfo.municipioSite,
            atividadePrincipal,
            atividadePrincipalSite,
            numeroEmpregado,
            finalidade,
            this.ufEmpresa,
            this.municipioEmpresa,
            ramoAtividade,
            safra,
            usuarioSolicitacao.agriculture,
            usuarioSolicitacao.pasture,
            usuarioSolicitacao.reforestation,
            usuarioSolicitacao.nativeVegetation,
            solos,
            garantias,
            financiamentos,
            usuarioSolicitacao?.data?.farm_name,
            usuarioSolicitacao?.data?.area,
            usuarioSolicitacao.isHasRegistration,
            this.ufFazenda,
            this.municipioFazenda,
            coordenadasFazenda,
            usuarioSolicitacao.isLowSeason,
            usuarioSolicitacao.isAcceptedTerms,
            atividadePrincipal,
            this.retornarTempoAtividadeId(usuarioSolicitacao.timeActivity),
            usuarioSolicitacao.soilsRR,
            usuarioSolicitacao.biomeRR,
            usuarioSolicitacao.climateRR,
            usuarioSolicitacao.pasture,
            usuarioSolicitacao.degradedPasture,
            usuarioSolicitacao.potencialCredit,
            this.precoSelecionado
        );

        this.terralogsService.salvarSolicitacao(ojbSolicitacao)
            .subscribe(() => {
            }, er => {
                return this.toast(er?.error?.result.message || 'Erro ao enviar a solicitação, tente novamente mais tarde', 'danger');
            });

        localStorage.removeItem(this.relatorio.id);
        localStorage.removeItem('DADOS-CHATBOT-SOLICITACAO');
        await this.loadingController.dismiss();
    }

    async openModalBuscarPropriedade() {
        this.listModals.push('buscar-propriedade');
        const modal = await this.modalController.create({
            component: BuscarPropriedadePage,
            cssClass: 'modal-full',
            backdropDismiss: true,
            componentProps: {
                options: this.currentMessage.options,
                listModals: this.listModals
            },
            id: 'buscar-propriedade',
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data.codigoMunicipo) {
            this.municipioFazenda = data.codigoMunicipo;
        }

        if (data.codigoUf) {
            this.ufFazenda = data.codigoUf;
        }

        if (data.propriedade) {
            this.dataPropriedade = data.propriedade;
            this.precoSelecionado = data.propriedade.prices[0].total_price;
        }

        if (data && data.type === 'old') {
            this.dataPropriedade = data.value;
            this.nextIntent('CONCORDAR_ESTIMATIVA', null, true);
        }

        if (data && data.type === 'new') {
            this.newPolygon = data.value;
            this.nextIntent('AREA_TOTAL_MATRICULA', null, true);
        }

        if (data && data.type === 'close') {
            this.goBack();
        }
    }

    async openModalSumPercentage() {
        const modal = await this.modalController.create({
            component: ModalSumPercetagePage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'sum-percentage',
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            this.nextIntent(
                this.currentMessage.next_intent,
                `Agricultura ${data.agricultura}%, Pastagem ${data.pastagem}%, Reflorestamento ${data.reflorestamento}%, Vegetação nativa ${data.vegetacaoNativa}%`,
                false
            );
        }
    }

    modeloSolicitacao(
        cpfCnpj?, nome?, email?, telefone?, valorFinanciado?, anoFundacao?,
        ultimoFaturamento?, estimativaValor?, hipoteca?, proprietario?,
        tipoPessoa?, estado?, municipio?, primeiraAtividade?, atividadePrincipalSite?,
        porteEmpresa?, finalidade?, estadoEmpresa?, municipioEmpresa?, ramoAtividade?,
        safra?, percentualAgricultura?, percentualPastagem?, percentualReflorestamento?,
        percentualVegetacao?, solos?, garantias?, financiamentos?, nomeFazenda?,
        areaFazenda?, matricula?, estadoFazenda?, municipioFazenda?, coordenadas?,
        safrinha?, aceitaTermo?, atividadePrincipal?, tempoExercicio?, relatoriosSolo?,
        relatoriosBioma?, relatoriosClima?, pasto?, pastoDegradado?, potencialCredito?,
        preco?
    ) {
        return {
            cpfCnpj: cpfCnpj || null,
            nome: nome || null,
            email: email || null,
            telefone: telefone || null,
            valorFinanciado: this.castingNumero(valorFinanciado) || null,
            anoFundacao: anoFundacao || null,
            ultimoFaturamento: this.castingNumero(ultimoFaturamento) || null,
            estimativaValor: this.castingNumero(estimativaValor) || null,
            hipoteca: hipoteca || false,
            proprietario: proprietario || false,
            tipoPessoa: tipoPessoa || null,
            estado: estado || null,
            municipio: municipio || null,
            primeiraAtividade: primeiraAtividade || null,
            atividadePrincipalSite: atividadePrincipalSite || null,
            porteEmpresa: porteEmpresa || null,
            finalidade: finalidade || null,
            estadoEmpresa: estadoEmpresa || null,
            municipioEmpresa: municipioEmpresa || null,
            ramoAtividade: ramoAtividade || null,
            safra: safra || null,
            percentualAgricultura: this.castingNumero(percentualAgricultura) || null,
            percentualPastagem: this.castingNumero(percentualPastagem) || null,
            percentualReflorestamento: this.castingNumero(percentualReflorestamento) || null,
            percentualVegetacao: this.castingNumero(percentualVegetacao) || null,
            solos: solos || [],
            garantias: garantias || [],
            financiamentos: financiamentos || [],
            nomeFazenda: nomeFazenda || null,
            areaFazenda: areaFazenda || null,
            matricula: matricula || false,
            estadoFazenda: estadoFazenda || null,
            municipioFazenda: municipioFazenda || null,
            coordenadas: coordenadas || [],
            safrinha: safrinha || false,
            aceitaTermo: aceitaTermo || false,
            atividadePrincipal: atividadePrincipal || null,
            tempoExercicio: tempoExercicio || null,
            relatoriosSolo: relatoriosSolo || null,
            relatoriosBioma: relatoriosBioma || null,
            relatoriosClima: relatoriosClima || null,
            pasto: pasto || null,
            pastoDegradado: pastoDegradado || null,
            potencialCredito: potencialCredito || null,
            preco: preco || null
        };
    }

    castingNumero(valor) {
        return valor ? +(valor.toString().replace(/[^\d]+/g, '')) : null;
    }

    async backAsk() {
        if (this.currentMessage.intent !== 'WELCOME') {
            const messagesBot = this.messages.filter((message) => message.sender === 'bot');
            this.nextIntent(
                messagesBot[messagesBot.length - 2].intent,
                'Voltando para pergunta anterior'
            );
        }
    }

    async openModalAjuda() {
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda',
        });
        await modal.present();
        await modal.onWillDismiss();
        this.modalController.dismiss(null, '', 'modal-mobile-options').catch((error) => null);
    }

    async openModalMobileOptions() {
        const modal = await this.modalController.create({
            component: ModalMobileOptionsPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-mobile-options',
            componentProps: {
                options: [
                    {
                        icon: 'assets/custom-icons/icone-voltar-pergunta-modal-opcoes.svg',
                        label: 'Voltar pergunta',
                        action: async () => this.backAsk(),
                        isDisabled: this.isBackAsk
                    }, {
                        icon: 'assets/custom-icons/icon-ajuda-modal-opcoes.svg',
                        label: 'Ajuda',
                        action: async () => this.openModalAjuda(),
                        isDisabled: false
                    }
                ]
            },
        });
        await modal.present();
    }

    private formatarMaiusculo(text) {
        if (text) {
            const loweredText = text.toLowerCase();
            const words = loweredText.split(' ');
            for (let a = 0; a < words.length; a++) {
                let w = words[a];

                const firstLetter = w[0];
                if (firstLetter) {
                    w = firstLetter.toUpperCase() + w.slice(1);
                }

                words[a] = w;
            }
            return words.join(' ');
        }
        return '';
    }

    formatDate() {
        return format(new Date(), 'dd/MM/yyyy - HH:mm');
    }

    retornarFinalidadeId(value) {
        switch (value) {
            case 'Custeio':
                return 1;
            case 'Investimento':
                return 2;
            case 'Comercialização':
                return 3;
        }
    }

    retornarAtividadePrincipalId(value) {
        switch (value) {
            case 'Algodoeira':
                return 1;
            case 'Armazém':
                return 2;
            case 'Cooperativa':
                return 3;
            case 'Frigorífico':
                return 4;
            case 'Revenda':
                return 5;
            case 'Trading':
                return 6;
            case 'Transportadora':
                return 7;
            case 'Usina':
                return 8;
            case 'Outro':
                return 9;
        }
    }

    retornarNumerosFuncionarioEmpresaId(value) {
        switch (value) {
            case 'Até 19 funcionários':
                return 1;
            case '20 a 99 funcionários':
                return 2;
            case '100 a 499 funcionários':
                return 3;
            case 'A partir de 500 funcionários':
                return 4;
        }
    }

    retornarAtividadeSite(value) {
        switch (value) {
            case 'Produtor rural':
                return 1;
            case 'Proprietário de agroindústria':
                return 2;
            case 'Gerente':
                return 3;
            case 'Consultor agropecuário':
                return 4;
            case 'Outro':
                return 5;
        }
    }

    retornarAtividadePrincipalMatriculaId(value) {
        switch (value) {
            case 'Cultivo de grãos':
                return 1;
            case 'Cultivo de algodão':
                return 2;
            case 'Cultivo de cana':
                return 3;
            case 'Cafeicultura':
                return 4;
            case 'Citricultura':
                return 5;
            case 'Fruticultura e/ou horticultura':
                return 6;
            case 'Grãos e pecuária':
                return 7;
            case 'Pecuária':
                return 8;
            case 'Reflorestamento':
                return 9;
            case 'Outra':
                return 10;
        }
    }

    retornarGarantiaPorId(value) {
        switch (value) {
            case 'Outra(s) matrícula(s) de propriedade rural':
                return 1;
            case 'Armazém':
                return 2;
            case 'Propriedade urbana':
                return 3;
            case 'Penhora de safra':
                return 4;
            case 'Estoque':
                return 5;
            case 'Recebíveis':
                return 6;
            case 'Não tenho outra garantia':
                return 7;
        }
    }

    retornarComoEmpresaSeFinancia(value) {
        switch (value) {
            case 'Capital dos acionistas':
                return 1;
            case 'Capital próprio':
                return 2;
            case 'Bancos':
                return 3;
            case 'Fundos':
                return 4;
            case 'Fornecedores':
                return 5;
            case 'Tradings':
                return 6;
            case 'Outro':
                return 7;
        }
    }

    retornarTempoAtividadeId(value) {
        switch (value) {
            case 'Vou começar':
                return 1;
            case 'Até 2 anos':
                return 2;
            case '2 a 5 anos':
                return 3;
            case '5 a 10 anos':
                return 4;
            case '10 a 15 anos':
                return 4;
            case 'Mais de 15 anos':
                return 5;
        }
    }

    retornarTipoSoloId(value) {
        switch (value) {
            case 'Afloramento de rochas':
                return 1;
            case 'Alissolo':
                return 2;
            case 'Argissolo':
                return 3;
            case 'Cambissolo':
                return 4;
            case 'Chernossolo':
                return 5;
            case 'Dunas':
                return 6;
            case 'Espodossolo':
                return 7;
            case 'Gleissolo':
                return 8;
            case 'Latossolo':
                return 9;
            case 'Luvissolo':
                return 10;
            case 'Massa de água':
                return 11;
            case 'Neossolo':
                return 12;
            case 'Organossolo':
                return 13;
            case 'Planossolo':
                return 14;
            case 'Plintossolo':
                return 15;
            case 'Vertissolo':
                return 16;
        }
    }

    retornarTipoSafraId(value) {
        switch (value) {
            case 'Nenhuma':
                return 1;
            case '1 safra':
                return 2;
            case '2 safras':
                return 3;
            case '3 safras':
                return 4;
        }
    }

    ngOnDestroy(): void {
        this.text = '';
        this.messages = [];
        this.currentMessage = {};
        this.nextIntent('WELCOME');

        this.isComecar = true;
        this.footerButton = true;
        this.footerText = false;
        this.footerSelect = false;
        this.isPf = false;
        this.isPj = false;
        this.cpf = null;
        this.isCpf = false;
        this.financiamento = null;
        this.isFinanciamento = false;
        this.isTipoFinanciamento = false;
        this.tipoFinanciamento = null;
        this.map = null;
        this.loading = false;

        this.relatorio = null;
        this.dataPropriedade = null;
        this.newPolygon = null;
        this.isFinanciar = false;
        this.municipioFazenda = null;
        this.ufFazenda = null;
        this.municipioEmpresa = null;
        this.ufEmpresa = null;

        this.dataUser = null;
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-detalhe-regiao-limitrofe',
  templateUrl: './detalhe-regiao-limitrofe.component.html',
  styleUrls: ['./detalhe-regiao-limitrofe.component.scss'],
})
export class DetalheRegiaoLimitrofeComponent implements OnInit {

  @Input() properties;
  
  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  visualizar() {
    this.popoverController.dismiss({
      info: this.properties
    });
  }

  close() {
    this.popoverController.dismiss();
  }

}

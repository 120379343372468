import { PipeTransform, Injectable, Pipe } from '@angular/core';
@Pipe({
  name: 'replace',
})
@Injectable()
export class ReplacePipe implements PipeTransform {
  constructor() {}
  transform(item: any, replace, replacement): any {
    if (item == null) {
      return '';
    }
    item = item.replace(replace, replacement);
    const last = item.substring(item.lastIndexOf('.') + 1);
    item = item.replace('.' + last, ',' + last);
    return item;
  }
}

import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {IonInput, LoadingController, ModalController, ToastController} from '@ionic/angular';
import {ChatbotService} from '@services/chatbot.service';
import {SmsService} from '@services/sms.service';
import {Parceiro} from 'app/models/Parceiro';
import {Relatorio} from 'app/models/Relatorio';
import {Usuario} from 'app/models/Usuario';
import {addMinutes} from 'date-fns';
import {FinishChatbotPage} from '../finish-chatbot/finish-chatbot.page';
import {forkJoin, from} from 'rxjs';
import {TerralogsService} from '@services/terralogs.service';

@Component({
    selector: 'app-modal-sms',
    templateUrl: './modal-sms.page.html',
    styleUrls: ['./modal-sms.page.scss'],
})
export class ModalSmsPage implements OnInit {

    @Input() formData: any;
    @Input() parceiro: boolean = false;
    @Input() isEspecialista: boolean = false;
    @Input() pin: string;
    focusInput: number = 1;

    @ViewChildren('input') inputComponents: QueryList<IonInput>;

    interval: any;
    timer: string;

    n1: string;
    n2: string;
    n3: string;
    n4: string;

    invalidCode: Boolean = false;
    emptyCode: Boolean = false;
    isTimeOut: Boolean = false;

    constructor(
        private router: Router,
        private modalController: ModalController,
        private chatbotService: ChatbotService,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private smsService: SmsService,
        private loadingController: LoadingController
    ) {
        this.clearAllInterval();
        this.startTimer();
    }

    ngOnInit() {}

    async goChatbot() {
        this.invalidCode = true;
        this.emptyCode = false;
        const codeUser = `${this.n1}${this.n2}${this.n3}${this.n4}`;

        // console.log('+this.pin', +this.pin);

        if (!this.isTimeOut) {
            if (codeUser !== '' && codeUser.length === 4) {

                if (+this.pin === +codeUser) {
                    this.invalidCode = false;
                    this.clearAllInterval();
                    if (!this.parceiro) {

                        const cidade = this.formData.cidade.split(' - ');
                        const relatorio: Relatorio = new Relatorio();
                        relatorio.name = this.formData.nome;
                        relatorio.mail = this.formData.email;
                        relatorio.cityOperation = cidade[0];
                        relatorio.ufOperation = cidade[1];
                        relatorio.phone = this.formData.telefone;
                        relatorio.activityFirst = this.formData.atividadePrincipal;
                        if (this.isEspecialista) {
                            relatorio.isSpecialist = true;
                        }

                        const id = `id${Date.now()}`;

                        await (await this.loadingController.create()).present();
                        const user: Usuario = new Usuario(relatorio);
                        localStorage.setItem(id, JSON.stringify(relatorio));
                        await this.loadingController.dismiss();

                        await this.modalController.dismiss();
                        this.router.navigate([`chatbot/${id}`]);
                    } else {
                        await (await this.loadingController.create()).present();

                        const partner: Parceiro = new Parceiro(this.formData);
                        const parceiroTerralogs = {
                            nome: this.formData.nome,
                            cpf: this.formData.cpf,
                            email: this.formData.email,
                            telefone: this.formData.telefone,
                            perfil: 4, // Atendente
                            estado: this.formData.idUf,
                            municipio: this.formData.idMunicipio,
                            atividadeParceiro: this.formData.idAtividadePrincipal,
                            perfilCliente: this.formData.idPerfil,
                            site: true
                        };

                        return forkJoin([
                            from(this.terralogsService.saveFormParceiro(parceiroTerralogs))
                        ]).subscribe( async ([chatParceiro]) => {
                            await this.loadingController.dismiss();
                            await this.modalController.dismiss();
                            await this.goModalFinishChatbot();
                        }, async (er) => {
                            await this.loadingController.dismiss();
                            return this.toast(er?.error?.result.message || 'Erro ao enviar a solicitação, tente novamente mais tarde', 'danger');
                        });
                    }
                }
            } else {
                this.emptyCode = true;
            }
        }
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }

    async goModalFinishChatbot() {
        const modal = await this.modalController.create({
            component: FinishChatbotPage,
            cssClass: 'modal-full',
            backdropDismiss: true,
            componentProps: {
                message: `<b>Muito obrigado, ${this.formatarMaiusculo(this.formData.nome)}</b>! Suas informações foram <b>enviadas com sucesso</b>. Em breve, entraremos em contato com você.`,
            },
        });
        await modal.present();
    }

    setFocus(id: number) {
        this.focusInput = id;
    }

    async onKeyUp(evt, id: number) {
        const inputs = this.inputComponents.toArray();
        if (id < 3) {
            await inputs[id + 1].setFocus();
        }
    }

    startTimer() {
        const countDownDate = addMinutes(new Date(), 2).getTime();
        this.isTimeOut = false;
        const interval = setInterval(function() {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let timer = '0' + minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
            if (distance < 0) {
                clearInterval(interval);
                timer = '00:00';
                this.isTimeOut = true;
            }
            document.getElementById('timer').innerHTML = timer;
        }, 1000);
    }

    clearAllInterval() {
        (function(w) {
            w = w || window;
            var i = w.setInterval(function() {
            }, 100000);
            while (i >= 0) {
                w.clearInterval(i--);
            }
        })(/*window*/);
        this.n1 = '';
        this.n2 = '';
        this.n3 = '';
        this.n4 = '';
    }

    async resendVerificationCode() {
        this.clearAllInterval();
        this.startTimer();
        let pin = Math.floor(Math.random() * 9999) + 1000;
        pin = pin > 9999 ? Math.floor(pin / 10) : pin;
        this.pin = `${pin}`;
        const message = `${pin} - Código de verificação Terralogs.`;

        await this.smsService.send(message, '+55' + this.formData.telefone);
    }

    private formatarMaiusculo(text) {
        if (text) {
            var loweredText = text.toLowerCase();
            var words = loweredText.split(' ');
            for (var a = 0; a < words.length; a++) {
                var w = words[a];

                var firstLetter = w[0];
                w = firstLetter.toUpperCase() + w.slice(1);

                words[a] = w;
            }
            return words.join(' ');
        }
        return '';
    }
}

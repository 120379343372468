import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {CamadasService} from '@services/camadas.service';
import {TerralogsService} from '@services/terralogs.service';
import {City} from 'app/models/City';

@Component({
    selector: 'app-modal-selecionar-camadas',
    templateUrl: './modal-selecionar-camadas.page.html',
    styleUrls: ['./modal-selecionar-camadas.page.scss'],
})
export class ModalSelecionarCamadasPage implements OnInit {

    @Input() city: string;
    @Input() state: string;
    @Input() cityInfo: City;
    @Input() filtros: any;

    camadaSelecionada;
    segmentoAtivo;
    segmentos;

    solos = [];
    biomas = [];
    climas = [];
    precipitacoes = [];
    temperatura = [];

    constructor(
        private modalController: ModalController,
        private camadasService: CamadasService,
        private loadingController: LoadingController,
        private terralogsService: TerralogsService
    ) {
    }


    ngOnInit() {
        this.solos = this.camadasService.getSolosLegenda();
        this.biomas = this.camadasService.getBiomasLegenda();
        this.climas = this.camadasService.getClimasLegenda();
        this.precipitacoes = this.camadasService.getPrecipitacaoLegenda();
        this.temperatura = this.camadasService.getTemperaturaLegenda();

        this.segmentos = [
            {
                nome: 'Solos',
                img: 'assets/images/camadas/solos.png',
                contador: this.cityInfo?.resources?.solo,
                disabled: this.cityInfo?.resources?.solo === 0,
                identificador: 'solos',
                ativo: this.filtros.find((v) => v.nome === 'solos').ativo
            },
            {
                nome: 'Biomas',
                img: 'assets/images/camadas/biomas.png',
                contador: this.cityInfo?.resources?.biome,
                disabled: this.cityInfo?.resources?.biome === 0,
                identificador: 'biomas',
                ativo: this.filtros.find((v) => v.nome === 'biomas').ativo
            },
            {
                nome: 'Clima',
                img: 'assets/images/camadas/clima.png',
                contador: this.cityInfo?.resources?.climate,
                disabled: this.cityInfo?.resources?.climate === 0,
                identificador: 'clima',
                ativo: this.filtros.find((v) => v.nome === 'clima').ativo
            },
            {
                nome: 'Amazônia Legal',
                img: 'assets/images/camadas/amazonia-legal.png',
                contador: this.cityInfo?.resources?.legal_amazon,
                disabled: this.cityInfo?.resources?.legal_amazon === 0,
                identificador: 'amazonia',
                ativo: this.filtros.find((v) => v.nome === 'amazonia').ativo
            },
            {
                nome: 'Precipitações',
                img: 'assets/images/camadas/precipitacoes.png',
                contador: this.cityInfo?.resources?.precipitation,
                disabled: this.cityInfo?.resources?.precipitation === 0,
                identificador: 'precipitacoes',
                ativo: this.filtros.find((v) => v.nome === 'precipitacoes').ativo
            },
            {
                nome: 'Temperatura',
                img: 'assets/images/camadas/temperatura.png',
                contador: this.cityInfo?.resources?.temperature,
                disabled: this.cityInfo?.resources?.temperature === 0,
                identificador: 'temperatura',
                ativo: this.filtros.find((v) => v.nome === 'temperatura').ativo
            }
        ];
        this.segmentoAtivo = this.segmentos.find(v => v.ativo === true);
        if (this.segmentoAtivo) {
            this.segmentoAtivo = this.segmentoAtivo.identificador;
        }
    }

    async selecionar(evento: any, tipo: string) {
        await (await this.loadingController.create()).present();
        try {
            let params;
            let response: any = {
                data: []
            };
            this.filtros.forEach(vl => {
                if (vl.nome === 'solos' || vl.nome === 'biomas'
                    || vl.nome === 'clima' || vl.nome === 'amazonia'
                    || vl.nome === 'precipitacoes' || vl.nome === 'temperatura') {
                    vl.ativo = false;
                }
            });
            const valor = this.filtros.find((v) => v.nome === tipo);
            valor.ativo = true;
            const index = this.filtros.indexOf(valor);
            this.filtros[index] = valor;
            const valorWithin = {
                latitude: '' + this.cityInfo.geometry_centroid[1],
                longitude: '' + this.cityInfo.geometry_centroid[0],
                radius: '0'
            };
            switch (tipo) {
                case 'solos': {
                    const limit = 100;
                    const offset = 0;
                    valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                    params = {
                        _limit: limit,
                        _offset: offset,
                        _order: 'register_date',
                        _sort: 'ASC',
                        _field: 'geometry_smoothed,name,color',
                        within: JSON.stringify(valorWithin),
                    };
                    let dados = [];
                    dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                    response.data = dados;
                    break;
                }
                case 'biomas': {
                    const limit = 1000;
                    const offset = 0;
                    params = {
                        _limit: limit,
                        _offset: offset,
                        _field: 'geometry_smoothed,geometry_centroid,color,name'
                    };
                    let dados = [];
                    dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                    response.data = dados;
                    break;
                }
                case 'clima': {
                    const limit = 100;
                    const offset = 0;
                    valorWithin.radius = '' + this.calcRadius(10, this.cityInfo.area);
                    params = {
                        _limit: limit,
                        _offset: offset,
                        _order: 'register_date',
                        _sort: 'ASC',
                        _field: 'geometry_smoothed,geometry_centroid,name,code',
                        within: JSON.stringify(valorWithin)
                    };
                    let dados = [];
                    dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                    response.data = dados;
                    break;
                }
                case 'amazonia': {
                    response = await this.terralogsService.getAmazoniaLegal();
                    break;
                }
                case 'precipitacoes': {
                    const limit = 100;
                    const offset = 0;
                    valorWithin.radius = '' + this.calcRadius(10, this.cityInfo.area);
                    params = {
                        _limit: limit,
                        _offset: offset,
                        _order: 'average_rainfall',
                        _sort: 'ASC',
                        _field: 'geometry_smoothed,geometry_centroid,average_rainfall',
                        within: JSON.stringify(valorWithin),
                    };
                    let dados = [];
                    dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                    response.data = dados;
                    break;
                }
                case 'temperatura': {
                    const limit = 100;
                    const offset = 0;
                    params = {
                        _limit: limit,
                        _offset: offset,
                        _order: 'register_date',
                        _sort: 'ASC',
                        _field: 'geometry_smoothed,temperature'
                    };
                    let dados = [];
                    dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                    response.data = dados;
                    break;
                }
                case 'nenhuma': {
                    response = {
                        data: true
                    };
                    break;
                }
            }
            this.modalController.dismiss(
                {
                    ativo: true,
                    retorno: response.data,
                    tipo,
                    filtros: this.filtros
                }
            );
        } catch (err) {
            this.modalController.dismiss(
                {
                    ativo: true,
                    retorno: false,
                    tipo,
                    filtros: this.filtros
                }
            );
        }
        this.loadingController.dismiss();
    }

    retornarClean() {
        const response = {
            data: true
        };
        this.modalController.dismiss(
            {
                ativo: true,
                retorno: response.data,
                tipo: 'nenhuma',
                filtros: this.filtros
            }
        );
    }

    calcRadius(radius = 3, area) {
        return Math.round(radius * Math.sqrt((area * 10000) / 3.14159265359));
    }

    async buscarPaginado(tipo, params, limit, offset, arrayValores) {

        const response: any = await this.retornarServico(tipo, params);

        arrayValores = arrayValores.concat(response.data);
        if (response.data.length === limit) {
            offset = offset + limit;
            params._offset = offset;
            return await this.buscarPaginado(tipo, params, limit, offset, arrayValores);
        }
        return await arrayValores;
    }

    async retornarServico(tipo, params) {
        let response;
        switch (tipo) {
            case 'solos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'solo');
            }
            case 'biomas': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'biome');
            }
            case 'clima': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'climate');
            }
            case 'temperatura': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'temperature');
            }
            case 'precipitacoes': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'precipitation');
            }
        }
    }

    close() {
        this.modalController.dismiss();
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
@Component({
    selector: 'app-legendas-mobile',
    templateUrl: './modal-legendas-mobile.page.html',
    styleUrls: ['./modal-legendas-mobile.page.scss'],
})
export class ModalLegendasMobilePage implements OnInit {

    legendas;

    constructor(
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss();
    }
}

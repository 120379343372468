import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController, ToastController, PopoverController, Platform, LoadingController} from '@ionic/angular';
import {Uf} from 'app/models/Uf';
import * as L from 'leaflet';
import * as Constants from '@services/contants.service';
import {DataPropriedades} from '../../../models/Propriedades';
import {EncontrarPropriedadePage} from '../encontrar-propriedade/encontrar-propriedade.page';
import {PropriedadeSelecionadaPage} from '../propriedade-selecionada/propriedade-selecionada.page';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import domtoimage from 'dom-to-image-google-font-issue';
import {TerralogsService} from '@services/terralogs.service';
import {CompartilharPinPageComponent} from 'app/components/compartilhar-pin-page/compartilhar-pin-page.component';

@Component({
    selector: 'app-modal-detail-propriedade',
    templateUrl: './modal-detail-propriedade.page.html',
    styleUrls: ['./modal-detail-propriedade.page.scss'],
})
export class ModalDetailPropriedadePage implements OnInit {

    @Input() dataPropriedade: DataPropriedades;
    // @Input() map: any;
    public map: L.map;
    @Input() codigoMunicipo: number;
    @Input() codigoUf: number;
    @Input() tamanho: string;
    @Input() isFinanciar: boolean;
    @Input() uf: Uf;
    @Input() listModals: string[] = [];
    @Input() idModalSelecionada: string;
    @Input() tipo: string;
    @Input() modalEscolherPropriedade = false;
    etapa = 1;
    formGroupForm: FormGroup;
    img;
    estimativaValor;
    financimantoPontencial;
    mobile = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private platform: Platform,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private popoverController: PopoverController,
        private modalController: ModalController
    ) {
        this.mobile = this.platform.width() < 1114;
        this.formGroupForm = this.formBuilder.group({
            nome: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            email: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            telefone: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
            estimativaValor: new FormControl('', {
                validators: [
                    Validators.required,
                ]
            }),
        });
    }

    ngOnInit() {
        this.img = 'assets/custom-icons/logo-outline.svg';
        this.estimativaValor = Math.round(this.dataPropriedade.prices[0].total_price);
        this.financimantoPontencial = Math.round(this.dataPropriedade.prices[0].clouseout_price);
    }

    async ionViewDidEnter() {
        this.loadMap();
    }

    proximaEtapa() {
        this.etapa++;
    }

    loadMap() {
        const [lng, lat] = this.dataPropriedade.geometry_centroid;
        this.map = new L.map('mapDetail', {zoomControl: false}).setView([lat, lng], 11);
        L.tileLayer(Constants.TILE_LAYER, {
            attribution: '',
            maxZoom: 18,
            id: Constants.TILE_ID,
            tileSize: 512,
            zoomOffset: -1,
            accessToken: Constants.TILE_ACCESS_TOKEN
        }).addTo(this.map); // Add the Tile Layer to the map


        const coors = [];
        this.dataPropriedade.geometry_smoothed.coordinates[0].forEach((coor) => {
            const coorCityJson = JSON.stringify(coor);
            coors.push(JSON.parse(coorCityJson).reverse());
        });
        const poly = L.polygon(coors, {fillColor: '#205826', color: 'red', fillOpacity: 0.6}).addTo(this.map);
    }

    ionViewDidLeave() {
        this.map.remove();
    }

    async modalPropriedadeSelecionada() {
        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('propriedade-selecionada' + idModal);
        await this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: PropriedadeSelecionadaPage,
            cssClass: 'modal-full',
            componentProps: {
                dataPropriedade: this.dataPropriedade,
                isFinanciar: this.isFinanciar,
                listModals: this.listModals
            },
            id: 'propriedade-selecionada' + idModal
        });
        await modal.present();
        await modal.onWillDismiss();

    }

    async goTo() {
        const bannerMap = await this.printMap();
        localStorage.setItem('bannerMap', bannerMap);
        localStorage.setItem('is_financiar', 'true');
        localStorage.setItem('data_propriedade', JSON.stringify(this.dataPropriedade));
        if (this.modalEscolherPropriedade) {
            await this.modalController.dismiss();
            await this.modalController.dismiss(
                {
                    propriedade: this.dataPropriedade,
                    codigoMunicipo: this.codigoMunicipo,
                    codigoUf: this.codigoUf
                }
            );
            // await this.modalController.dismiss({teste: true}, '', 'mapa-propriedade-modal').catch((error) => null);
        } else {
            await (await this.loadingController.create()).present();
            setTimeout(async () => {
                const zoomLeaflet: any = document.getElementsByClassName('leaflet-control-zoom');
                for (let x = 0; x < zoomLeaflet.length; x++) {
                    zoomLeaflet[x].style.display = 'none';
                }

                for (let x = 0; x < zoomLeaflet.length; x++) {
                    zoomLeaflet[x].style.display = 'initial';
                }

                this.router.navigate(['info-pessoal'], {queryParams: {idPropriedade: this.dataPropriedade.id}});
                this.modalController.dismiss();
                await this.loadingController.dismiss();
            }, 2000);
        }
    }

    async printMap() {
        const node = document.getElementById('mapDetail');

        const classButtonsZoom: HTMLElement = node && node.querySelector('.leaflet-control-zoom.leaflet-bar.leaflet-control');
        // Hack para diminuir o tamanho da imagem
        const scale = 0.8;
        try {

            // Hack para diminuir o tamanho da imagem
            // const scale = 0.8;
            const options = {
                height: node.offsetHeight * scale,
                width: node.offsetWidth * scale,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: `top left`,
                    width: `${node.offsetWidth}px`,
                    height: `${node.offsetHeight}px`
                }
            };

            if (classButtonsZoom) {
                classButtonsZoom.style.display = 'none';
            }

            const base64 = await domtoimage.toPng(node, options);
            return base64;
        } catch (error) {
            console.error(error);
        } finally {
            if (classButtonsZoom) {
                classButtonsZoom.style.display = 'block';
            }
        }
    }


    async modalEncontrarPropriedade() {
        const idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('encontrar-propriedade' + idModal);
        const modal = await this.modalController.create({
            component: EncontrarPropriedadePage,
            cssClass: 'modal-full',
            backdropDismiss: true,
            id: 'encontrar-propriedade' + idModal,
            componentProps: {
                data: true,
                dataPropriedade: this.dataPropriedade,
                tamanho: this.tamanho,
                isFinanciar: this.isFinanciar,
                uf: this.uf,
                listModals: this.listModals,
                idModalSelecionada: 'encontrar-propriedade' + idModal
            },
        });
        await modal.present()
            .then(() => {
                this.modalController.dismiss(null, '', this.idModalSelecionada).catch(error => console.log(error));
                this.modalController.dismiss(null, '', 'mapa-propriedade').catch(error => console.log(error));
                this.modalController.dismiss(null, '', 'mapa-encontrar-propriedade').catch(error => console.log(error));
            });
        const {data} = await modal.onWillDismiss();

        if (data) {

        }
    }

    async compartilharRegiao(ev: any) {
        const popover = await this.popoverController.create({
            component: CompartilharPinPageComponent,
            cssClass: this.mobile ? 'popover-detalhe-mobile' : 'popover-detalhe',
            translucent: true,
            event: ev,
            mode: 'ios',
            componentProps: {
                id: this.dataPropriedade.id
            }
        });
        await popover.present();

        const {role} = await popover.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    async confirmar() {
        await (await this.loadingController.create()).present();
        if (!this.formGroupForm.valid) {
            const toast = await this.toastController.create({
                message: 'Preencha corretamente o formulário',
                position: 'top',
                color: 'warning',
                animated: true,
                duration: 4000
            });
            await toast.present();
            await this.loadingController.dismiss();
        } else {
            try {
                await this.terralogsService.sendEstimativa(this.dataPropriedade.id, {
                    email: this.formGroupForm.get('email').value,
                    price: this.formGroupForm.get('estimativaValor').value.replace(/\D+/g, '')
                });
                const toast = await this.toastController.create({
                    message: 'Solicitação enviada com sucesso',
                    position: 'top',
                    color: 'success',
                    animated: true,
                    duration: 4000
                });
                await toast.present();
                await this.loadingController.dismiss();
                await this.modalController.dismiss();
            } catch (err) {
                const toast = await this.toastController.create({
                    message: 'Erro ao enviar a solitação',
                    position: 'top',
                    color: 'danger',
                    animated: true,
                    duration: 4000
                });
                await toast.present();
                await this.loadingController.dismiss();
            }
        }
    }

    formatMaiusculo(data: string) {
        return data.toUpperCase();
    }

    close() {
        this.modalController.dismiss();
    }
}

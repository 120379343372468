import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import { ModalEsqueciSenhaPage } from './modal-esqueci-senha.page';
import { ModalEsqueciSenhaPageRoutingModule } from './modal-esqueci-senha-routing.module';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ModalEsqueciSenhaPageRoutingModule,
        PipesModule
    ],
    declarations: [ModalEsqueciSenhaPage]
})
export class ModalEsqueciSenhaPageModule {
}

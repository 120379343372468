import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { DetalheRegiaoPinPageComponent } from './detalhe-regiao-pin-page/detalhe-regiao-pin-page.component';
import { CompartilharPinPageComponent } from './compartilhar-pin-page/compartilhar-pin-page.component';
import { FooterLandingPageComponent } from "./footer-landing-page/footer-landing-page.component";
import { LogoSuperiorLandingPageComponent } from "./logo-superior-landing-page/logo-superior-landing-page.component";
import { MapComponent } from "./map/map.component";
import { MenuHorizontalLandingPageComponent } from "./menu-horizontal-landing-page/menu-horizontal-landing-page.component";
import { MenuLateralLandingPageComponent } from "./menu-lateral-landing-page/menu-lateral-landing-page.component";
import { DetalheRegiaoLimitrofeComponent } from "./detalhe-regiao-limitrofe/detalhe-regiao-limitrofe.component";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [
    FooterLandingPageComponent,
    MenuLateralLandingPageComponent,
    LogoSuperiorLandingPageComponent,
    MenuHorizontalLandingPageComponent,
    DetalheRegiaoPinPageComponent,
    MapComponent,
    CompartilharPinPageComponent,
    DetalheRegiaoLimitrofeComponent
  ],
  exports: [
    FooterLandingPageComponent,
    MenuLateralLandingPageComponent,
    LogoSuperiorLandingPageComponent,
    MenuHorizontalLandingPageComponent,
    DetalheRegiaoPinPageComponent,
    MapComponent,
    CompartilharPinPageComponent,
    DetalheRegiaoLimitrofeComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentModule {}

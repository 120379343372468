import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {TerralogsService} from '@services/terralogs.service';
import {City} from 'app/models/City';

@Component({
    selector: 'app-modal-selecionar-filtros',
    templateUrl: './modal-selecionar-filtros.page.html',
    styleUrls: ['./modal-selecionar-filtros.page.scss'],
})
export class ModalSelecionarFiltrosPage implements OnInit {

    @Input() city: string;
    @Input() state: string;
    @Input() cityInfo: City;
    @Input() filtros: any;
    segmentos;

    constructor(
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
        this.segmentos = [
            {
                nome: 'SIGEF',
                img: 'assets/images/pins/sigef.svg',
                contador: this.cityInfo?.resources?.enrollment || 0,
                disabled: this.cityInfo?.resources?.enrollment === 0,
                identificador: 'sigef',
                ativo: (this.cityInfo?.resources?.enrollment > 0 && this.filtros.find((v) => v.nome === 'sigef').ativo)
            },
            {
                nome: 'CAR',
                img: 'assets/images/pins/car.svg',
                contador: this.cityInfo?.resources?.car || 0,
                disabled: this.cityInfo?.resources?.car === 0,
                identificador: 'car',
                ativo: this.filtros.find((v) => v.nome === 'car').ativo
            },
            {
                nome: 'Silos',
                img: 'assets/images/pins/silos.svg',
                contador: this.cityInfo?.resources?.silo || 0,
                disabled: this.cityInfo?.resources?.silo === 0,
                identificador: 'silos',
                ativo: this.filtros.find((v) => v.nome === 'silos').ativo
            },
            {
                nome: 'Frigoríficos',
                img: 'assets/images/pins/frigorifico.svg',
                contador: this.cityInfo?.resources?.fridge || 0,
                disabled: this.cityInfo?.resources?.fridge === 0,
                identificador: 'frigorificos',
                ativo: this.filtros.find((v) => v.nome === 'frigorificos').ativo
            },
            {
                nome: 'Usinas',
                img: 'assets/images/pins/usinas.svg',
                contador: this.cityInfo?.resources?.mill || 0,
                disabled: this.cityInfo?.resources?.mill === 0,
                identificador: 'usinas',
                ativo: this.filtros.find((v) => v.nome === 'usinas').ativo
            },
            {
                nome: 'Laticínios',
                img: 'assets/images/pins/laticinios.svg',
                contador: this.cityInfo?.resources?.dairy || 0,
                disabled: this.cityInfo?.resources?.dairy === 0,
                identificador: 'laticinios',
                ativo: this.filtros.find((v) => v.nome === 'laticinios').ativo
            },
            {
                nome: 'Florestais',
                img: 'assets/images/pins/florestais.svg',
                contador: this.cityInfo?.resources?.wood_processing || 0,
                disabled: this.cityInfo?.resources?.wood_processing === 0,
                identificador: 'florestais',
                ativo: this.filtros.find((v) => v.nome === 'florestais').ativo
            },
            {
                nome: 'Indústrias de Suco',
                img: 'assets/images/pins/suco.svg',
                contador: this.cityInfo?.resources?.orange_processing || 0,
                disabled: this.cityInfo?.resources?.orange_processing === 0,
                identificador: 'industriasSuco',
                ativo: this.filtros.find((v) => v.nome === 'industriasSuco').ativo
            },
            {
                nome: 'Concessionárias de Trator',
                img: 'assets/images/pins/concessionariasTrator.svg',
                contador: this.cityInfo?.resources?.tractor_concessionaire || 0,
                disabled: this.cityInfo?.resources?.tractor_concessionaire === 0,
                identificador: 'concessionariasTrator',
                ativo: this.filtros.find((v) => v.nome === 'concessionariasTrator').ativo
            },
            {
                nome: 'Pivôs',
                img: 'assets/images/pins/pivos.svg',
                contador: this.cityInfo?.resources?.pivot || 0,
                disabled: this.cityInfo?.resources?.pivot === 0,
                identificador: 'pivos',
                ativo: this.filtros.find((v) => v.nome === 'pivos').ativo
            },
            {
                nome: 'Portos',
                img: 'assets/images/pins/portos.svg',
                contador: this.cityInfo?.resources?.seaport || 0,
                disabled: this.cityInfo?.resources?.seaport === 0,
                identificador: 'portos',
                ativo: this.filtros.find((v) => v.nome === 'portos').ativo
            },
            {
                nome: 'Assentamentos',
                img: 'assets/images/pins/assentamentos.svg',
                contador: this.cityInfo?.resources?.settlement || 0,
                disabled: this.cityInfo?.resources?.settlement === 0,
                identificador: 'assentamentos',
                ativo: this.filtros.find((v) => v.nome === 'assentamentos').ativo
            },
            {
                nome: 'Terras Indígenas',
                img: 'assets/images/pins/indigenas.svg',
                contador: this.cityInfo?.resources?.indigenous_land || 0,
                disabled: this.cityInfo?.resources?.indigenous_land === 0,
                identificador: 'terrasIndigenas',
                ativo: this.filtros.find((v) => v.nome === 'terrasIndigenas').ativo
            },
            {
                nome: 'Quilombolas',
                img: 'assets/images/pins/quilombolas.svg',
                contador: this.cityInfo?.resources?.quilombola || 0,
                disabled: this.cityInfo?.resources?.quilombola === 0,
                identificador: 'quilombolas',
                ativo: this.filtros.find((v) => v.nome === 'quilombolas').ativo
            },
            {
                nome: 'Unidades de Conservação',
                img: 'assets/images/pins/conservacao.svg',
                contador: this.cityInfo?.resources?.conservation_area || 0,
                disabled: this.cityInfo?.resources?.conservation_area === 0,
                identificador: 'unidadesConservacao',
                ativo: this.filtros.find((v) => v.nome === 'unidadesConservacao').ativo
            },
            {
                nome: 'Ferrovias',
                img: 'assets/images/pins/ferrovias.svg',
                contador: this.cityInfo?.resources?.railroad || 0,
                disabled: this.cityInfo?.resources?.railroad === 0,
                identificador: 'ferrovias',
                ativo: this.filtros.find((v) => v.nome === 'ferrovias').ativo
            },
            {
                nome: 'Recursos Hídricos',
                img: 'assets/images/pins/water-resource.svg',
                contador: this.cityInfo?.resources?.water_resource || 0,
                disabled: this.cityInfo?.resources?.water_resource === 0,
                identificador: 'recursosHidricos',
                ativo: this.filtros.find((v) => v.nome === 'recursosHidricos').ativo
            }
        ];
    }

    async selecionar(evento: any, tipo: string) {
        await (await this.loadingController.create()).present();
        try {
            const checked = evento.detail.checked;
            let params;
            const response: any = {
                data: []
            };
            const valor = this.filtros.find((v) => v.nome === tipo);
            valor.ativo = checked;
            const index = this.filtros.indexOf(valor);
            this.filtros[index] = valor;
            const valorWithin = {
                latitude: '' + this.cityInfo.geometry_centroid[1],
                longitude: '' + this.cityInfo.geometry_centroid[0],
                radius: '0'
            };
            if (checked) {
                switch (tipo) {
                    case 'sigef':
                    case 'car': {
                        const limit = 200;
                        const offset = 0;
                        params = {
                            state: this.state.toLowerCase(),
                            city: this.city.trim(),
                            origin: tipo,
                            _limit: limit,
                            _offset: offset,
                            area_minimum: 30,
                            _field: 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices',
                        };

                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'silos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'name',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'laticinios': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'pivos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            _field: 'geometry_smoothed,geometry_centroid',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'assentamentos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            _field: 'geometry_smoothed,geometry_centroid,name,area,creation_at,obtainment_form,capacity,families',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'industriasSuco': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'florestais': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'unidadesConservacao': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            _field: 'geometry_smoothed,geometry_centroid,name,category,scope,year',
                            within: JSON.stringify(valorWithin),
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'portos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'name',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'terrasIndigenas': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            _field: 'geometry_smoothed,geometry_centroid,name,ethnicity,area,phase,modality',
                            within: JSON.stringify(valorWithin),
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'frigorificos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'usinas': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'quilombolas': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            _field: 'geometry_smoothed,geometry_centroid,name,scope',
                            within: JSON.stringify(valorWithin),
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'ferrovias': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(3, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'name',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'concessionariasTrator': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(1.5, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'register_date',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                    case 'recursosHidricos': {
                        const limit = 100;
                        const offset = 0;
                        valorWithin.radius = '' + this.calcRadius(3, this.cityInfo.area);
                        params = {
                            _limit: limit,
                            _offset: offset,
                            _order: 'name',
                            _sort: 'ASC',
                            within: JSON.stringify(valorWithin)
                        };
                        let dados = [];
                        dados = await this.buscarPaginado(tipo, params, limit, offset, []);
                        response.data = dados;
                        break;
                    }
                }
                this.modalController.dismiss(
                    {
                        ativo: true,
                        retorno: response.data,
                        tipo,
                        filtros: this.filtros
                    }
                );
            } else {
                this.modalController.dismiss(
                    {
                        ativo: false,
                        tipo,
                        filtros: this.filtros
                    }
                );
            }
        } catch (err) {
            this.modalController.dismiss(
                {
                    ativo: true,
                    retorno: false,
                    tipo,
                    filtros: this.filtros
                }
            );
        }
        this.loadingController.dismiss();
    }

    async buscarPaginado(tipo, params, limit, offset, arrayValores) {

        const response: any = await this.retornarServico(tipo, params);

        arrayValores = arrayValores.concat(response.data);
        if (response.data.length === limit) {
            offset = offset + limit;
            params._offset = offset;
            return await this.buscarPaginado(tipo, params, limit, offset, arrayValores);
        }
        return await arrayValores;
    }


    async retornarServico(tipo, params) {
        let response;
        switch (tipo) {
            case 'sigef':
            case 'car': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'enrollment');
            }
            case 'silos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'silo');
            }
            case 'frigorificos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'fridge');
            }
            case 'usinas': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'mill');
            }
            case 'laticinios': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'dairy');
            }
            case 'florestais': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'wood-processing');
            }
            case 'industriasSuco': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'orange-processing');
            }
            case 'concessionariasTrator': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'tractor-concessionaire');
            }
            case 'pivos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'pivot');
            }
            case 'portos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'seaport');
            }
            case 'assentamentos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'settlement');
            }
            case 'terrasIndigenas': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'indigenous-land');
            }
            case 'quilombolas': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'quilombola');
            }
            case 'unidadesConservacao': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'conservation-area');
            }
            case 'ferrovias': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'railroad');
            }
            case 'recursosHidricos': {
                return response = await this.terralogsService.getInfoPorTipo(params, 'water-resource');
            }
        }
    }

    calcRadius(radius = 3, area) {
        return Math.round(radius * Math.sqrt((area * 10000) / 3.14159265359));
    }

    close() {
        this.modalController.dismiss();
    }
}

import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TerralogsService} from '@services/terralogs.service';
import { ModalLoginPage } from '../modal-login/modal-login.page';
import { validarEmail } from 'app/utils/utils';

@Component({
    selector: 'app-modal-esqueci-senha',
    templateUrl: './modal-esqueci-senha.page.html',
    styleUrls: ['./modal-esqueci-senha.page.scss'],
})
export class ModalEsqueciSenhaPage implements OnInit {

    form: FormGroup;
    loading: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController,
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.required, Validators.email]
        });
    }

    ngOnInit() {}

    async recuperar() {
        const email = this.form.get('email').value;
        const emailValido = validarEmail(email);
        if (!emailValido) {
            this.toast('E-mail inválido', 'danger');
            return false;
        }

        await (await this.loadingController.create()).present();
        this.loading = true;

        this.terralogsService.recuperarSenhaUsuario(email)
            .subscribe(() => {
                this.toast('Você irá receber um e-mail com as instruções para gerar uma nova senha', 'success');
                setTimeout(() => {
                    this.login()
                }, 2000);
            }, er => {
                this.toast(er?.error?.result.message  || 'Não foi possível salvar os dados. Tente novamente!', 'danger');
            });

        await this.loadingController.dismiss();
        this.loading = false;
    }

    async login() {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: ModalLoginPage,
            cssClass: ['modal-small', 'modal-login'],
            backdropDismiss: true,
            id: 'modal-login'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();
    }

    close() {
        this.modalController.dismiss();
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-sum-percetage',
  templateUrl: './modal-sum-percetage.page.html',
  styleUrls: ['./modal-sum-percetage.page.scss'],
})
export class ModalSumPercetagePage implements OnInit {
  
  agricultura = 0;
  pastagem = 0;
  //pastoDegradado = 0;
  reflorestamento = 0;
  vegetacaoNativa = 0;

  total = 0;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  changeValue() {
    this.total = 0;
    this.total += this.agricultura;
    this.total += this.pastagem;
    //this.total += this.pastoDegradado;
    this.total += this.reflorestamento;
    this.total += this.vegetacaoNativa;
  }

  confirm() {
    this.modalController.dismiss({
      agricultura: this.agricultura,
      pastagem: this.pastagem,
      //pastoDegradado: this.pastoDegradado,
      reflorestamento: this.reflorestamento,
      vegetacaoNativa: this.vegetacaoNativa
    })
  }

  dismiss() {
    this.modalController.dismiss();
  }

}

import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "footer-landing-page",
  templateUrl: "footer-landing-page.component.html",
  styleUrls: ["footer-landing-page.component.scss"],
})
export class FooterLandingPageComponent {
  constructor(private router: Router) {}

  scroll(id) {
    if (this.router.url !== "/home") {
      this.router.navigate(["/home"]).then(() => {
        var anchor = document.getElementById(id);
        anchor.scrollIntoView({ block: "end", behavior: "smooth" });
      });
    } else {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }
}

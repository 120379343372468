import {ModalController} from '@ionic/angular';
import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import * as L from 'leaflet';
import * as uuid from 'uuid';
import {Observable} from 'rxjs';
import {TerralogsService} from '@services/terralogs.service';


@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    private containerId: string = uuid.v4();
    atualizarMapa$: Observable<boolean>;

    id = `leaflet-map-container-${this.containerId}`;
    map: L.Map;
    esriLayer: any;

    /**
     * Feature layer que vai conter todos os shapesadicionados pelo usuário
     */
    edLayer: any;

    @Input()
    showFilterButton = true;

    @Input()
    showTipoButton = true;

    @Input()
    basemap = 'Gray';

    @Input()
    acessoLocalizacao = false;

    @Input()
    initialPosition = [-27.5364666, -48.563182];

    @Input()
    initialZoom = 9;

    @Input()
    width = '100%';

    @Input()
    height = '102%';

    @Input()
    showControll = true;

    @Input()
    isLoading = false;

    @Output()
    onLoadMap = new EventEmitter<L.Map>();

    @Output()
    onClick = new EventEmitter();


    @Input()
    tipoMapa = 'Streets';

    imageryEnable: boolean;
    filtrosAtivo = [];

    constructor(private modalController: ModalController,
                private terraLogsService: TerralogsService,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.atualizarMapa$ = this.terraLogsService.atualizarMapa();
        this.atualizarMapa$.subscribe(async (up) => {
            await this.atualizarMapa();
        });
        setTimeout(() => this.loadMap(), 500);

    }

    async atualizarMapa() {
        if (this.map) {
            await this.map.remove();
        }
        setTimeout(() => this.loadMap(), 500);
    }

    loadMap() {
        this.map = new L.Map(this.id, {
            editable: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            fullscreenControl: true,
            zoomControl: this.showControll,
            fullscreenControlOptions: {
                position: 'topleft'
            },
        }).setView(
            this.initialPosition,
            this.initialZoom
        );
        this.onLoadMap.emit(this.map);
        this.map.on('click', (e) => {
            this.onClick.emit(e);
        });
    }

}

import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'menu-lateral-landing-page',
    templateUrl: 'menu-lateral-landing-page.component.html',
    styleUrls: ['menu-lateral-landing-page.component.scss'],
})
export class MenuLateralLandingPageComponent {

    @Output() onToggle: EventEmitter<any> = new EventEmitter();

    constructor(public router: Router) {}

    onToggleMenu(): void {
        this.onToggle.emit(null);
    }

    goChatbot() {
		this.router.navigate(['info-pessoal']);
    }
  
}
export const MAP_ICON_SIGEF = 'assets/images/pins/sigef.svg'
export const MAP_ICON_CAR = 'assets/images/pins/car.svg';
export const MAP_ICON_LIMITROFE = 'assets/images/pins/limitrofe.svg';
export const MAP_ICON_SILOS = 'assets/images/pins/silos.svg';
export const MAP_ICON_FRIGORIFICO = 'assets/images/pins/frigorifico.svg';
export const MAP_ICON_USINAS = 'assets/images/pins/usinas.svg';
export const MAP_ICON_LATICINIOS = 'assets/images/pins/laticinios.svg';
export const MAP_ICON_FLORESTAIS = 'assets/images/pins/florestais.svg';
export const MAP_ICON_SUCO = 'assets/images/pins/suco.svg';
export const MAP_ICON_CONCESSIONARIAS_TRATOR = 'assets/images/pins/concessionariasTrator.svg';
export const MAP_ICON_PIVOS = 'assets/images/pins/pivos.svg';
export const MAP_ICON_PORTOS = 'assets/images/pins/portos.svg';
export const MAP_ICON_ASSENTAMENTOS = 'assets/images/pins/assentamentos.svg';
export const MAP_ICON_INDIGENAS = 'assets/images/pins/indigenas.svg';
export const MAP_ICON_QUILOMBOLAS = 'assets/images/pins/quilombolas.svg';
export const MAP_ICON_CONSERVACAO = 'assets/images/pins/conservacao.svg';
export const MAP_ICON_WATER_RESOURCE = 'assets/images/pins/water-resource.svg';


export const MAP_ICON_SELECTED = 'assets/icon/marker-selected.svg'

export const TILE_LAYER = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';

export const TILE_ID = 'mapbox/satellite-streets-v11';

export const TILE_ACCESS_TOKEN = 'pk.eyJ1IjoiZ3VzdGF2b3ZpZWlyYTk5MiIsImEiOiJja2piZmN3MGcwazFwMnVxcWh0YWVubjdoIn0.rny57jknqMG45YR2nlrojA';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapaPropriedadesPageRoutingModule } from './mapa-propriedades-routing.module';

import { MapaPropriedadesPage } from './mapa-propriedades.page';
import { ComponentModule } from 'app/components/component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentModule,
    MapaPropriedadesPageRoutingModule
  ],
  declarations: [MapaPropriedadesPage]
})
export class MapaPropriedadesPageModule {}

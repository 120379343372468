import { Component, Input, OnInit } from '@angular/core'
import { LoadingController, ModalController } from '@ionic/angular'
import * as L from 'leaflet'
import * as L1 from 'leaflet.markercluster'
import * as Constants from '@services/contants.service'
import { DataPropriedades } from '../../../models/Propriedades'
import { ModalDetailPropriedadePage } from '../modal-detail-propriedade/modal-detail-propriedade.page'
import { ModalAjudaPage } from '../modal-ajuda/modal-ajuda.page'
import { Router } from '@angular/router'
import { ModalMobileOptionsPage } from '../modal-mobile-options/modal-mobile-options.page'

const mapIconSelected = L.icon({
    iconUrl: Constants.MAP_ICON_SELECTED,
    iconSize: [68, 68],
})
const mapIcon = L.icon({
    iconUrl: Constants.MAP_ICON_SIGEF,
    iconSize: [68, 68],
})

@Component({
    selector: 'app-mapa-encontrar-propriedade',
    templateUrl: './mapa-encontrar-propriedade.page.html',
    styleUrls: ['./mapa-encontrar-propriedade.page.scss'],
})
export class MapaEncontrarPropriedadePage implements OnInit {
    @Input() data: DataPropriedades[]
    @Input() isFinanciar: Boolean
    @Input() listModals: string[] = [];

    public map: L.map
    public markers = new L1.MarkerClusterGroup({
        maxClusterRadius: 10,
    })

    constructor(
        private modalController: ModalController,
        private loadingController: LoadingController,
        private router: Router
    ) {}

    ionViewDidEnter() {
        this.loadMap()
    }

    ngOnInit() {}

    loadMap() {
        const [lng, lat] = this.data[0].geometry_centroid
        this.map = new L.map('mappropriedade').setView([lat, lng], 10)
        L.tileLayer(Constants.TILE_LAYER, {
            attribution: '',
            maxZoom: 18,
            id: Constants.TILE_ID,
            tileSize: 512,
            zoomOffset: -1,
            accessToken: Constants.TILE_ACCESS_TOKEN
        }).addTo(this.map)
        this.populateMapPins(this.data)
    }

    populateMapPins(data) {
        data.forEach((properties) => {
            const [lng, lat] = properties.geometry_centroid
            const newPin = new L.marker([lat, lng], { icon: mapIcon }).on(
                'click',
                (layerClicked) => {
                    this.detailPoint(layerClicked, properties)
                }
            )
            this.markers.addLayer(newPin)
        })
        this.map.addLayer(this.markers)
    }

    async detailPoint(selectedPinLayer: L.marker, dataPropriedade: DataPropriedades) {
        let idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-detail-propriedade' + idModal)
        const [lng, lat] = dataPropriedade.geometry_centroid
        const modal = await this.modalController.create({
            component: ModalDetailPropriedadePage,
            cssClass: 'modal-footer-medium',
            backdropDismiss: true,
            componentProps: {
                dataPropriedade,
                map: this.map,
                isFinanciar: this.isFinanciar,
                listModals: this.listModals
            },
            id: 'modal-detail-propriedade' + idModal,
        })

        selectedPinLayer.target.setIcon(mapIconSelected)
        await modal.present()
        await modal.onWillDismiss().then(() => {
            selectedPinLayer.target.setIcon(mapIcon)
        })
    }

    async openModalAjuda() {
        let idModal = Math.floor(Math.random() * 9999) + 1000;
        this.listModals.push('modal-ajuda' + idModal)
        const modal = await this.modalController.create({
            component: ModalAjudaPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-ajuda' + idModal,
        })
        await modal.present()
    }

    async onBack() {
        console.log('FILTRAR MAPA ENCONTRAR PROPRIEDADE')
        await this.modalController.dismiss(null, '', 'mapa-encontrar-propriedade');
    }

    async openModalMobileOptions() {
        const modal = await this.modalController.create({
            component: ModalMobileOptionsPage,
            cssClass: 'modal-footer',
            backdropDismiss: true,
            id: 'modal-mobile-options',
            componentProps: {
                options: [
                    {
                        icon: 'assets/custom-icons/icone-pesquisar-modal-opcoes.svg',
                        label: 'Filtrar',
                        action: async () => this.onBack(),
                        isDisabled: false
                    }, {
                        icon: 'assets/custom-icons/icon-ajuda-modal-opcoes.svg',
                        label: 'Ajuda',
                        action: async () => this.openModalAjuda(),
                        isDisabled: false
                    }
                ]
            },
        })
        await modal.present()
    }

    async onClose() {
        await (await this.loadingController.create()).present()
        await this.listModals.map(async m => {
            await this.modalController.dismiss(null, '', m).catch(error => console.log(error));
        })
        await this.loadingController.dismiss()
        this.router.navigate(['home'])
    }
}

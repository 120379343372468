import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  URL_SMS = environment.url_api_sms;

  constructor(
    private http: HttpClient
  ) { }

  send(message: string, phoneNumber: string): Promise<any> {
    return this.http.post(`${this.URL_SMS}/v1/sendMessage`, { message, phoneNumber }).toPromise();
  }

  getHistoric(phoneNumber: string): Promise<any> {
    return this.http.get(`${this.URL_SMS}/v1/history?phoneNumber=${phoneNumber}`).toPromise();
  }
}

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StringUtils {

    replaceAcentos(newStringComAcento) {
        let str = (newStringComAcento).toString();
        const mapaAcentosHex = {
          a: /[\xE0-\xE6]/g,
          e: /[\xE8-\xEB]/g,
          i: /[\xEC-\xEF]/g,
          o: /[\xF2-\xF6]/g,
          u: /[\xF9-\xFC]/g,
          c: /\xE7/g,
          n: /\xF1/g
        };
      
        // tslint:disable-next-line:forin
        for (const letra in mapaAcentosHex) {
          const expressaoRegular = mapaAcentosHex[letra];
          str = str.replace(expressaoRegular, letra);
        }
      
        return str;
    }
}
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
    ADD_POLYGON = 'ADD_POLYGON',
    ADD_DATA = 'ADD_DATA',
    CLEAR = 'CLEAR'
}

export const addPolygon = createAction(ActionTypes.ADD_POLYGON, props<{payload : any}>())

export const addMapa = createAction(ActionTypes.ADD_DATA, props<{ payload: any }>());

export const clear = createAction(ActionTypes.CLEAR);
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {TerralogsService} from '@services/terralogs.service';

@Component({
    selector: 'app-modal-bem-vindo',
    templateUrl: './modal-bem-vindo.page.html',
    styleUrls: ['./modal-bem-vindo.page.scss'],
})
export class ModalBemVindoPage implements OnInit {

    constructor(
        private router: Router,
        private platform: Platform,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController,
    ) {
    }


    ngOnInit() {
    }

    voltar() {
        this.modalController.dismiss();
    }

    close() {
        this.modalController.dismiss();
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalDetailPropriedadePageRoutingModule } from './modal-detail-propriedade-routing.module';
import { ModalDetailPropriedadePage } from './modal-detail-propriedade.page';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { PipesModule } from 'app/pipes/pipes.module';
import { BrMaskerModule } from 'br-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxTrimDirectiveModule,
    BrMaskerModule,
    ModalDetailPropriedadePageRoutingModule,
    PipesModule
  ],
  declarations: [ModalDetailPropriedadePage]
})
export class ModalDetailPropriedadePageModule {}
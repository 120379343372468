import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DominiosService } from '@services/dominios.service'
import { Municipio } from 'app/models/Municipio'
import { Uf } from 'app/models/Uf'
import { StringUtils } from 'app/utils/string-utils'

@Component({
    selector: 'app-modal-buscar-municipio',
    templateUrl: './modal-buscar-municipio.page.html',
    styleUrls: ['./modal-buscar-municipio.page.scss'],
})
export class ModalBuscarMunicipioPage implements OnInit {
    constructor(
        private modalController: ModalController,
        private dominiosService: DominiosService,
        private stringUtils: StringUtils
    ) {}
    text: string = ''
    municipios: Municipio[] = []
    municipioSelected: string = ''
    uf: Uf
    options: any[] = []
    filteredOptions: any[] = []
    showMunicipiosList = true
    title: any;

    async ngOnInit() {
        this.municipios = await this.dominiosService.getMunicipio('');
        const ufs = await this.dominiosService.getUf();
        this.options = this.municipios.map((municipio) => {
            const sigla = ufs.find((uf) => municipio.codigo_uf === uf.codigo_uf).uf;
            this.municipioSelected = municipio.nome;
            return  {
                municipio: municipio.codigo_ibge,
                uf: municipio.codigo_uf,
                nome: `${municipio.nome} - ${sigla}`
            };
        });
    }

    async dismiss() {
        await this.modalController.dismiss();
    }

    async confirm() {
        // const optionsSelected = this.options.filter((o) => o.active)
    }

    onFilter() {
        if (this.municipioSelected !== this.text) {
            if (this.text && this.text !== '') {
                this.filteredOptions = this.options
                    .filter((option) => option?.nome.toLowerCase().includes(this.text.toLowerCase())
                            || this.stringUtils.replaceAcentos(option?.nome.toLowerCase()).includes(this.text.toLowerCase()))
                    .slice(0, 7);
            } else {
                this.filteredOptions = [];
            }
        }
    }

    async onSelectMunicipio(municipio) {
        await this.modalController.dismiss(municipio)
    }
}

import {Component, OnInit, Input} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-compartilhar-pin-page',
    templateUrl: './compartilhar-pin-page.component.html',
    styleUrls: ['./compartilhar-pin-page.component.scss'],
})
export class CompartilharPinPageComponent implements OnInit {

    @Input() id;
    mobile = false;
    conteudo;
    url;

    constructor(
        private platform: Platform,
        private toastController: ToastController
    ) {
        this.mobile = this.platform.width() < 1114;
    }

    ngOnInit() {
        this.url = window.location.origin;
    }

    teste() {

    }

    whatsapp() {
        if (this.mobile) {
            const a = document.createElement('a');
            const linkText = document.createTextNode('');
            a.appendChild(linkText);
            a.href = `whatsapp://send?text=Veja esta propriedade dentro do Terralogs | ${this.url}/mapa-propriedades/share/${this.id}`;
            document.body.appendChild(a);
            a.click();
        } else {
            window.open('https://web.whatsapp.com/send?text=' + `Veja esta propriedade dentro do Terralogs | ${this.url}/mapa-propriedades/share/${this.id}`);
        }
    }

    async copiar() {
        const textarea = document.createElement('textarea');
        textarea.textContent = `${this.url}/mapa-propriedades/share/${this.id}`;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        try {
            const toast = await this.toastController.create({
                message: 'Link compartilhado com sucesso',
                position: 'top',
                color: 'success',
                animated: true,
                duration: 4000
            });
            await toast.present();
        } catch (ex) {
            return false;
        } finally {
            document.body.removeChild(textarea);
        }

    }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-form-propriedade',
  templateUrl: './modal-form-propriedade.page.html',
  styleUrls: ['./modal-form-propriedade.page.scss'],
})
export class ModalFormPropriedadePage implements OnInit {

  formGroupForm = new FormGroup({
    nome: new FormControl('', {
      validators: [
        Validators.required,
      ]
    }),
  });

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.modalController.dismiss(null, '', 'modal-detail-propriedade').catch(error => console.log(error));
  }

  async confirm() {
    await this.modalController.dismiss(this.formGroupForm.get('nome').value, '', 'modal-form-propriedade');
  }

  async dismiss() {
    await this.modalController.dismiss(null, '', 'modal-form-propriedade');
  }
}

import { ALPHANUMERIC_REGEX, NUMERIC_REGEX } from "app/utils/constants";
import { DataPropriedades } from "./Propriedades";
import { Relatorio } from "./Relatorio";


export class Usuario {
    id: string;
    name: string;
    phone: string;
    mail: string;
    cityOperation: string;
    ufOperation: string;
    activityFirst: string;
    isSpecialist: boolean;
    kindPerson: string;
    cpf: string;
    cnpj: string;
    companyName: string;
    cityCompany: string;
    ufCompany: string;
    fundationYear: string;
    numberEmployee: string;
    financingAmount: number;
    goal: string;
    agropecuaryActivity: string;
    timeActivity: string;
    area: number;
    isLowSeason: Boolean;
    lastAmount: number;
    financingProduction: any[];
    isHasRegistration: Boolean;
    data: DataPropriedades;
    polygon: {
        editing: any,
        nome_propriede: string
        options: any,
        _bounds: any,
        _events: any
    };
    garantees: any[];
    acceptedEstimated: Boolean;
    valueEstimated: number;
    agriculture: number;
    pasture: number;
    degradedPasture: number;
    reforestation: number
    nativeVegetation: number;
    predominantSoils: string[];
    isMoetgage: Boolean;
    isOwner: Boolean;
    otherGarantees: any[];
    potencialCredit: number;
    isAcceptedTerms: Boolean;
    vintagesPerYear: string;
    soilsRR: string;
    biomeRR: string;
    climateRR: string;

    constructor(relatorio: Relatorio) {
        this.id = relatorio.id;
        this.name = relatorio.name;
        this.phone = relatorio.phone;
        this.mail = relatorio.mail;
        this.cityOperation = relatorio.cityOperation;
        this.ufOperation = relatorio.ufOperation;
        this.activityFirst = relatorio.activityFirst;
        this.isSpecialist = relatorio.isSpecialist;
        this.kindPerson = relatorio.kindPerson;
        this.cpf = relatorio.cpf ? relatorio.cpf.replace(ALPHANUMERIC_REGEX, '') : null;
        this.cnpj = relatorio.cnpj ? relatorio.cnpj.replace(ALPHANUMERIC_REGEX, '') : null;
        this.companyName = relatorio.companyName;
        this.cityCompany = relatorio.cityCompany;
        this.ufCompany = relatorio.ufCompany;
        this.fundationYear = relatorio.fundationYear;
        this.numberEmployee = relatorio.numberEmployee;
        this.financingAmount = relatorio.financingAmount ? Number(relatorio.financingAmount.replace(NUMERIC_REGEX, '')) : null;
        this.goal = relatorio.goal;
        this.agropecuaryActivity = relatorio.agropecuaryActivity;
        this.timeActivity = relatorio.timeActivity;
        this.area = relatorio.area ? Number(relatorio.area.replace(NUMERIC_REGEX, '')) : null;
        this.isLowSeason = relatorio.isLowSeason;
        this.lastAmount = relatorio.lastAmount ? Number(relatorio.lastAmount.replace(NUMERIC_REGEX, '')) : null;
        this.financingProduction = relatorio.financingProduction;
        this.isHasRegistration = relatorio.isHasRegistration;
        this.data = relatorio.data;
        this.polygon = relatorio.polygon ? this.createPoligon(relatorio.polygon) : null;
        this.garantees = relatorio.garantees;
        this.acceptedEstimated = relatorio.acceptedEstimated;
        this.valueEstimated = relatorio.valueEstimated ? Number(relatorio.valueEstimated.replace(NUMERIC_REGEX, '')) : null;
        this.agriculture = relatorio.agriculture ? Number(relatorio.agriculture.replace(NUMERIC_REGEX, '')) : null;
        this.pasture = relatorio.pasture ? Number(relatorio.pasture.replace(NUMERIC_REGEX, '')) : null;
        this.degradedPasture = relatorio.degradedPasture ? Number(relatorio.degradedPasture.replace(NUMERIC_REGEX, '')) : null;
        this.reforestation = relatorio.reforestation ? Number(relatorio.reforestation.replace(NUMERIC_REGEX, '')) : null;
        this.nativeVegetation = relatorio.nativeVegetation ? Number(relatorio.nativeVegetation.replace(NUMERIC_REGEX, '')) : null;
        this.predominantSoils = relatorio.predominantSoils;
        this.isMoetgage = relatorio.isMoetgage;
        this.isOwner = relatorio.isOwner;
        this.otherGarantees = relatorio.otherGarantees;
        this.potencialCredit = relatorio.potencialCredit ? Number(relatorio.potencialCredit.replace(NUMERIC_REGEX, '')) : null;
        this.isAcceptedTerms = relatorio.isAcceptedTerms;
        this.vintagesPerYear = relatorio.vintagesPerYear;
        this.soilsRR = relatorio.data && relatorio.data.ground_type && relatorio.data.ground_type.length > 0 ? this.formatSoils(relatorio.data.ground_type[0], relatorio.predominantSoils) : (relatorio.predominantSoils ? this.formatSoils(null, relatorio.predominantSoils) : 'Não cadastrado.');
        this.biomeRR = this.formatBiome(relatorio.data);
        this.climateRR = this.formatClimate(relatorio.data);
    }

    private createPoligon(polygon: any): any {
        const obj = {
            latlngs: polygon.editing.latlngs[0],
            nome_propriede: polygon.nome_propriede,
            options: polygon.options,
            _bounds: polygon._bounds
        }
        return obj;
    }

    private formatSoils(groundType, predominantSoils) {
        let text = '';
        if (groundType) {
            text += groundType;
        }
        if (predominantSoils) {
            if (text != '') {
                text += ', '
            }
            let count = 0;
            predominantSoils.map(s => {
                if (count > 0) {
                    text += ', '
                }
                if (s !== groundType) {
                    text += s;
                    count++;
                }
            });
        }
        return text;
    }

    private formatClimate(data) {
        return data && data.climate_type && data.climate_type.length > 0 ? data.climate_type[0] + '.' : 'Não cadastrado.';
    }

    private formatBiome(data) {
        return data && data.biome_type && data.biome_type.length > 0 ? data.biome_type[0] : 'Não cadastrado.';
    }
}
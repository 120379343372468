import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Municipio } from '../models/Municipio';
import { Uf } from '../models/Uf';

@Injectable({
  providedIn: 'root'
})
export class DominiosService {

  constructor(private http: HttpClient) { 

  }

  async getMunicipio(query: string): Promise<Municipio[]> {
    return await this.http.get<Municipio[]>('assets/json/municipios.json').toPromise();
  }

  async getUf(): Promise<Uf[]> {
    return await this.http.get<Uf[]>('assets/json/uf.json').toPromise();
  } 
}

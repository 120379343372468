import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class FormatPdf {

    validateSoils(groundType, predominantSoils): any[] {
        let result = [];
        if (groundType) {
            let arr = this.formatSoil(groundType);
            arr.forEach(e => result.push(e));
        }
        if (predominantSoils) {
            predominantSoils.map(s => {
                if (s !== groundType) {
                    let arr2 = this.formatSoil(s);
                    arr2.forEach(e => result.push(e));
                }
            });
        }
        return result;
    }
    
    formatSoil(groundType): any[] {
        let result: any[] = [];
        if (groundType == 'Afloramento de rochas') {
            result.push({ text: 'Afloramento de rochas: ', bold: true });
            result.push('composto de exposição de rocha na superfície. Causa mais comum é o desgaste e erosão do solo. Inapto para cultivo. ');
        }
        else if (groundType == 'Alissolo') {
            result.push({ text: 'Alissolo: ', bold: true });
            result.push('formado por solos minerais ácidos, com baixa fertilidade química e teor excessivo de alumínio. São utilizados normalmente para pastagem e culturas de subsistência. ');
        }
        else if (groundType == 'Argissolo') {
            result.push({ text: 'Argissolo: ', bold: true });
            result.push('apresenta evidente incremento no teor de argila. Constate-se grande diversidades em fertilidade e profundidade, sendo empregado para diferentes culturas agrícolas. ');
        }
        else if (groundType == 'Cambissolo') {
            result.push({ text: 'Cambissolo: ', bold: true });
            result.push('apresenta solos pouco desenvolvidos, normalmente com baixa permeabilidade. Em áreas planas, apresentam potencial para cultivo. Em áreas acidentadas, por seres rasos, possuem fortes limitações e são suscetíveis a processos erosivos. ');
        }
        else if (groundType == 'Chernossolo') {
            result.push({ text: 'Chernossolo: ', bold: true });
            result.push('formado por solo com fertilidade bastante elevada e alto teor de argila. As limitações de uso se relacionam ao risco de erosão pois ocorrem em relevos ondulados a fortemente ondulados. ');
        }
        else if (groundType == 'Dunas') {
            result.push({ text: 'Dunas: ', bold: true });
            result.push('são formas de relevo que consistem em acúmulo de areia, quase sempre formados pela ação dos ventos. Ocorrem em relevo ondulado. Inaptos para cultivo. ');
        }
        else if (groundType == 'Espodossolo') {
            result.push({ text: 'Espodossolo: ', bold: true });
            result.push('são solos de moderada a fortemente ácidos, logo exigem correção para o cultivo. Textura é predominantemente arenosa, causando limitações para cultivo quando mal manejado. ');
        }
        else if (groundType == 'Gleissolo') {
            result.push({ text: 'Gleissolo: ', bold: true });
            result.push('compreende solos mal a muito mal drenados. Apresentem limitações para uso agrícola devido à presença de lençol freático elevado. ');
        }
        else if (groundType == 'Latossolo') {
            result.push({ text: 'Latossolo: ', bold: true });
            result.push('são solos profundos a muito profundos, com incremento de argila dependendo do tipo. As propriedades físicas e químicas, pós alguma correção, são tornam-se ótimas para cultivo agrícola, florestal e pecuário. ');
        }
        else if (groundType == 'Luvissolo') {
            result.push({ text: 'Luvissolo: ', bold: true });
            result.push('são solos rasos e pouco profundos, de elevado potencial nutricional, decorrente de altas quantidade de nutrientes. Ocorrem em relevo suave ondulado, o que facilita sua mecanização. Em razão da pouca profundidade sua principal limitação é retenção de água. ');
        }
        else if (groundType == 'Massa de água') {
            result.push({ text: 'Massa de água: ', bold: true });
            result.push('é uma grande porção de água inapta para cultivo. ');
        }
        else if (groundType == 'Neossolo') {
            result.push({ text: 'Neossolo: ', bold: true });
            result.push('são solos pouco evoluídos, com espessura e textura bastante diversificada. Principais restrições são baixa fertilidade natural e excesso de umidade, e manejo mecanizável quando apresentem textura fina. Quando ocorrem em textura média e boa drenagem, oferecem alto potencial agropecuário. ');
        }
        else if (groundType == 'Nitossolo') {
            result.push({ text: 'Nitossolo: ', bold: true });
            result.push('são solos homogêneos, com mínima variação de cor e profundidade. São argilosos, com estrutura que favorece retenção de água, boa drenagem. Adequados para cultivo de diversas culturas. ');
        }
        else if (groundType == 'Organossolo') {
            result.push({ text: 'Organossolo: ', bold: true });
            result.push('são solos, na sua maior parte, fortemente ácidos. A baixa fertilidade natural, deficiência de aeração e os impedimentos a mecanização constituem importantes limitações ao uso desses solos. ');
        }
        else if (groundType == 'Planossolo') {
            result.push({ text: 'Planossolo: ', bold: true });
            result.push('são solos com fertilidade variável, com algum incremento de argila e baixa permeabilidade. Exigem manejo e cuidados para cultivo agrícola. ');
        }
        else if (groundType == 'Plintossolo') {
            result.push({ text: 'Plintossolo: ', bold: true });
            result.push('são solos profundos, moderadamente drenados e, em geral, de fertilidade baixa. Exigem intenso manejo para cultivo. ');
        }
        else if (groundType == 'Vertissolo') {
            result.push({ text: 'Vertissolo: ', bold: true });
            result.push('são solos adequados do ponto de vista química, porém apresentem atributos físicos pouco favoráveis ao manejo, sendo duros quando secos, formando torrões. Normalmente utilizados em pastagens naturais de boa qualidade. ');
        }
        else {
            result.push('Não cadastrado.');
        }
        return result;
    }

    formatBiome(biomeType): any[] {
        let result: any[] = [];
        if (biomeType == 'Amazônia') {
            result.push({ text: 'Amazônia: ', bold: true });
            result.push('caracterizada pela baixa amplitude térmica e grande umidade, flora constituída por uma vegetação florestal muito rica e densa, com folhas largas e grandes, que não caem no outono.');
        }
        else if (biomeType == 'Caatinga') {
            result.push({ text: 'Caatinga: ', bold: true });
            result.push('caracterizada pela vegetação composta, principalmente, por plantas xerófilas e caducifólias.');
        }
        else if (biomeType == 'Cerrado') {
            result.push({ text: 'Cerrado: ', bold: true });
            result.push('caracterizada pelo clima tropical continental, contendo duas estações bem definidas - uma úmida (verão) e outra seca (inverno) -, possui uma vegetação com árvores e arbustos de pequeno porte.');
        }
        else if (biomeType == 'Mata Atlântica') {
            result.push({ text: 'Mata Atlântica: ', bold: true });
            result.push('caracterizada por uma vegetação florestal densa, com diversos tamanhos, latifoliada e perene.');
        }
        else if (biomeType == 'Pampa') {
            result.push({ text: 'Pampa: ', bold: true });
            result.push('caracterizada principalmente por planícies em virtude do clima frio e seco, a vegetação não consegue desenvolver-se, sendo constituída principalmente por gramíneas.');
        }
        else if (biomeType == 'Pantanal') {
            result.push({ text: 'Pantanal: ', bold: true });
            result.push('caracterizada durante o período chuvoso é alaga grande parte da planície da região, fora deste período os rios diminuem o seu volume d\'água e retornam para os seus leitos, com vegetação bem diversificada.');
        }
        else {
            result.push('Não cadastrado.');
        }
        return result;
    }
    
    formatClimate(climateType): any[] {
        let result: any[] = [];
        if (climateType == 'Clima tropical úmido ou superúmido') {
            result.push({ text: 'Clima tropical úmido ou superúmido: ', bold: true });
            result.push('em estação seca, sendo a temperatura média do mês mais quente superior a 18 ºC. O total das chuvas do mês mais seco é superior a 60 mm, com precipitações maiores de março a agosto, ultrapassando o total de 1.500 mm anuais. Nos meses mais quentes (janeiro e fevereiro) a temperatura é de 24 a 25ºC.');
        }
        else if (climateType == 'Clima tropical úmido ou subúmido') {
            result.push({ text: 'Clima tropical úmido ou subúmido: ', bold: true });
            result.push('caracteriza-se por apresentar temperatura média do mês mais frio sempre superior a 18ºC apresentando uma estação seca de pequena duração que é compensada pelos totais elevados de precipitação.');
        }
        else if (climateType == 'Clima tropical quente e úmido') {
            result.push({ text: 'Clima tropical quente e úmido: ', bold: true });
            result.push('é caracterizado pela ausência de chuvas de verão e sua ocorrência no "inverno" (que corresponde à estação chuvosa e não ao inverno propriamente dito).');
        }
        else if (climateType == 'Clima tropical com inverno seco') {
            result.push({ text: 'Clima tropical com inverno seco: ', bold: true });
            result.push('apresenta estação chuvosa no verão, de novembro a abril, e nítida estação seca no inverno, de maio a outubro (julho é o mês mais seco). A temperatura média do mês mais frio é superior a 18ºC. As precipitações são superiores a 750 mm anuais, atingindo 1800 mm.');
        }
        else if (climateType == 'Clima subtropical com verão quente') {
            result.push({ text: 'Clima subtropical com verão quente: ', bold: true });
            result.push('as temperaturas são superiores a 22ºC no verão e com mais de 30 mm de chuva no mês mais seco.');
        }
        else if (climateType == 'Clima subtropical de inverno seco') {
            result.push({ text: 'Clima subtropical de inverno seco: ', bold: true });
            result.push('com temperaturas inferiores a 18ºC e verão quente, com temperaturas superiores a 22ºC.');
        }
        else if (climateType == 'Clima subtropical de altitude') {
            result.push({ text: 'Clima subtropical de altitude: ', bold: true });
            result.push('a temperatura média do mês mais quente é inferior a 22ºC.');
        }
        else if (climateType == 'Clima semi-árido quente') {
            result.push({ text: 'Clima semi-árido quente: ', bold: true });
            result.push('é caracterizado por escassez de chuvas e grande irregularidade em sua distribuição; baixa nebulosidade; forte insolação; índices elevados de evaporação, e temperaturas médias elevadas (por volta de 27ºC). A umidade relativa do ar é normalmente baixa, e as poucas chuvas - de 250 mm a 750 mm por ano - concentram-se num espaço curto de tempo, provocando enchentes torrenciais. Mesmo durante a época das chuvas (novembro a abril), sua distribuição é irregular, deixando de ocorrer durante alguns anos e provocando secas. A vegetação característica desse tipo de clima é a xerófila (Caatinga).');
        }
        else if (climateType == 'Clima temperado com verão ameno') {
            result.push({ text: 'Clima temperado com verão ameno: ', bold: true });
            result.push('chuvas uniformemente distribuídas, sem estação seca e a temperatura média do mês mais quente não chega a 22ºC. Precipitação de 1.100 a 2.000 mm. Geadas severas e frequentes, num período médio de ocorrência de dez a 25 dias anualmente.');
        }
        else {
            result.push('Não cadastrado.');
        }
        return result;
    }

}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_TERRALOGS: string = 'https://api.terralogs.com.br';
const USER_TERRALOGS: string = 'terralogsdocumentos@gmail.com';
const PASSWORD_TERRALOGS: string = 'Z3LlOp4S5__2022';
const URL_TERRALOGS_API: string = 'https://backend.terralogs.com.br';
const GATEWAY_TERRALOGS: string = 'https://bs22kexud2.execute-api.sa-east-1.amazonaws.com';
const URL_CHATBOT: string = 'https://terralogs-chatbot-api-wb7o5gwcva-uc.a.run.app';

export const environment = {
    production: false,
    mapbox_token: '',
    url_terralogs_api: URL_TERRALOGS_API,
    user_terralogs: USER_TERRALOGS,
    password_terralogs: PASSWORD_TERRALOGS,
    api_terralogs: API_TERRALOGS,
    api_version_terralogs: 'v1',
    url_chatbot: URL_CHATBOT,
    url_api_sms: GATEWAY_TERRALOGS + '/tl-prd'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

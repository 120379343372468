import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {TerralogsService} from '@services/terralogs.service';
import {ModalCadastroPage} from '../modal-cadastro/modal-cadastro.page';
import {ModalLoginPage} from '../modal-login/modal-login.page';

@Component({
    selector: 'app-modal-limite',
    templateUrl: './modal-limite.page.html',
    styleUrls: ['./modal-limite.page.scss'],
})
export class ModalLimitePage implements OnInit {

    constructor(
        private router: Router,
        private platform: Platform,
        private toastController: ToastController,
        private terralogsService: TerralogsService,
        private loadingController: LoadingController,
        private modalController: ModalController,
    ) {
    }

    ngOnInit() {
    }


    async entrar() {
        this.close();
        const modal = await this.modalController.create({
            component: ModalLoginPage,
            cssClass: ['modal-small', 'modal-login'],
            backdropDismiss: true,
            id: 'modal-login'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            console.log('data', data);
        }
    }

    async cadastrar() {
        this.close();
        const modal = await this.modalController.create({
            component: ModalCadastroPage,
            cssClass: ['modal-small', 'modal-cadastrar'],
            backdropDismiss: true,
            id: 'modal-cadastrar'
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();

        if (data) {
            console.log('data', data);
        }
    }

    close() {
        this.modalController.dismiss();
    }

    async toast(mensagem, color = 'warning') {
        const toast = await this.toastController.create({
            message: mensagem,
            position: 'top',
            color,
            animated: true,
            duration: 4000
        });
        await toast.present();
    }
}

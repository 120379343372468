import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Municipio } from 'app/models/Municipio';
import { StringUtils } from 'app/utils/string-utils';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataPropriedades } from '../../../models/Propriedades';
import { Uf } from '../../../models/Uf';
import { DominiosService } from '../../../services/dominios.service';
import { TerralogsService } from '../../../services/terralogs.service';
import { DesenharPropriedadePage } from '../desenhar-propriedade/desenhar-propriedade.page';
import { MapaEncontrarPropriedadePage } from '../mapa-encontrar-propriedade/mapa-encontrar-propriedade.page';
import { ModalAjudaPage } from '../modal-ajuda/modal-ajuda.page';
import { ModalMobileOptionsPage } from '../modal-mobile-options/modal-mobile-options.page';
import { ModalMultipleChoicePage } from '../modal-multiple-choice/modal-multiple-choice.page';
import { ModalEncontrarPropriedadePage } from './modal-encontrar-propriedade/modal-encontrar-propriedade.page';

@Component({
  selector: 'app-encontrar-propriedade',
  templateUrl: './encontrar-propriedade.page.html',
  styleUrls: ['./encontrar-propriedade.page.scss'],
})
export class EncontrarPropriedadePage implements OnInit {

  @Input() dataPropriedade: DataPropriedades;
  @Input() tamanho: string;
  @Input() isFinanciar: Boolean;
  @Input() uf: Uf;
  @Input() listModals: string[] = [];
  @Input() idModalSelecionada: string;

  formGroupForm = new FormGroup({
    cidade: new FormControl('', {
        validators: [Validators.required],
    }),
    tamanhoPropriedade: new FormControl('', {
      validators: [Validators.required],
    }),
    nome: new FormControl(''),
    matricula: new FormControl(''),
    numeroCAR: new FormControl('')
  });

  municipios: Municipio[] = []
  tamanhoPropriedade: string;
  valueTamanho: string;
  options: string[] = []
  filteredOptions: Observable<string[]>
  
  municipioSelected: string = ''
  notExistResponse: boolean = false;

  constructor(
    private modalController: ModalController,
    private terralogsService: TerralogsService,
    private dominiosService: DominiosService,
    private router: Router,
    private stringUtils: StringUtils,
    private loadingController: LoadingController
  ) { }

  async ngOnInit() {
    await (await this.loadingController.create()).present()

    const {data} = await this.terralogsService.login();
    localStorage.setItem('token', data.token);

    this.municipios = await this.dominiosService.getMunicipio('')
    const ufs = await this.dominiosService.getUf()

    this.options = this.municipios.map((municipio) => {
        const sigla = ufs.find((uf) => municipio.codigo_uf === uf.codigo_uf).uf
        this.municipioSelected = municipio.nome
        return `${municipio.nome} - ${sigla}`
    })

    this.filteredOptions = this.formGroupForm.get('cidade').valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
    )
    await this.loadingController.dismiss()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()

    return this.options
        .filter((option) => option.toLowerCase().includes(filterValue) 
                || this.stringUtils.replaceAcentos(option.toLowerCase()).includes(filterValue))
        .slice(0, 5)
}

  async getPropriedade() {
    await (await this.loadingController.create()).present();

    const cidade: string = this.formGroupForm.get('cidade').value;

    const splitCidade = cidade.split(' - ');

    const [minimum, maximum] = this.valueTamanho.split('-');

    const nome = this.formGroupForm.get('nome').value;
    const matricula = this.formGroupForm.get('matricula').value;
    const numeroCAR = this.formGroupForm.get('numeroCAR').value;

    let query = null;
    if (nome && nome !== '') {
      query = nome.trim();
    } else if (matricula && matricula !== '') {
      query = matricula.trim();
    } else if (numeroCAR && numeroCAR !== '') {
      query = numeroCAR.trim();
    }

    // const params = {
    //   city: splitCidade && splitCidade.length > 0 ? splitCidade[0] : '',
    //   query: '',
    //   area_minimum: minimum,
    //   area_maximum: maximum,
    //   _field: 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices,owner_name,secundary_owner_name,climate_type,biome_type,relief_type,distance,nearest_urban_city,registration_number,overlap,ground_type,origin',
    // }

    const limit = 200;
    let offset = 0;
    const params = {
      query: '',
      _limit: limit,
      _offset: offset,
      state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1).toLowerCase().trim() : '',
      city: splitCidade && splitCidade.length > 0 ? splitCidade[0].trim() : '',
      origin: 'sigef',
      _field: 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices',
      area_minimum: 30
    };

    if (query) {
      params.query = query;
    } else {
      delete params.query;
    };

    let response: any = {
      data: []
    };

    response.data = await this.buscarPaginadoPropriedade(params, limit, offset, []);

    const city =  await this.terralogsService.getCity(cidade);

    console.log('city', city);
    console.log('cid', cidade);
    console.log('response', response.data);

    response = {...response,
      state: splitCidade && splitCidade.length > 0 ? splitCidade[1].slice(0, -1) : '',
      city: splitCidade && splitCidade.length > 0 ? splitCidade[0] : ''
    };
    
    await this.loadingController.dismiss();
    if (!response) {
      this.notExistResponse = true;
      this.openModalEncontrarPropriedade();
    } else {
      await this.openModalMapaPropriedades(response.data, city.data);
    }
  }

  async openModalMapaPropriedades(response, cityInfo) {
    this.listModals.push('mapa-encontrar-propriedade');
    const modal = await this.modalController.create({
      component: MapaEncontrarPropriedadePage,
      cssClass: 'modal-full',
      backdropDismiss: true,
      componentProps: {
        data: response,
        isFinanciar: this.isFinanciar,
        listModals: this.listModals,
        city: response.city,
        state: response.state,
        cityInfo,
        uf: this.uf,
        zoomInicial: 9
      },
      id: 'mapa-encontrar-propriedade',
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();

    if (data) {
        
    }
  }

  async buscarPaginadoPropriedade(params, limit, offset, arrayValores) {

    const response: any = await this.terralogsService.getInfoPorTipo(params, 'enrollment');
    if (response) {
      arrayValores = arrayValores.concat(response.data);
      if (response.data.length === limit) {
        offset = offset + limit;
        params._offset = offset;
        return await this.buscarPaginadoPropriedade(params, limit, offset, arrayValores);
      }
      return await arrayValores;
    } else {
      return [];
    }

  }

  async modalChoice() {
    const modal = await this.modalController.create({
      component: ModalMultipleChoicePage,
      cssClass: 'modal-footer',
      backdropDismiss: true,
      componentProps: {
        title: 'Tamanho da propriedade',
        options: [
          {
            name: 'Até 500 hectares',
            value: '0-500',
          },
          {
            name: 'De 500 a 1.500 hectares',
            value: '500-1500',
          },
          {
            name: 'De 1.500 a 3.000 hectares',
            value: '1500-3000',
          },
          {
            name: 'De 3.000 a 5.000 hectares',
            value: '3000-5000',
          },
          {
            name: 'De 5.000 a 10.000 hectares',
            value: '5000-10000',
          },
          {
            name: 'Acima de 10.000 hectares',
            value: '10000-9999999999',
          },
        ],
        multiple: false
      },
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();

    if (data) {
      this.formGroupForm.get('tamanhoPropriedade').setValue(data[0].name);
      this.valueTamanho = data[0].value;
    }
  }

  async openModalAjuda() {
    const modal = await this.modalController.create({
        component: ModalAjudaPage,
        cssClass: 'modal-footer',
        backdropDismiss: true,
        id: 'modal-ajuda',
    })
    await modal.present()
  }

  async openModalEncontrarPropriedade() {
    let idModal = Math.floor(Math.random() * 9999) + 1000;
    this.listModals.push('modal-encontrar-propriedade' + idModal)
    const modal = await this.modalController.create({
        component: ModalEncontrarPropriedadePage,
        cssClass: 'modal-info',
        backdropDismiss: true,
        componentProps: {
          uf: this.uf,
          isFinanciar: this.isFinanciar,
          listModals: this.listModals,
          cidade: this.formGroupForm.get('cidade').value
        },
        id: 'modal-encontrar-propriedade' + idModal,
    })
    await modal.present()
  }

  async openModalMobileOptions() {
    const modal = await this.modalController.create({
        component: ModalMobileOptionsPage,
        cssClass: 'modal-footer',
        backdropDismiss: true,
        id: 'modal-mobile-options',
        componentProps: {
            options: [
                {
                    icon: 'assets/custom-icons/icon-reiniciar-selecao.svg',
                    label: 'Voltar',
                    action: async () => this.onBack(),
                    isDisabled: false
                }, {
                    icon: 'assets/custom-icons/icon-ajuda-modal-opcoes.svg',
                    label: 'Ajuda',
                    action: async () => this.openModalAjuda(),
                    isDisabled: false
                }
            ]
        },
    })
    await modal.present()
  }

  async onBack() {
    console.log('VOLTAR ENCONTRAR PROPRIEDADE')
    if (this.idModalSelecionada) {
      await this.modalController.dismiss(null, '', this.idModalSelecionada)
    } else {
      await this.modalController.dismiss()
    }
  }

  async onClose() {
    await (await this.loadingController.create()).present()
    await this.listModals.map(async m => {
        await this.modalController.dismiss(null, '', m).catch(error => console.log(error));
    })
    await this.loadingController.dismiss()
    await this.router.navigate(['home']);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-mobile-options',
  templateUrl: './modal-mobile-options.page.html',
  styleUrls: ['./modal-mobile-options.page.scss'],
})
export class ModalMobileOptionsPage implements OnInit {

  @Input() options: { icon: string, label: string, action: () => null, isDisabled: boolean }[];

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async dismiss() {
    await this.modalController.dismiss(null, '', 'modal-mobile-options');
  }

}
